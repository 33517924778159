import React, { useState } from 'react'
import { Timeline } from 'react-event-timeline'
import PaymentMethodTimelineEvent from './PaymentMethodTimelineEvent'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

export default function PaymentMethodTimeline({
  customer,
  policy,
  paymentMethods,
  documents,
  testData,
  ...noneEventProps
}) {
  const [openEvents, setOpenEvents] = useState([])

  const handleEventOpened = newlyOpenedEvent => {
    let newOpenEvents = [...openEvents]
    newOpenEvents.push(newlyOpenedEvent)
    setOpenEvents(newOpenEvents)
  }

  const handleEventClosed = newlyClosedEvent => {
    let newOpenEvents = [...openEvents]
    newOpenEvents = newOpenEvents.filter(
      event => event.eventId !== newlyClosedEvent.eventId
    )
    setOpenEvents(newOpenEvents)
  }

  const sortByCreatedAtAscending = (e1, e2) => {
    const e1Date = DateTime.fromISO(
      e1.events.find(el => el.status === 'ACTIVE').createdAt
    )
    const e2Date = DateTime.fromISO(
      e2.events.find(el => el.status === 'ACTIVE').createdAt
    )

    if (e1Date < e2Date) {
      return -1
    } else if (e1Date > e2Date) {
      return 1
    }

    return 0
  }

  const sortByStatusAndCreatedAtAscending = (
    paymentMethod1,
    paymentMethod2
  ) => {
    if (isDeactivated(paymentMethod1) && !isDeactivated(paymentMethod2)) {
      return -1
    }

    if (isDeactivated(paymentMethod2) && !isDeactivated(paymentMethod1)) {
      return 1
    }
    return sortByCreatedAtAscending(paymentMethod1, paymentMethod2)
  }

  function isDeactivated(paymentMethod) {
    return paymentMethod.status === 'INACTIVE'
  }

  const sortedPaymentMethods = paymentMethods
    .slice()
    .sort(sortByStatusAndCreatedAtAscending)
    .reverse()

  return (
    <div className="payment-method-timeline-wrapper">
      <Timeline>
        {sortedPaymentMethods.map(paymentMethod => (
          <PaymentMethodTimelineEvent
            key={paymentMethod.id}
            customer={customer}
            policy={policy}
            paymentMethod={paymentMethod}
            documents={documents}
            testData={testData}
            onEventOpened={handleEventOpened}
            onEventClosed={handleEventClosed}
            {...noneEventProps}
          />
        ))}
      </Timeline>
    </div>
  )
}

PaymentMethodTimeline.propTypes = {
  paymentMethods: PropTypes.array.isRequired
}
