import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import Close from '@material-ui/icons/Close'

import styles from './DevTools.module.css'
import { Button, IconButton, Chip } from '@material-ui/core'
import { Form } from 'react-final-form'
import CheckboxField from './CheckboxField'
import {
  MICROFRONTEND_NAMES,
  QUERY_PARAM_NAME
} from '../utils/BackofficeMicrofrontend'

export function DevTools() {
  const [hidden, setHidden] = useState(false)
  const [expanded, setExpanded] = useState(false)

  if (process.env.NODE_ENV !== 'development') {
    return null
  }

  if (hidden) {
    return null
  }

  const localMicrofrontends = getLocalMicrofrontendsFromUrl()

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div style={{ flex: 1 }}>
          {expanded && <CloseIconButton onClick={() => setHidden(true)} />}
        </div>
        <div>
          {localMicrofrontends.length > 0 && (
            <Chip
              label={localMicrofrontends.length}
              size="small"
              color="primary"
              style={{ marginRight: 10 }}
            />
          )}
          DevTools
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
      </div>
      {expanded && <Settings />}
    </div>
  )
}

function Settings() {
  return (
    <div className={styles.settingsRoot}>
      Load microfrontends from localhost:
      <Form
        initialValues={initialValues()}
        onSubmit={handleSubmitForm}
        subscription={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ul style={{ marginTop: 10 }}>
              {MICROFRONTEND_NAMES.map(mf => (
                <li key={mf}>
                  <CheckboxField
                    name={`localMicrofrontends.${mf}`}
                    label={mf}
                  />
                </li>
              ))}
            </ul>
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="submit">
                Apply
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  )
}

function CloseIconButton({ onClick }) {
  return (
    <IconButton size="small" onClick={onClick}>
      <Close />
    </IconButton>
  )
}

function getLocalMicrofrontendsFromUrl() {
  const params = new URLSearchParams(window.location.search)
  const localMicrofrontendsFromUrl = (params.get(QUERY_PARAM_NAME) ?? '').split(
    ','
  )
  return MICROFRONTEND_NAMES.filter(mf =>
    localMicrofrontendsFromUrl.includes(mf)
  )
}

function initialValues() {
  const localMicrofrontendsFromUrl = getLocalMicrofrontendsFromUrl()
  const localMicrofrontends = MICROFRONTEND_NAMES.reduce(
    (acc, mf) => ({ ...acc, [mf]: localMicrofrontendsFromUrl.includes(mf) }),
    {}
  )

  return {
    localMicrofrontends
  }
}

function handleSubmitForm(values) {
  const localMicrofrontends = Object.entries(values.localMicrofrontends)
    .filter(([key, value]) => value)
    .map(([key]) => key)

  const params = new URLSearchParams(window.location.search)
  if (localMicrofrontends.length === 0) {
    params.delete(QUERY_PARAM_NAME)
  } else {
    params.set(QUERY_PARAM_NAME, localMicrofrontends.join(','))
  }

  window.location.search = params.toString()
}
