export class Product {
  constructor(
    readonly id: string,
    readonly version: number,
    readonly pricingConfiguration: PricingConfiguration
  ) {}

  isPremiumAdjustmentAllowed() {
    return this.pricingConfiguration.premiumAdjustmentAllowed
  }
}

interface PricingConfiguration {
  premiumAdjustmentAllowed: boolean
}
