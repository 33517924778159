import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './NavButton.module.scss'

const NavButton = props => {
  const history = useHistory()

  return (
    <div
      className={styles.listItem}
      onClick={() => {
        if (props.onClick) props.onClick()
        if (props.to) history.push(props.to)
      }}>
      {props.Icon && (
        <div className={styles.iconAnt}>
          <props.Icon />
        </div>
      )}
      <div className={styles.label}>{props.label}</div>
    </div>
  )
}

export default NavButton
