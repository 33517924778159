import { DateTime } from 'luxon'
import {
  PolicyEvent,
  PolicyEventDocument,
  PolicyEventTypeDescriptor
} from '../../customers/customer-details/policy-details/timeline/PolicyTimeline'

export const combineEventsWithDocuments = <T extends PolicyEvent>(
  events: T[],
  documents: PolicyEventDocument[] = []
) => {
  return events.map(event => {
    const matchingDocuments = documents.filter(doc => {
      return (
        event.eventType !== 'SCHEDULED_CANCELLATION' &&
        doc.eventId === event.eventId
      )
    })

    return {
      ...event,
      attachments: matchingDocuments.length > 0 ? matchingDocuments : undefined
    }
  })
}

export const combineEventsWithEventDescription = <T extends PolicyEvent>(
  events: T[],
  eventTypes: PolicyEventTypeDescriptor[] = []
) =>
  events.map(event => ({
    ...event,
    eventDescription:
      eventTypes.find(t =>
        t.subType ? t.subType === event.type : t.type === event.eventType
      )?.description || 'Unbekanntes Ereignis'
  }))

export const sortByCreatedAtAscending = (e1, e2) => {
  const e1Date = DateTime.fromISO(e1.createdAt)
  const e2Date = DateTime.fromISO(e2.createdAt)

  if (e1Date < e2Date) {
    return -1
  } else if (e1Date > e2Date) {
    return 1
  }

  return 0
}

export const allTransferEventsFileAttachments = (events, documents) => {
  const nonEmptyAttachmentList = attachment => {
    return attachment !== undefined
  }
  return combineEventsWithDocuments(events, documents)
    .flatMap(event => event.attachments)
    .filter(nonEmptyAttachmentList)
}
