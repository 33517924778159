import * as React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import LinearProgress from '@material-ui/core/LinearProgress'

export class SortableTableHead extends React.Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    isSelectable: PropTypes.bool,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      columns,
      isLoading,
      isSelectable,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props

    return (
      <TableHead>
        <TableRow>
          {isSelectable && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {columns.map(column => {
            const cellPadding = column.disablePadding ? 'none' : 'normal'
            const sortDirection =
              orderBy === (column.orderBy || column.id) ? order : false
            const tooltipPlacement = column.numeric
              ? 'bottom-end'
              : 'bottom-start'

            return (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={cellPadding}
                sortDirection={sortDirection}>
                {column.notSortable ? (
                  column.label
                ) : (
                  <Tooltip
                    title="Sort"
                    placement={tooltipPlacement}
                    enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === (column.orderBy || column.id)}
                      direction={order}
                      onClick={this.createSortHandler(
                        column.orderBy || column.id
                      )}>
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            )
          }, this)}
        </TableRow>
        {isLoading && (
          <TableRow style={{ height: 'auto' }}>
            <TableCell colSpan={columns.length} style={{ padding: 0 }}>
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
      </TableHead>
    )
  }
}
