import { PolicyUpdatePersistenceInterface } from './boundaries/PolicyUpdatePersistenceInterface'
import { PolicyUpdate } from './entities/PolicyUpdate'
import * as _ from 'lodash'

export class PolicyUpdateStore implements PolicyUpdatePersistenceInterface {
  private _store?: PolicyUpdate

  constructor(initialValue: PolicyUpdate) {
    this._store = initialValue
  }

  storePolicyUpdate(policyUpdate: PolicyUpdate): void {
    this._store = policyUpdate
  }

  loadPolicyUpdate(): PolicyUpdate {
    if (this._store === undefined)
      throw new Error('Trying to load from store, but store is empty.')
    return _.cloneDeep(this._store)
  }
}
