import { PolicyUpdate } from '../entities/PolicyUpdate'
import { PolicyUpdateRequestDto } from './PolicyUpdateRequestDto'
import { DateTime } from 'luxon'

export class PolicyUpdateRequestConverter {
  static createFrom(policyUpdate: PolicyUpdate): PolicyUpdateRequestDto {
    const updateDto: PolicyUpdateRequestDto = {
      elements: policyUpdate.elements.map(element => ({
        name: element.name,
        attributes: element.attributes,
        options: element.options,
        personsToInsure: element.personsToInsure,
        insuredEntities: element.insuredEntities
      }))
    }

    return removeEmptyProperties(updateDto)
  }

  static createFromEffectiveAt(
    policyUpdate: PolicyUpdate,
    effectiveDate: DateTime
  ): PolicyUpdateRequestDto {
    const update = this.createFrom(policyUpdate)
    return {
      ...update,
      effectiveDate: effectiveDate.toString()
    }
  }
}

function removeEmptyProperties(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object')
      removeEmptyProperties(obj[key])
    else if (obj[key] === undefined) delete obj[key]
  })
  return obj
}
