export const insuredPersonOptionNames = [
  'accidentalDeathPayOption',
  'hospitalDailyPayOption',
  'ignoreExistingPreconditionOption',
  'activityPlusOption',
  'dailyAllowanceOption',
  'transitionalPayOption'
]

export const insuredPersonPricingAttributeNames = [
  'occupationId',
  'birthDate',
  'disabilitySum',
  'disabilityProgression',
  'accidentalDeathPaySum',
  'transitionalPaySum',
  'hospitalDailyPaySum',
  'dailyAllowanceType',
  'dailyAllowanceSum',
  'annuityOption',
  'annuityPerMonth',
  ...insuredPersonOptionNames
]

export const insuredPersonAttributeNames = [
  'birthDate',
  'occupationName',
  'isOccupationAccepted'
]

export const confirmationValueNames = [
  'confirmationNoMedicalConditionsModulePreexistingConditions',
  'confirmationNoMedicalConditionsSenior'
]
