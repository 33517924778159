import { AccessRights } from '../../auth/AuthorizationFilter'
import { matchPath, useLocation } from 'react-router-dom'
import {
  PlusCircleOutlined,
  FileAddOutlined,
  SkinOutlined,
  RiseOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  IdcardOutlined,
  ApiOutlined,
  SettingOutlined,
  PlusSquareOutlined,
  CarryOutOutlined,
  FilePdfOutlined
} from '@ant-design/icons'

const ELEMENT_BLUEPRINT_PARTNER_ID = '45233'

export const customersPoliciesRoutes = [
  {
    to: '/customers',
    label: 'Kunden',
    Icon: TeamOutlined
  },
  {
    to: '/policy',
    label: 'Police erstellen',
    accessRights: AccessRights.CreateProductionPolicy,
    Icon: PlusCircleOutlined
  },
  {
    to: '/test-policy',
    label: 'Testpolice erstellen',
    accessRights: AccessRights.CreateTestPolicy,
    Icon: FileAddOutlined
  }
]

export const customersOrdersRoutes = [
  {
    to: '/test-order',
    label: 'Testorder erstellen',
    accessRights: AccessRights.CreateTestPolicy,
    Icon: PlusSquareOutlined
  },
  {
    to: '/order',
    label: 'Produktionsorder erstellen',
    accessRights: AccessRights.CreateProductionPolicy,
    Icon: CarryOutOutlined
  }
]

export const usersBrandsRoutes = [
  {
    to: '/user-management',
    label: 'Benutzerverwaltung',
    accessRights: AccessRights.UserAdmin,
    Icon: UsergroupAddOutlined
  },
  {
    to: '/brands',
    label: 'Document Branding',
    accessRights: AccessRights.ProductAdmin,
    Icon: SkinOutlined
  }
]

export const insuredProducts = [
  {
    to: '/insurance-products',
    label: 'Insurance Products',
    accessRights: AccessRights.InsuranceProductAdmin
  }
]

export const productLine = [
  {
    to: '/product-line',
    label: 'Product line overview',
    accessRights: AccessRights.ProductAdmin
  },
  {
    to: '/lines-of-business',
    label: 'Lines of Business',
    accessRights: AccessRights.ProductAdmin
  }
]

export const risksRoutes = [
  {
    to: '/risks',
    label: 'Risks Group Management',
    accessRights: AccessRights.PartnerAdmin,
    Icon: RiseOutlined
  }
]

export const commonRoutes = [
  {
    to: '/partners',
    label: 'Partner',
    accessRights: AccessRights.PartnerAdmin,
    Icon: SolutionOutlined
  },
  {
    to: '/products',
    label: 'Produkte',
    accessRights: AccessRights.ProductAdmin,
    Icon: AppstoreOutlined
  },
  {
    to: '/document-creation',
    label: 'Document creation',
    accessRights: AccessRights.ProductAdmin,
    Icon: FilePdfOutlined
  },
  {
    to: '/purchase-flows',
    label: 'Purchase Flow',
    accessRights: AccessRights.PurchaseFlowAdmin,
    Icon: ShoppingCartOutlined
  },
  {
    to: '/customer-portals',
    label: 'Customer Portals',
    accessRights: AccessRights.CustomerPortalAdmin,
    Icon: IdcardOutlined
  }
]

export const apiRoutes = [
  {
    to: `/api-documentation/${ELEMENT_BLUEPRINT_PARTNER_ID}`,
    label: 'API Dokumentation',
    accessRights: AccessRights.Element,
    Icon: ApiOutlined
  }
]

export const footerRoutes = [
  {
    to: '/settings',
    label: 'Einstellungen',
    Icon: SettingOutlined
  }
]

export const allRoutes = [
  ...customersPoliciesRoutes,
  ...usersBrandsRoutes,
  ...productLine,
  ...risksRoutes,
  ...commonRoutes,
  ...apiRoutes,
  ...footerRoutes
]

export const useActiveRoutes = routes => {
  const location = useLocation()
  return routes.filter(route =>
    matchPath(location.pathname, { path: route.to })
  )
}
