import React, { useState } from 'react'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { TimelineEvent } from 'react-event-timeline'

import { mapPaymentMethodIcon } from '../PaymentDetailsUtils'
import { formattedGermanDateTime } from '../../../../../utils/Dates'
import {
  CollapsibleTimelineEventCloseButton,
  CollapsibleTimelineEventOpenButton
} from '../../../../../utils/timeline/CollapsibleTimelineEvent'

import { ShowRawDataDialogButton } from '../PaymentsCollapsibleTimelineEvent'

const cursorPointerStyle = { cursor: 'pointer', fontWeight: 'normal' }
const eventMinWidth = '250px'

function Content({ transfer }) {
  const createdTransactionDate = formattedGermanDateTime(
    transfer.transferEventDate
  )

  return (
    <React.Fragment>
      <div
        style={{
          fontSize: 20
        }}>{`Abbuchungstag: ${createdTransactionDate}`}</div>
    </React.Fragment>
  )
}

function Title({ label, onClick, paymentMethodType }) {
  return (
    <Typography variant="body1">
      <Grid container direction="row" alignItems="center" onClick={onClick}>
        <Grid item>
          <Icon color={'primary'} fontSize={'small'} style={{ marginRight: 3 }}>
            {mapPaymentMethodIcon(paymentMethodType)}
          </Icon>
        </Grid>
        <Grid item>{label}</Grid>
      </Grid>
    </Typography>
  )
}

const OpenCollapsibleTimelineEvent = ({
  transfer,
  label,
  icon,
  iconColor,
  children,
  onClose,
  paymentMethod,
  ...noneEventProps
}) => (
  <TimelineEvent
    createdAt={
      <div>
        <Content transfer={transfer} />
        <ShowRawDataDialogButton data={transfer} />
        <CollapsibleTimelineEventCloseButton onClose={onClose} />
      </div>
    }
    title={
      <Title
        label={label}
        onClick={onClose}
        paymentMethodType={paymentMethod.type}
      />
    }
    icon={
      <Icon style={cursorPointerStyle} onClick={onClose}>
        {icon}
      </Icon>
    }
    iconColor={iconColor}
    style={{
      backgroundColor: 'white',
      boxShadow: '0 0 3px 1px rgba(0,0,0,0.25)',
      left: -50,
      paddingLeft: 50,
      top: -10,
      paddingTop: 10,
      bottom: -10,
      paddingBottom: 10,
      borderRadius: 6,
      marginRight: -48,
      minWidth: eventMinWidth,
      marginTop: 20
    }}
    bubbleStyle={{
      zIndex: 1,
      backgroundColor: 'white'
    }}
    {...noneEventProps}>
    <div
      style={{
        marginTop: 12,
        marginLeft: -40,
        marginRight: 5,
        fontWeight: 'normal',
        fontSize: 16
      }}>
      {children}
    </div>
  </TimelineEvent>
)

const ClosedCollapsibleTimelineEvent = ({
  transfer,
  label,
  icon,
  iconColor,
  children,
  onOpen,
  paymentMethod,
  ...noneEventProps
}) => (
  <TimelineEvent
    createdAt={
      <div>
        <Content transfer={transfer} />
        <CollapsibleTimelineEventOpenButton onOpen={onOpen} />
      </div>
    }
    title={
      <Title
        label={label}
        onClick={onOpen}
        paymentMethodType={paymentMethod.type}
      />
    }
    icon={
      <Icon style={cursorPointerStyle} onClick={onOpen}>
        {icon}
      </Icon>
    }
    iconColor={iconColor}
    style={{
      minWidth: eventMinWidth,
      marginTop: 20
    }}
    bubbleStyle={{ backgroundColor: 'white' }}
    {...noneEventProps}
  />
)

export function TransfersCollapsibleTimelineEvent(props) {
  const [open, setOpen] = useState(false)

  function handleOpen() {
    if (props.onEventOpened) {
      props.onEventOpened(props.transfer)
    }
    setOpen(true)
  }

  function handleClose() {
    if (props.onEventClosed) {
      props.onEventClosed(props.transfer)
    }
    setOpen(false)
  }

  return open ? (
    <OpenCollapsibleTimelineEvent {...props} onClose={handleClose} />
  ) : (
    <ClosedCollapsibleTimelineEvent {...props} onOpen={handleOpen} />
  )
}
