import React, { useContext, useEffect, useState } from 'react'
import {
  PolicyUpdateContext,
  PolicyUpdateContextValue
} from './PolicyUpdateContext'
import { PolicyService } from '../services/PolicyService'
import ProgressVisualizer from '../utils/ProgressVisualizer'
import ElementNameMappingContext from '../global-state/ElementNameMappingContext'
import { PolicyUpdate } from './entities/PolicyUpdate'
import { useProduct } from '../services/ProductService'
import { Product } from './entities/Product'
import { ServiceCallStatus } from '../utils/useService'
import { RemoveInsuredPerson } from './usecases/RemoveInsuredPerson'
import store from '../customers/CustomerStore'
import { GlobalSnackbarService } from '../utils/GlobalSnackbarService'
import { PolicyUpdateUserInterface } from './boundaries/PolicyUpdateUserInterface'
import { AddInsuredPerson } from './usecases/AddInsuredPerson'
import { PolicyUpdateStore } from './PolicyUpdateStore'
import { RemovePolicyLine } from './usecases/RemovePolicyLine'
import { RemoveInsuredDog } from './usecases/RemoveInsuredDog'
import { Card } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

export function PolicyUpdateContextProvider({ internalPolicy, children }) {
  const productCall = useProduct(
    internalPolicy.productId,
    internalPolicy.productVersion
  )
  const [product, setProduct] = useState<Product | null>(null)
  const nameMappingContext = useContext(ElementNameMappingContext)

  useEffect(() => {
    if (productCall.status === ServiceCallStatus.SUCCESS) {
      setProduct(
        new Product(
          productCall.productResponse.id,
          productCall.productResponse.version,
          productCall.productResponse.pricingConfiguration
        )
      )
    }
  }, [
    internalPolicy.productId,
    internalPolicy.productVersion,
    productCall.status,
    productCall.productResponse,
    nameMappingContext
  ])

  if (productCall.status === ServiceCallStatus.FAILURE) {
    // @ts-ignore
    const error = productCall.error?.data?.message
    return (
      <Card style={{ marginTop: '20px' }}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error || 'Unable to load policy because product could not be loaded'}
        </Alert>
      </Card>
    )
  }

  if (!internalPolicy || !product) return <ProgressVisualizer />

  const { nameMap } = nameMappingContext || {}
  const policyUpdate = PolicyUpdate.for(internalPolicy, nameMap || {})

  const policyUpdateApi = new PolicyService()

  const policyUpdatePresenter = new (class
    implements PolicyUpdateUserInterface
  {
    reloadCustomerView() {
      store.reloadCustomer()
    }

    showError(e: any): void {
      GlobalSnackbarService.emit(
        'Fehler beim ändern des Vertrags: ' +
          JSON.stringify(e.data || e.message),
        'error'
      )
    }
  })()

  const policyUpdateStore = new PolicyUpdateStore(policyUpdate)

  const useCases = {
    RemoveInsuredPerson: new RemoveInsuredPerson(
      policyUpdateApi,
      policyUpdatePresenter,
      policyUpdateStore
    ),
    AddInsuredPerson: new AddInsuredPerson(
      policyUpdateApi,
      policyUpdatePresenter,
      policyUpdateStore
    ),
    RemovePolicyLine: new RemovePolicyLine(
      policyUpdateApi,
      policyUpdatePresenter,
      policyUpdateStore
    ),
    RemoveInsuredDog: new RemoveInsuredDog(
      policyUpdateApi,
      policyUpdatePresenter,
      policyUpdateStore
    )
  }

  const policyUpdateContextValue: PolicyUpdateContextValue = {
    useCases,
    product
  }

  return (
    <PolicyUpdateContext.Provider value={policyUpdateContextValue}>
      {children}
    </PolicyUpdateContext.Provider>
  )
}
