import { getTranslationForKey } from '../../i18n/getTranslationForKey'
import { Button, Divider } from 'antd'
import React from 'react'
import { policyService } from '../../services/PolicyService'
import { useConfirmation } from '../../components/ConfirmationDialogContextProvider'

export function TimelineEventApprovalComponent({ props }) {
  const { event, isLatestEvent } = props

  const confirmDialog = useConfirmation()

  return (
    <>
      {event?.eventToApprove &&
        event.eventToApprove.type === 'PolicyCanceledEventV1' && (
          <>
            <ul style={{ margin: 10, padding: '0 10px' }}>
              <li>
                Kündigungsquelle:{' '}
                <b>
                  {getTranslationForKey(
                    `TIMELINE_EVENT.CANCELLATION.SOURCE.${event.eventToApprove.cancellationSource}`
                  )}
                </b>
              </li>
              <li>
                Kündigungsgrund:{' '}
                <b>
                  {getTranslationForKey(
                    `TIMELINE_EVENT.CANCELLATION.REASON.${event.eventToApprove.cancellationReason}`
                  )}
                </b>
              </li>
            </ul>
          </>
        )}
      {isLatestEvent && (
        <div>
          <Divider style={{ margin: '8px 0' }} />
          <div style={{ textAlign: 'right' }}>
            <Button
              type="danger"
              size="small"
              onClick={() => handleClick(confirmDialog, props, 'REJECTED')}>
              Ablehnen
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleClick(confirmDialog, props, 'APPROVED')}>
              Freigeben
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

const handleClick = (confirmDialog, props, outcome) => {
  const outcomeText = outcome === 'APPROVED' ? 'FREIGEBEN' : 'ABLEHNEN'

  confirmDialog({
    variant: 'danger',
    catchOnCancel: true,
    title: 'Änderung',
    description: `Sind Sie sicher, dass Sie diesen Vorgang ${outcomeText} möchten?`,
    primaryButtonText: 'Abbrechen',
    secondaryButtonText: 'Bestätigen'
  })
    .then(() => {
      const { policyId, customerId, eventId } = props.event
      policyService
        .createApprovalOutcome(customerId, policyId, eventId, outcome)
        .then(() => {
          window.location.reload()
        })
    })
    .catch(() => {
      // do nothing, user cancelled
    })
}
