import React from 'react'
import TextField from '../../components/TextField'
import SelectField from '../../components/SelectField'
import { FieldArray } from 'react-final-form-arrays'
import * as Validators from '../../utils/Validators'
import * as Formatters from '../../utils/Formatters'
import DatePickerField from '../../components/DatePickerField'
import { FixedColumnCountGridLayout } from '../../components/GridLayouts'
import CustomerCountrySelection from './CustomerCountrySelection'
import { RemoveButton } from '../../components/RemoveButton'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { FormSpy } from 'react-final-form'
import MarketingConsentFields from './MarketingConsentFields'
import { InputLabel } from '@material-ui/core'

export function CustomerDataFields({ type, allowEUCountries }) {
  return (
    <FixedColumnCountGridLayout columnCount={2}>
      <div>
        {type === 'LEGAL_ENTITY' ? (
          <LegalEntityFields />
        ) : (
          <NaturalPersonFields />
        )}
      </div>
      <div>
        <TextField
          allowNull={true}
          name={`companyName`}
          floatingLabelText="Firma"
        />
        <FieldArray name="addresses">
          {({ fields }) =>
            fields.map((name, index) => (
              <CustomerAddress
                key={index}
                name={name}
                allowEUCountries={allowEUCountries}
              />
            ))
          }
        </FieldArray>

        <TextField allowNull={true} name="email" floatingLabelText="E-Mail" />
        <TextField
          allowNull={true}
          name="phone"
          floatingLabelText="Telefon"
          validate={Validators.phone}
          format={Formatters.phoneInternational}
          formatOnBlur={true}
        />
        {type === 'LEGAL_ENTITY' && (
          <div>
            <TextField
              allowNull={true}
              name="contactPerson.firstName"
              floatingLabelText="Vorname"
            />
            <TextField
              allowNull={true}
              name="contactPerson.lastName"
              floatingLabelText="Nachname"
            />
            <SelectField
              name="contactPerson.gender"
              floatingLabelText="Geschlecht">
              <option value="MALE">männlich</option>
              <option value="FEMALE">weiblich</option>
              <option value="DIVERSE">divers</option>
              <option value="PREFER_NOT_TO_MENTION">keine angabe</option>
              <option value="UNKNOWN">unbekannt</option>
              <option value="NOT_APPLICABLE">nicht zutreffend</option>
            </SelectField>
          </div>
        )}
        <div>
          <InputLabel>Werbeeinwilligung</InputLabel>
          <FormSpy>
            {props => <MarketingConsentFields formInstance={props.form} />}
          </FormSpy>
        </div>
        <TextField
          allowNull={true}
          name="externalId"
          floatingLabelText="Kunden-ID im Partnersystem"
        />
        <TextField
          allowNull={true}
          name="subpartnerId"
          floatingLabelText="Subpartner-ID"
        />
        <TextField
          allowNull={true}
          name="subpartnerEmail"
          floatingLabelText="Subpartner Email"
        />
      </div>
    </FixedColumnCountGridLayout>
  )
}

function NaturalPersonFields() {
  return (
    <div>
      <TextField
        allowNull={true}
        validate={Validators.maxLength(20)}
        name="title"
        floatingLabelText="Titel"
      />
      <TextField
        allowNull={true}
        name="firstName"
        floatingLabelText="Vorname"
      />
      <TextField
        allowNull={true}
        name="lastName"
        floatingLabelText="Nachname"
      />
      <TextField
        allowNull={true}
        name="nationality"
        floatingLabelText="Nationalität"
      />
      <DatePickerField
        allowNull={true}
        name="birthDate"
        floatingLabelText="Geburtsdatum"
      />

      <SelectField name="gender" floatingLabelText="Geschlecht">
        <option value="MALE">männlich</option>
        <option value="FEMALE">weiblich</option>
        <option value="DIVERSE">divers</option>
        <option value="PREFER_NOT_TO_MENTION">keine angabe</option>
        <option value="UNKNOWN">unbekannt</option>
        <option value="NOT_APPLICABLE">nicht zutreffend</option>
      </SelectField>

      <TextField
        allowNull={true}
        name="occupation.type"
        floatingLabelText="Beruf"
      />
      <TextField
        allowNull={true}
        name="occupation.employer"
        floatingLabelText="Arbeitgeber"
      />
    </div>
  )
}

function LegalEntityFields() {
  return (
    <div>
      <TextField allowNull={true} name="name" floatingLabelText="Name" />
      <TextField
        allowNull={true}
        name="taxId"
        floatingLabelText="Steuernummer"
      />
      <AffiliatedCompanies />
    </div>
  )
}

function AffiliatedCompanies() {
  return (
    <FieldArray name="affiliatedCompanies">
      {({ fields }) => (
        <div>
          {fields.map((company, index) => (
            <SpanGridLayout key={company}>
              <TextField
                allowNull={false}
                name={`${company}.name`}
                floatingLabelText="Verbundenes Unternehmen"
              />
              <RemoveButton onClick={() => fields.remove(index)} />
            </SpanGridLayout>
          ))}
          <div style={{ paddingTop: '10px' }}>
            <Button
              color={'primary'}
              onClick={() => fields.push({ name: '' })}
              style={{ float: 'right' }}>
              Verbundenes Unternehmen hinzufügen
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
}

export function CustomerAddress({ name, allowEUCountries }) {
  return (
    <>
      <TextField
        allowNull={true}
        name={`${name}.addressPrefix`}
        floatingLabelText="Adresszusatz"
      />
      <TextField
        allowNull={true}
        name={`${name}.street`}
        floatingLabelText="Straße"
      />
      <TextField
        allowNull={true}
        name={`${name}.houseNumber`}
        floatingLabelText="Hausnummer"
      />
      <TextField
        allowNull={true}
        name={`${name}.postCode`}
        floatingLabelText="PLZ"
      />
      <TextField
        allowNull={true}
        name={`${name}.city`}
        floatingLabelText="Ort"
      />

      <CustomerCountrySelection
        name={`${name}.country`}
        allowEUCountries={allowEUCountries}
        floatingLabelText="Land"
      />
    </>
  )
}

const SpanGridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  > div:first-child {
    grid-column: span 8;
  }
`
