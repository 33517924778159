import * as React from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import { FormattedMessage } from 'react-intl'

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  table: {
    width: 'auto'
  },
  firstCell: {
    paddingLeft: 0,
    borderBottom: 'none'
  },
  tableCell: {
    borderBottom: 'none'
  },
  buttonLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  buttonLabelSmall: {
    fontSize: '0.75em'
  }
})

function UserDetailsWithoutStyles({ classes, history, user }) {
  const hasMFA = user.preferredMFA && user.preferredMFA !== 'NOMFA'
  const rows = [
    {
      label: <FormattedMessage id="NAME" tagName="strong" />,
      value: `${user.attributes.givenName} ${user.attributes.familyName}`
    },
    {
      label: <FormattedMessage id="UUID" tagName="strong" />,
      value: user.username
    },
    {
      label: <FormattedMessage id="EMAIL" tagName="strong" />,
      value: (
        <React.Fragment>
          {user.attributes.email}{' '}
          {user.attributes.email_verified && (
            <DoneIcon
              style={{ marginLeft: '0.5em', verticalAlign: 'inherit' }}
            />
          )}
        </React.Fragment>
      )
    },
    {
      label: <FormattedMessage id="MFA" tagName="strong" />,
      value: (
        <FormattedMessage
          id={hasMFA ? `MFA_TYPE_${user.preferredMFA}` : 'MFA_NOT_SETUP'}
        />
      )
    }
  ]

  return (
    <Paper elevation={2} className={classes.root}>
      <Typography gutterBottom={true} variant="h5">
        <FormattedMessage id="USER_DETAILS" />
      </Typography>

      <Table className={classes.table}>
        <TableBody>
          {rows.map((row, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell className={classes.firstCell}>{row.label}</TableCell>
                <TableCell className={classes.tableCell}>{row.value}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <Button
        color="primary"
        variant="contained"
        onClick={() => history.push('/settings/mfa-setup')}>
        <div className={classes.buttonLabel}>
          <FormattedMessage
            id={hasMFA ? 'MFA_ACTION_RESYNC' : 'MFA_ACTION_SETUP'}
            tagName="div"
          />
          {hasMFA && (
            <div className={classes.buttonLabelSmall}>
              <FormattedMessage id="MFA_ACTION_RESYNC_HINT" />
            </div>
          )}
        </div>
      </Button>
    </Paper>
  )
}

export const UserDetails = withStyles(styles)(UserDetailsWithoutStyles)
