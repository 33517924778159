import React, { useMemo } from 'react'

import { BackofficeMicrofrontend } from '../utils/BackofficeMicrofrontend.tsx'

export default function CreatePolicyWizardMicrofrontend({ testData }) {
  const customProps = useMemo(
    () => ({
      testData
    }),
    [testData]
  )

  return (
    <BackofficeMicrofrontend
      name="policy-creation"
      prodUrl={'https://bo-policy-creation.web.app'}
      localUrl={'http://localhost:3031'}
      additionalCustomProps={customProps}
    />
  )
}
