import React from 'react'
import grey from '@material-ui/core/colors/grey'
import { CollapsibleTimelineEvent } from '../../../utils/timeline/CollapsibleTimelineEvent'
import './TimelineAttachmentForm.css'
import { getCountryGermanNameByName } from '../CustomerCountries'
import { displaySalutation } from '../CustomerData'
import { MARKETING_CONSENT_OPTIONS } from '../MarketingConsentFields'

const grey700 = grey['700']

export default function CustomerTimelineEvent({ event, ...noneEventProps }) {
  const style = { fontSize: 16 }
  const iconStyle = { fontSize: 12, height: 10 }
  const contentStyle = { boxShadow: 'none', padding: 0, margin: 0 }

  let componentToRender

  switch (event.eventType) {
    // non-platform-supported GenericCustomerEvent with no data but a type
    case 'CUSTOMER_COMPLETE':
    case 'CUSTOMER_CHANGE_PAYMENT_DETAILS':
      componentToRender = (
        <GenericTimelineEvent
          titleStyle={style}
          subtitleStyle={style}
          iconStyle={iconStyle}
          contentStyle={contentStyle}
          event={event}
          {...noneEventProps}
        />
      )
      break
    case 'CUSTOMER_CREATED':
      componentToRender = (
        <CustomerCreatedTimelineEvent
          titleStyle={style}
          subtitleStyle={style}
          iconStyle={iconStyle}
          contentStyle={contentStyle}
          event={event}
          {...noneEventProps}
        />
      )
      break
    case 'CUSTOMER_UPDATED':
    default:
      componentToRender = (
        <CustomerUpdatedTimelineEvent
          titleStyle={style}
          subtitleStyle={style}
          iconStyle={iconStyle}
          contentStyle={contentStyle}
          event={event}
          {...noneEventProps}
        />
      )
      break
  }

  return componentToRender
}

function CustomerCreatedTimelineEvent({ event, ...noneEventProps }) {
  return (
    <CollapsibleTimelineEvent
      label={event.eventDescription}
      event={event}
      icon="fiber_new"
      iconColor={grey700}
      data-testid="customer-created-timeline-event"
      {...noneEventProps}>
      {renderCustomerDetails(event)}
    </CollapsibleTimelineEvent>
  )
}

function CustomerUpdatedTimelineEvent({ event, ...noneEventProps }) {
  return (
    <CollapsibleTimelineEvent
      label={event.eventDescription}
      event={event}
      icon="edit"
      iconColor={grey700}
      data-testid="customer-updated-timeline-event"
      {...noneEventProps}>
      {renderCustomerDetails(event)}
    </CollapsibleTimelineEvent>
  )
}

function GenericTimelineEvent({ event, ...noneEventProps }) {
  return (
    <CollapsibleTimelineEvent
      label={event.eventDescription}
      event={event}
      icon="assignment_late"
      iconColor={grey700}
      {...noneEventProps}
    />
  )
}

const renderCustomerDetails = function (event) {
  return (
    <ul style={{ margin: 10, padding: 0 }}>
      {event.email && (
        <li>
          Email: <b>{event.email}</b>
        </li>
      )}
      {event.title && (
        <li>
          Titel: <b>{event.title}</b>
        </li>
      )}
      {event.gender && (
        <li>
          Geschlecht: <b>{event.gender}</b>
        </li>
      )}
      {event.name && (
        <li>
          Name: <b>{event.name}</b>
        </li>
      )}
      {event.legalEntityName && (
        <li>
          Name: <b>{event.legalEntityName}</b>
        </li>
      )}
      {event.firstName && (
        <li>
          Vorname: <b>{event.firstName}</b>
        </li>
      )}
      {event.lastName && (
        <li>
          Nachname: <b>{event.lastName}</b>
        </li>
      )}
      {event.birthDate && (
        <li>
          Geburtsdatum: <b>{event.birthDate}</b>
        </li>
      )}
      {event.nationality && (
        <li>
          Nationalität: <b>{event.nationality}</b>
        </li>
      )}
      {event.phone && (
        <li>
          Telefonnummer: <b>{event.phone}</b>
        </li>
      )}
      {event.marketingConsents && (
        <li>
          Werbeeinwilligung:{' '}
          {
            <ul>
              {MARKETING_CONSENT_OPTIONS.map(({ label, value }) => {
                return (
                  <li key={value}>
                    {`${label} : `}
                    <strong>
                      {`${
                        event.marketingConsents.includes(value) ? 'Ja' : 'Nein'
                      }`}
                    </strong>
                  </li>
                )
              })}
            </ul>
          }
        </li>
      )}
      {event.contactPerson && event.contactPerson.firstName && (
        <li>
          Kontaktperson:
          <ul key={event.contactPerson.firstName}>
            {event.contactPerson.gender && (
              <li>
                Anrede: <b>{displaySalutation(event.contactPerson.gender)}</b>
              </li>
            )}
            {event.contactPerson.firstName && (
              <li>
                Vorname: <b>{event.contactPerson.firstName}</b>
              </li>
            )}
            {event.contactPerson.lastName && (
              <li>
                Nachname: <b>{event.contactPerson.lastName}</b>
              </li>
            )}
          </ul>
        </li>
      )}
      {event.taxId && (
        <li>
          Steuer-Nr.: <b>{event.taxId}</b>
        </li>
      )}

      {event.externalId && (
        <li>
          Externe ID: <b>{event.externalId}</b>
        </li>
      )}
      {event.subpartnerId && (
        <li>
          Subpatner ID: <b>{event.subpartnerId}</b>
        </li>
      )}
      {event.subpartnerEmail && (
        <li>
          Subpatner Email: <b>{event.subpartnerEmail}</b>
        </li>
      )}

      {event.occupation && (
        <span>
          <li>
            Anstellungsverhältnis: <b>{event.occupation.type}</b>
          </li>
          <li>
            Arbeitgeber: <b>{event.occupation.employer}</b>
          </li>
        </span>
      )}
      <li>
        Firma: <b>{event.companyName}</b>
      </li>
      {event.addresses && (
        <li>
          Adresse:
          {event.addresses.map(address => {
            return (
              <ul key={address.street}>
                <li>
                  Adresszusatz: <b>{address.addressPrefix}</b>
                </li>
                <li>
                  Straße: <b>{address.street}</b>
                </li>
                <li>
                  Hausnummer: <b>{address.houseNumber}</b>
                </li>
                <li>
                  PLZ: <b>{address.postCode}</b>
                </li>
                <li>
                  Ort: <b>{address.city}</b>
                </li>
                <li>
                  Land: <b>{getCountryGermanNameByName(address.country)}</b>
                </li>
              </ul>
            )
          })}
        </li>
      )}
    </ul>
  )
}
