import React, { useState } from 'react'
import { Form } from 'react-final-form'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import DialogContent from '@material-ui/core/DialogContent'

import DatePickerField from '../../../../../components/DatePickerField'
import { PaymentsService } from '../../../../../services/PaymentsService'
import { formatDate, formatToISODate } from '../../../../../utils/Formatters'

import styles from './Scheduled.module.scss'

const ScheduledItem = ({ paymentData }) => {
  const [show, toggleShow] = useState(false)
  const [date, setDate] = useState(paymentData.dueDate)

  const handleDateChange = newDate => {
    setDate(newDate)
  }

  return (
    <>
      <Button className={styles.listItem} onClick={() => toggleShow(true)}>
        <ListItem>
          <Box className={styles.dateRangeWrapper}>
            <Box className={styles.dateRange}>
              {paymentData.amount > 0
                ? `${formatDate(
                    paymentData.metadata.accountingStartDate
                  )} - ${formatDate(paymentData.metadata.accountingEndDate)}`
                : `Due: ${formatDate(date)}`}
            </Box>
            <Box>{paymentData.amount} €</Box>
          </Box>
        </ListItem>
      </Button>
      {show ? (
        <DatePicker
          onChange={handleDateChange}
          onClose={() => toggleShow(false)}
          date={date}
          paymentData={paymentData}
        />
      ) : null}
    </>
  )
}

function DatePicker({ onClose, onChange, paymentData, date }) {
  const [error, setError] = useState()

  const onSubmit = async values => {
    setError(null)

    try {
      const dueDate = formatToISODate(values.dueDate)

      await PaymentsService.setScheduledPayments({
        policyId: paymentData.policyId,
        idempotentId: paymentData.idempotentId,
        status: paymentData.status,
        dueDate: dueDate
      })

      onChange(dueDate)
      onClose()
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ dueDate: date }}
      render={({ handleSubmit }) => (
        <Dialog open={true} maxWidth="md">
          {error ? (
            <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>
          ) : null}
          <DialogTitle>Change due date</DialogTitle>
          <DialogContent style={{ paddingTop: 8 }}>
            <form className={styles.form} onSubmit={handleSubmit} noValidate>
              <DatePickerField
                minDate={new Date()}
                maxDate={new Date(paymentData.dueDate)}
                name="dueDate"
                floatingLabelText="Fälligkeitsdatum"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSubmit}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  )
}

export default ScheduledItem
