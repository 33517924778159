import { useCallback } from 'react'
import { BackofficeAPI } from '@elementinsurance/cognito-auth'
import { errorHandler, notFoundErrorHandler } from '../utils/RestErrorHandler'
import useService, { ServiceCallStatus } from '../utils/useService'
import { DateTime } from 'luxon'
import { PolicyUpdateNetworkInterface } from '../policy-update/boundaries/PolicyUpdateNetworkInterface'

export class PolicyService implements PolicyUpdateNetworkInterface {
  cancelPolicy(
    policy,
    cancelDate,
    cancellationSource,
    cancellationReason,
    cancellationRequestDate
  ) {
    return BackofficeAPI.post(
      `/customers/${policy.customerId}/policies/${policy.id}/cancellation`,
      {
        cancelDate,
        cancellationSource,
        cancellationReason,
        cancellationRequestDate
      }
    )
  }

  revokePolicy(policy, cancellationSource, cancellationReason) {
    return BackofficeAPI.post(
      `/customers/${policy.customerId}/policies/${policy.id}/revocation`,
      {
        cancellationSource,
        cancellationReason
      }
    )
  }

  updateCustomersPolicy(customerId, policyId, updatePolicyRequest) {
    return BackofficeAPI.put(
      `/customers/${customerId}/policies/${policyId}`,
      updatePolicyRequest
    )
  }

  getPolicyEventTypes() {
    return BackofficeAPI.get(`/policy-event-types`).catch(errorHandler())
  }

  getCancellationSources() {
    return BackofficeAPI.get(`/policies/cancellation/sources`).catch(
      errorHandler()
    )
  }

  getCancellationReasons() {
    return BackofficeAPI.get(`/policies/cancellation/reasons`).catch(
      errorHandler()
    )
  }

  addGenericPolicyEvent(customerId, policyId, eventType) {
    return BackofficeAPI.post(
      `/customers/${customerId}/policies/${policyId}/generic-events`,
      {
        eventType
      }
    ).catch(errorHandler())
  }

  getPolicy(policyId): Promise<PolicyResponse> {
    const effectiveDate = DateTime.local().toUTC().toISO().toString()
    return BackofficeAPI.get(
      `/policies/${policyId}?effectiveDate=${effectiveDate}`
    ).catch(errorHandler())
  }

  loadPayments(policyId) {
    return BackofficeAPI.get(`/policies/${policyId}/payments`).catch(
      notFoundErrorHandler()
    )
  }

  createDunningReminder(policyId, createDunningReminderRequest) {
    return BackofficeAPI.post(
      `/policies/${policyId}/dunning`,
      createDunningReminderRequest
    )
  }

  getDunningCalculation(policyId) {
    return BackofficeAPI.get(`/policies/${policyId}/dunning-calculation`).catch(
      notFoundErrorHandler()
    )
  }

  updateExternalPolicyId(customerId, policyId, externalPolicyId) {
    return BackofficeAPI.put(
      `/customers/${customerId}/policies/${policyId}/externalPolicyId`,
      {
        effectiveAt: DateTime.local().toUTC().toISO().toString(),
        externalPolicyId
      }
    ).catch(errorHandler())
  }

  createPortfolioCancellationNotice(
    partnerId,
    portfolioCancellationNoticeRequest,
    testData
  ) {
    return BackofficeAPI.post(
      `/portfolio/${partnerId}/cancellation-notice?testData=${testData}`,
      portfolioCancellationNoticeRequest
    ).catch(errorHandler())
  }

  triggerPortfolioCancellation(partnerId, testData) {
    return BackofficeAPI.post(
      `/portfolio/${partnerId}/cancel?testData=${testData}`,
      partnerId
    ).catch(errorHandler())
  }

  getPortfolioCancellationNotice(partnerId) {
    return BackofficeAPI.get(
      `/portfolio/${partnerId}/cancellation-notice`
    ).catch(notFoundErrorHandler())
  }

  createApprovalOutcome(customerId, policyId, eventId, outcome) {
    return BackofficeAPI.post(
      `/customers/${customerId}/policies/${policyId}/eventId/${eventId}/outcome/${outcome}`,
      outcome
    ).catch(errorHandler())
  }
}

export type PolicyResponse = {
  id: string
  customerId: string
  productId: string
  productVersion: string
  coverStart: string
  dynamicCommission: unknown | null
  policyLines: {
    productLineId: string
    previousInsurer: string | null
    insuredEntities: unknown | null
    insuredPersons: unknown | null // legacy field, the new one is insuredEntities
  }[]
  elements: PolicyResponseElement[]
  premium: number
}

type PolicyResponseElement = {
  name: string
  previousInsurer?: string
  insuredEntities?: unknown // TODO: maybe this field doesnt exist at all?
  options: Record<string, unknown>
  attributes: Record<string, unknown>
}

export const policyService = new PolicyService()

export function useInternalPolicy(policyId: string): {
  policyResponse: any
  status: ServiceCallStatus
  error: string | null
} {
  const policyServiceCallback = useCallback(
    () => policyService.getPolicy(policyId),
    [policyId]
  )
  const { data, status, error } = useService(policyServiceCallback)

  return { policyResponse: data, status, error }
}
