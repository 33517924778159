import TextField from '../../../../components/TextField'
import * as Validators from '../../../../utils/Validators'
import { ibanFieldMask } from './PaymentDetailsUtils'

export default function PaymentDetailsForm() {
  return (
    <div style={{ marginTop: 30 }}>
      <div>
        <TextField
          name="accountHolder"
          floatingLabelText="Kontoinhaber"
          validate={Validators.required}
        />
      </div>
      <div>
        <TextField
          floatingLabelText="IBAN"
          name="iban"
          parse={ibanFieldMask}
          validate={Validators.compose(Validators.required, Validators.iban)}
        />
      </div>
    </div>
  )
}
