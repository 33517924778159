import { BackofficeAPI, SandboxAPI } from '@elementinsurance/cognito-auth'
import { errorHandler } from '../utils/RestErrorHandler'

export class MtaApiService {
  static create(quote, testData) {
    return testData
      ? SandboxAPI.post('/quotes/mta', quote).catch(errorHandler())
      : BackofficeAPI.post('/quotes/mta', quote).catch(errorHandler())
  }
}
