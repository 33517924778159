import { BackofficeAPI } from '@elementinsurance/cognito-auth'
import { valueIsString } from '../stringUtils'

export class UserService {
  static addUserToGroup(pool, group, user) {
    return BackofficeAPI.put(
      `/user-management/pools/${pool}/groups/${group}/users/${user}`
    )
  }

  static create(pool, data) {
    return BackofficeAPI.post(`/user-management/pools/${pool}/users`, data)
  }

  static update(pool, data, id = '') {
    return BackofficeAPI.put(`/user-management/pools/${pool}/users/${id}`, data)
  }

  static delete(pool, id) {
    return BackofficeAPI.delete(`/user-management/pools/${pool}/users/${id}`)
  }

  static disableUser(pool, id) {
    return BackofficeAPI.put(
      `/user-management/pools/${pool}/users/${id}/enabled`,
      {
        enabled: false
      }
    )
  }

  static disableUserMfa(pool, id) {
    return BackofficeAPI.delete(
      `/user-management/pools/${pool}/users/${id}/mfa`
    )
  }

  static enableUser(pool, id) {
    return BackofficeAPI.put(
      `/user-management/pools/${pool}/users/${id}/enabled`,
      {
        enabled: true
      }
    )
  }

  static info(pool, id) {
    return BackofficeAPI.get(`/user-management/pools/${pool}/users/${id}/info`)
  }

  static account() {
    return BackofficeAPI.get(`/user-management/account`)
  }

  static loadAll(pool, filters) {
    let search = new URLSearchParams()

    if (filters) {
      Object.keys(filters).forEach(key => {
        const value = filters[key]

        if (valueIsString(value) || typeof value === 'number') {
          search.set(key, value)
        }
      })
    }

    let searchString = `${search.toString()}`

    if (searchString) {
      searchString = '?' + searchString
    }

    return BackofficeAPI.get(
      `/user-management/pools/${pool}/users${searchString}`
    )
  }

  static loadGroups(pool) {
    return BackofficeAPI.get(`/user-management/pools/${pool}/groups`)
  }

  static loadUser(pool, uuid) {
    return BackofficeAPI.get(`/user-management/pools/${pool}/users/${uuid}`)
  }

  static removeUserFromGroup(pool, group, user) {
    return BackofficeAPI.delete(
      `/user-management/pools/${pool}/groups/${group}/users/${user}`
    )
  }

  static resendInvitation(pool, user) {
    return BackofficeAPI.put(
      `/user-management/pools/${pool}/users/${user}/resend-invitation`
    )
  }
}
