import React from 'react'
import SelectField from '../../../../components/SelectField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import * as Validators from '../../../../utils/Validators'
import { formatDate } from '../../../../utils/Formatters'

export default function SendDunningForm({
  balance,
  dueDate,
  firstReminderInstant
}) {
  return (
    <div style={{ marginTop: 30 }}>
      <h4>Zahlungserinnerung</h4>
      <SelectField
        name="dunningType"
        floatingLabelText="Beitragsart"
        validate={Validators.required}>
        <option value="FIRST_PREMIUM">Erstbeitrag</option>
        <option value="FOLLOWING_PREMIUM">Folgebeitrag</option>
      </SelectField>
      <SelectField
        name="reminderType"
        floatingLabelText="Zahlungserinnerung-Typ"
        validate={Validators.required}>
        <option value="FIRST_REMINDER">Erste Zahlungserinnerung</option>
        <option value="SECOND_REMINDER" disabled={!firstReminderInstant}>
          Zweite Zahlungserinnerung
        </option>
      </SelectField>
      <p>
        Dies sind die Informationen, die im Zahlungserinnerungsdokument
        enthalten sind:
      </p>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Fälligkeitsdatum:</TableCell>
            <TableCell>{formatDate(dueDate)}</TableCell>
          </TableRow>
          {firstReminderInstant && (
            <TableRow>
              <TableCell>Datum der letzten Erinnerung:</TableCell>
              <TableCell>{formatDate(firstReminderInstant)}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Betrag:</TableCell>
            <TableCell>{balance}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
