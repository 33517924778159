import React, { Component } from 'react'
import { observer } from 'mobx-react'
import store from '../CustomerStore'
import CustomerDetails from './CustomerDetails'
import ErrorMessage from '../../utils/ErrorMessage'
import ProgressVisualizer from '../../utils/ProgressVisualizer'
import { customerService } from '../../services/CustomerService'
import { policyService } from '../../services/PolicyService'
import Button from '@material-ui/core/Button/Button'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import { CustomerContextProvider } from './CustomerContextProvider'

class CustomerDetailsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customerEventTypes: null,
      policyEventTypes: null,
      refreshHint: false
    }
    this.timeout = null
  }

  componentDidMount() {
    this.loadEventTypes()
    store.loadCustomer(this.props.match.params.customerId)
    this.startRefreshHintTimeout()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.match.params.customerId !== prevProps.match.params.customerId
    ) {
      this.loadEventTypes()
      store.loadCustomer(this.props.match.params.customerId)

      this.setState({ refreshHint: false })

      this.clearRefreshHintTimeout()
      this.startRefreshHintTimeout()
    }
  }

  componentWillUnmount() {
    this.clearRefreshHintTimeout()
  }

  loadEventTypes = () =>
    Promise.all([
      customerService.getCustomerEventTypes(),
      policyService.getPolicyEventTypes()
    ]).then(([customerEventTypes, policyEventTypes]) => {
      this.setState({ customerEventTypes, policyEventTypes })
    })

  refreshTimeoutInMillis = () => 8 * 60 * 60 * 1000 // 8h

  startRefreshHintTimeout = () =>
    (this.timeout = setTimeout(
      this.showRefreshHint,
      this.refreshTimeoutInMillis()
    ))

  clearRefreshHintTimeout = () => clearTimeout(this.timeout)

  showRefreshHint = () => this.setState({ refreshHint: true })

  renderContent = () => {
    if (
      store.loading ||
      !this.state.customerEventTypes ||
      !this.state.policyEventTypes
    ) {
      return <ProgressVisualizer />
    } else if (store.loadCustomerError) {
      return <ErrorMessage error={store.loadCustomerError} />
    } else {
      const {
        selectedCustomer: { testData }
      } = store
      return (
        <CustomerContextProvider policies={store.policies} testData={testData}>
          <CustomerDetails
            store={store}
            customerEventTypes={this.state.customerEventTypes}
            policyEventTypes={this.state.policyEventTypes}
          />
        </CustomerContextProvider>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderContent()}
        <RefreshSnackbar
          open={this.state.refreshHint}
          refreshFunction={() => window.location.reload()}
        />
      </React.Fragment>
    )
  }
}

export function RefreshSnackbar({ open, refreshFunction }) {
  const refreshAction = (
    <Button color="primary" size="small" onClick={refreshFunction}>
      Neu laden
    </Button>
  )

  return (
    <Snackbar
      open={open}
      data-testid="refresh-snackbar"
      message="Bitte laden Sie die Seite neu. Daten könnten veraltet sein."
      action={refreshAction}
    />
  )
}

export default observer(CustomerDetailsContainer)
