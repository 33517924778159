import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  variant: 'danger' | 'info'
  title: string
  description: string
  primaryButtonText: string
  secondaryButtonText: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}

export function ConfirmationDialog({
  open,
  title,
  variant,
  description,
  primaryButtonText,
  secondaryButtonText,
  onSubmit,
  onClose
}: ConfirmationDialogProps) {
  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title" data-testid="confirmation-dialog">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {variant === 'danger' && (
          <>
            <Button
              color="primary"
              onClick={onClose}
              data-testid="dialog-button-cancel"
              autoFocus
              name="cancelButton">
              {primaryButtonText}
            </Button>
            <Button
              color="secondary"
              onClick={onSubmit}
              data-testid="dialog-button-confirm"
              name="confirmButton">
              {secondaryButtonText}
            </Button>
          </>
        )}

        {variant === 'info' && (
          <Button
            color="secondary"
            onClick={onSubmit}
            data-testid="dialog-button-confirm"
            name="confirmButton">
            {secondaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
