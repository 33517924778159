import * as React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  card: {
    minWidth: 475,
    maxWidth: 475,
    position: 'relative'
  },
  cardActions: {
    padding: `${theme.spacing()}px ${theme.spacing(3)}px`,
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  cardWrapper: {
    margin: '100px 50px',
    display: 'flex',
    justifyContent: 'center'
  },
  progressWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.75)'
  }
})

const WrapperComponent = ({
  classes,
  children,
  footer = null,
  header = null,
  loading = false
}) => (
  <div className={classes.cardWrapper}>
    <Card raised={true} className={classes.card}>
      <CardHeader title={header} />
      <CardContent>{children}</CardContent>

      {footer && (
        <CardActions className={classes.cardActions}>{footer}</CardActions>
      )}
      {loading && (
        <div className={classes.progressWrapper}>
          <CircularProgress size={75} />
        </div>
      )}
    </Card>
  </div>
)

WrapperComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

export const Wrapper = withStyles(styles)(WrapperComponent)
