import React from 'react'
import { Product } from './entities/Product'

export type PolicyUpdateContextValue = {
  useCases: object
  product: Product
}

export const PolicyUpdateContext =
  React.createContext<PolicyUpdateContextValue | null>(null)
