import * as React from 'react'
import { Authenticator } from '@elementinsurance/cognito-auth'

import { ElementLogo } from '../Logo'
import { Button } from './Button'
import { ClickableText } from './ClickableText'
import { ErrorMessage } from './ErrorMessage'
import { Headline } from './Headline'
import { Input } from './Input'
import { Wrapper } from './Wrapper'
import config from './auth-config'

export function Auth({ children }) {
  return (
    <Authenticator
      config={config}
      requireMfa={!window.Cypress}
      Components={{
        Button: Button,
        ClickableText: ClickableText,
        ErrorMessage: ErrorMessage,
        Headline: Headline,
        Logo: ElementLogo,
        Input: Input,
        Wrapper: Wrapper
      }}>
      {children}
    </Authenticator>
  )
}
