import React from 'react'
import * as _ from 'lodash'

export default class AttributesCombinePage extends React.Component {
  state = {
    inputString: '',
    outputString: ''
  }

  onLeftInputChange = e => {
    const inputString = e.target.value

    const inputArrays = []

    let rows = _.split(_.trim(inputString), '\n')
    _.forEach(rows, row => {
      const attributeValues = _.split(row, ',')
        .map(value => _.trim(value))
        .filter(value => value !== '')
      inputArrays.push(attributeValues)
    })

    this.setState({
      inputString,
      outputString: doConversion(inputArrays)
    })
  }

  render() {
    const textareaStyle = {
      width: '100%',
      whiteSpace: 'pre-wrap',
      boxSizing: 'border-box',
      height: '80vh'
    }

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Enter each attribute per row, separate attribute values by comma
          <textarea
            onChange={this.onLeftInputChange}
            style={textareaStyle}
            value={this.state.inputString}></textarea>
        </div>

        <div style={{ width: 50 }}></div>

        <div style={{ flex: 1 }}>
          Possible attributes values combination:
          <textarea
            readOnly={true}
            style={textareaStyle}
            value={this.state.outputString}></textarea>
        </div>
      </div>
    )
  }
}

function doConversion(inputArrays) {
  const outputArray = buildCombination(inputArrays).filter(
    row => row[0] !== row[1]
  )

  return arrayToCsv(outputArray)
}

function arrayToCsv(outputArrays) {
  if (!_.isArray(outputArrays)) return outputArrays

  const joinedCombination = outputArrays.map(combination =>
    _.isArray(combination) ? combination.join(',') : combination
  )

  return _.join(joinedCombination, '\n')
}

function buildCombination(input) {
  if (input.length === 1) {
    return input[0]
  } else {
    let result = []
    let remainingCombo = buildCombination(input.slice(1))

    for (let leftItem of remainingCombo) {
      for (let rightItem of input[0]) {
        result.push([rightItem].concat(leftItem))
      }
    }

    return result
  }
}
