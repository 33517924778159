import { DateTime } from 'luxon'
import { PolicyUpdate } from '../entities/PolicyUpdate'
import { PolicyUpdateNetworkInterface } from '../boundaries/PolicyUpdateNetworkInterface'
import { PolicyUpdateUserInterface } from '../boundaries/PolicyUpdateUserInterface'
import { PolicyUpdatePersistenceInterface } from '../boundaries/PolicyUpdatePersistenceInterface'
import { PersonToInsure } from '../entities/PersonToInsure'
import { UseCase } from './UseCase'

type AddInsuredPersonInput = {
  customerId: string
  policyId: string
  productLineId: string
  personToInsure: PersonToInsure
  effectiveDate: DateTime
}

export class AddInsuredPerson implements UseCase {
  constructor(
    private _networkInterface: PolicyUpdateNetworkInterface,
    private _userInterface: PolicyUpdateUserInterface,
    private _store: PolicyUpdatePersistenceInterface
  ) {}

  async execute(input: AddInsuredPersonInput) {
    try {
      const policyUpdate: PolicyUpdate = this._store.loadPolicyUpdate()

      policyUpdate.addPersonToElement(input.personToInsure, input.productLineId)

      const requestBody = policyUpdate.toUpdatePolicyRequestEffectiveAt(
        input.effectiveDate
      )

      await this._networkInterface.updateCustomersPolicy(
        input.customerId,
        input.policyId,
        requestBody
      )

      this._store.storePolicyUpdate(policyUpdate)

      this._userInterface.reloadCustomerView()
    } catch (e) {
      this._userInterface.showError(e)
    }
  }
}
