import React from 'react'
import PropTypes from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import FormGroup from '@material-ui/core/FormGroup'
import { Form } from 'react-final-form'

import CheckboxField from '../../components/CheckboxField'

export function TableFilterDialog({
  data,
  filterBy,
  onClose,
  selected,
  ...other
}) {
  if (!filterBy) {
    return null
  }

  const initialValues = data
    .map(entry => entry[filterBy])
    .reduce((acc, state) => {
      if (!acc[state]) {
        acc[state] = selected.indexOf(state) > -1
      }

      return acc
    }, {})

  return (
    <Form initialValues={initialValues} onSubmit={onClose} subscription={{}}>
      {({ handleSubmit }) => (
        <Dialog
          onClose={handleSubmit}
          aria-labelledby="simple-dialog-title"
          {...other}>
          <DialogTitle id="simple-dialog-title">
            Filtern nach Spalte <strong>{filterBy}</strong>
          </DialogTitle>
          <DialogContent>
            <FormGroup>
              {Object.keys(initialValues).map(key => (
                <CheckboxField key={key} label={key} name={key} />
              ))}
            </FormGroup>
          </DialogContent>
        </Dialog>
      )}
    </Form>
  )
}

TableFilterDialog.propTypes = {
  data: PropTypes.array.isRequired,
  filterBy: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired
}
