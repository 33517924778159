import { PersonToInsure } from '../../policy-update/entities/PersonToInsure'

export function mapQuoteRequestFrom(formData, product) {
  return {
    productId: product.id,
    productVersion: product.version,
    elements: product.elements
      .filter(elememt => elememt.name !== 'boat-kasko-assistance')
      .map(element =>
        mapToQuoteElement(element.name, formData.elements[element.name])
      ),
    payment: mapToPayment(formData.paymentFrequency),
    configuration: formData.configuration ? getConfiguration(formData) : null
  }
}

export function mapQuoteRequestFromAccidentPolicy(formData, product) {
  return {
    productId: product.id,
    productVersion: formData.policy.productVersion,
    elements: product.elements
      .filter(elememt => elememt.name !== 'boat-kasko-assistance')
      .map(element =>
        mapToQuoteElement(element.name, formData.elements[element.name])
      ),
    payment: mapToPayment(formData.paymentFrequency),
    configuration: formData.configuration ? getConfiguration(formData) : null
  }
}

function getConfiguration(formData) {
  const commission = formData.configuration.commission
    ? formData.configuration.commission
    : null
  const premiumAdjustmentFactors = [
    formData.configuration.performance
      ? { name: 'performance', value: formData.configuration.performance }
      : null,
    formData.configuration.thirdPartyRepair
      ? {
          name: 'thirdPartyRepair',
          value: formData.configuration.thirdPartyRepair
        }
      : null
  ].filter(f => f != null)

  if (premiumAdjustmentFactors.length === 0) {
    return { commission }
  }

  return { commission, premiumAdjustmentFactors }
}

export function mapPolicyUpdateRequest(formData) {
  const elements = Object.keys(formData.elements).map(name =>
    mapToElement(name, formData.elements[name])
  )

  return {
    effectiveDate: formData.effectiveDate,
    elements: elements,
    payment: mapToPayment(formData.paymentFrequency),
    configuration: formData.configuration ? getConfiguration(formData) : null
  }
}

export function moveInsuredEntityPropsToAttributes(ie) {
  let entity = {}

  entity.id = ie.id
  entity.type = ie.type
  entity.premium = ie.premium

  entity['attributes'] = ie.attributes || {}
  if (!!ie.configuration) {
    entity['configuration'] = ie.configuration
  }
  if (!!ie.options) {
    entity['options'] = ie.options
  }

  for (var propt in ie) {
    if (
      ie.hasOwnProperty(propt) &&
      [
        'id',
        'type',
        'premium',
        'attributes',
        'configuration',
        'options'
      ].lastIndexOf(propt) === -1
    ) {
      entity.attributes[propt] = ie[propt]
    }
  }

  return entity
}

export function moveInsuredPersonPropsToAttributes(ip) {
  let person = {}

  person.id = ip.id

  person['attributes'] = ip.attributes || {}
  if (!!ip.configuration) {
    person['configuration'] = ip.configuration
  }
  if (!!ip.options) {
    person['options'] = ip.options
  }
  for (var propt in ip) {
    if (
      ip.hasOwnProperty(propt) &&
      ['id', 'attributes', 'options', 'configuration'].lastIndexOf(propt) === -1
    ) {
      person.attributes[propt] = ip[propt]
    }
  }
  return person
}

function mapToElement(name, productLineFormData) {
  const { options = {}, coverStart, attributes } = productLineFormData

  return {
    name,
    coverStart,
    attributes,
    options,
    personsToInsure: productLineFormData.insuredPersons?.map(ip =>
      PersonToInsure.of(ip)
    ),
    previousInsurer: productLineFormData.previousInsurer,
    insuredEntities: productLineFormData.insuredEntities?.map(ie =>
      moveInsuredEntityPropsToAttributes(ie)
    )
  }
}

function isPriceRelevant(name, attributeName) {
  let isPriceRelevantAttributeByPolicyLine = {
    'boat-liability': [
      'discountVoucher',
      'maxEnginePower',
      'boatType',
      'sailArea'
    ],
    'boat-kasko': [
      'boatType',
      'constructionYear',
      'discountVoucher',
      'boatValue',
      'boatTrailerValue',
      'boatExternalEngineValue',
      'boatAuxiliaryBoatValue',
      'boatWatersportEquipmentValue',
      'sailArea',
      'carbonMadeBoat',
      'highValueWoodenBoat',
      'highSpeedBoat',
      'privateRental',
      'marketValueDiscount'
    ]
  }

  return isPriceRelevantAttributeByPolicyLine[name]
    ? isPriceRelevantAttributeByPolicyLine[name].includes(attributeName)
    : true
}

function mapToQuoteElement(name, productLineFormData) {
  const { options = {}, coverStart, attributes } = productLineFormData

  return {
    name,
    coverStart,
    attributes,
    options,
    insuredPersons: productLineFormData.insuredPersons?.map(ip => {
      let person = moveInsuredPersonPropsToAttributes(ip)

      person.attributes = Object.keys(person.attributes)
        .filter(attributeName => isPriceRelevant(name, attributeName))
        .reduce((filteredAttributes, attributeName) => {
          filteredAttributes[attributeName] = person.attributes[attributeName]
          return filteredAttributes
        }, {})

      return person
    }),
    insuredEntities: productLineFormData.insuredEntities?.map(ie => {
      let entity = moveInsuredEntityPropsToAttributes(ie)

      entity.attributes = Object.keys(entity.attributes)
        .filter(attributeName => isPriceRelevant(name, attributeName))
        .reduce((filteredAttributes, attributeName) => {
          filteredAttributes[attributeName] = entity.attributes[attributeName]
          return filteredAttributes
        }, {})

      return entity
    })
  }
}

function mapToPayment(paymentFrequency) {
  return {
    frequency: {
      unit: paymentFrequency || 'YEAR',
      period: 1
    }
  }
}
