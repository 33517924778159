import React from 'react'
import List from '@material-ui/core/List'
import { SchedulePaymentType } from './types'

import ScheduledItem from './ScheduledItem'

import styles from './Scheduled.module.scss'

type ScheduledListProps = {
  payments: SchedulePaymentType[]
}

const ScheduledList = ({ payments }: ScheduledListProps) => {
  return (
    <List className={styles.list}>
      {payments?.length ? (
        payments?.map((paymentData, idx) => {
          return <ScheduledItem key={idx} paymentData={paymentData} />
        })
      ) : (
        <div className={styles.noPayments}>No scheduled payments</div>
      )}
    </List>
  )
}

export default ScheduledList
