import * as React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/AddCircle'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import { FormattedMessage } from 'react-intl'

import { SortableTable } from '../utils/SortableTable/SortableTable'
import { UserListResendInvitationAction } from './UserListResendInvitationAction'

const columns = [
  { id: 'email', label: <FormattedMessage id="COMMON.EMAIL" /> },
  { id: 'name', label: <FormattedMessage id="COMMON.NAME" /> },
  {
    id: 'enabledIcon',
    label: <FormattedMessage id="COMMON.ACTIVE" />,
    orderBy: 'enabled'
  },
  { id: 'status', label: <FormattedMessage id="COMMON.STATUS" /> },
  {
    id: 'createdAt',
    label: <FormattedMessage id="COMMON.CREATED_AT" />,
    orderBy: 'createdAtMillis'
  },
  {
    id: 'actions',
    label: <FormattedMessage id="COMMON.ACTIONS" />,
    notSortable: true
  }
]

export function UserListView({
  name,
  isLoading,
  users,
  onEditUser,
  onNewUser,
  onResendInvitation
}) {
  const data = users
    .map(user => {
      const createdAt = new Date(user.createDate)

      return {
        ...user,
        email: user.attributes.email,
        name: `${user.attributes.givenName ?? ''} ${
          user.attributes.familyName ?? ''
        }`.trim(),
        enabledIcon: user.enabled ? <DoneIcon /> : <ClearIcon />,
        createdAt: createdAt.toLocaleString('de-DE'),
        createdAtMillis: createdAt.getTime()
      }
    })
    .map(user => ({
      ...user,
      actions: [
        <FormattedMessage key="action-edit" id="USER_MANAGEMENT.EDIT_USER">
          {txt => (
            <Tooltip title={txt}>
              <IconButton color="primary" onClick={() => onEditUser(user)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </FormattedMessage>,
        <UserListResendInvitationAction
          key="action-resend-invitation"
          user={user}
          resendInvitation={onResendInvitation}
        />
      ]
    }))

  return (
    <SortableTable
      actions={
        <FormattedMessage id="USER_MANAGEMENT.CREATE_USER">
          {txt => (
            <Tooltip title={txt}>
              <IconButton aria-label={txt} color="primary" onClick={onNewUser}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </FormattedMessage>
      }
      columns={columns}
      data={data}
      defaultOrderBy="email"
      idField="username"
      isLoading={isLoading}
      title={name}
      withPagination={true}
      style={{ margin: '20px 0' }}
    />
  )
}

UserListView.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.array
}
