import React from 'react'
import NavButton from '../NavButton/NavButton'
import useFeatureFlag from '../../utils/useFeatureFlag'
import styles from './AppLayout.module.scss'
import { useGetAuthorized } from '../../auth/AuthorizationFilter'
import { LogoutOutlined, TableOutlined } from '@ant-design/icons'
import ElementLogo from './logo/Logo'
import { Menu } from 'antd'
import {
  useActiveRoutes,
  allRoutes,
  customersPoliciesRoutes,
  usersBrandsRoutes,
  productLine,
  insuredProducts,
  risksRoutes,
  commonRoutes,
  apiRoutes,
  footerRoutes,
  customersOrdersRoutes
} from './routes'

export default function AppMenu({ auth }) {
  const getAuthorized = useGetAuthorized()

  const isInsuredProductsReady = useFeatureFlag('insuredProducts')

  const getRoutes = routes =>
    routes
      .filter(i => getAuthorized(i.accessRights))
      .map(i => ({
        key: i.to,
        label: <NavButton key={i.to} {...i} />
      }))

  return (
    <div className={styles.sider}>
      <div className={styles.logo}>
        <SideMenu items={[{ key: 'logo', label: <ElementLogo /> }]} />
      </div>

      <div className={styles.menu}>
        <SideMenu
          items={[
            ...getRoutes(customersPoliciesRoutes),
            ...getRoutes(customersOrdersRoutes),
            { type: 'divider' },
            ...getRoutes(usersBrandsRoutes),
            {
              label: 'Product line',
              key: 'sub1',
              icon: <TableOutlined />,
              children: [
                ...(isInsuredProductsReady
                  ? getRoutes([...productLine, ...insuredProducts])
                  : getRoutes(productLine))
              ]
            },
            ...getRoutes(risksRoutes),
            ...getRoutes(commonRoutes),
            { type: 'divider' },
            ...getRoutes(apiRoutes)
          ]}
        />
      </div>
      <SideMenu
        items={[
          { type: 'divider' },
          ...getRoutes(footerRoutes),
          {
            key: '/logout',
            label: (
              <NavButton
                onClick={auth.onSignOut}
                to="/logout"
                label="Ausloggen"
                Icon={LogoutOutlined}
              />
            )
          }
        ]}
      />
    </div>
  )
}

const SideMenu = ({ items }) => {
  const activeRoutes = useActiveRoutes(allRoutes)
  const keys = activeRoutes.map(i => i.to)

  return (
    <Menu
      items={items}
      theme="light"
      mode="inline"
      defaultSelectedKeys={keys}
      selectedKeys={keys}></Menu>
  )
}
