/**
 * Represents members to specify a type of the complex object in the event timeline.
 */
export enum TimelineContextPayloadType {
  SharedTransfer = 'SharedTransfer',
  PayPalPaymentDetails = 'PayPalPaymentDetails',
  TransactionConfirmedWithExtra = 'TransactionConfirmedWithExtra',
  ConfirmedWithManualTransfer = 'ConfirmedWithManualTransfer',

  TransferFundsInsufficient = 'TransferFundsInsufficient'
}
