import React, { useMemo } from 'react'
import { useSetPrompt } from '../../../utils/useSetPrompt'
import { BackofficeMicrofrontend } from '../../../utils/BackofficeMicrofrontend'
import { getTranslationForKey } from '../../../i18n/getTranslationForKey'
import { GlobalSnackbarService } from '../../../utils/GlobalSnackbarService'

type UpdatePolicyContainerProps = {
  policyId: string
  customerId: string
  onClose: (actionType?: string | undefined) => void
  policyContainsAccidentLine: boolean
  isDraft: boolean
  testData: boolean
}

export const UpdatePolicyMicroFrontend = ({
  policyId,
  customerId,
  onClose,
  policyContainsAccidentLine,
  isDraft,
  testData
}: UpdatePolicyContainerProps) => {
  const setPrompt = useSetPrompt()

  const customProps = useMemo(
    () => ({
      customerId,
      policyId,
      policyContainsAccidentLine,
      onClose,
      setPrompt,
      isDraft,
      testData
    }),
    [
      customerId,
      policyId,
      policyContainsAccidentLine,
      onClose,
      setPrompt,
      isDraft,
      testData
    ]
  )

  return (
    <BackofficeMicrofrontend
      name="policy-mta"
      prodUrl="https://bo-policy-mta.element.in"
      localUrl="http://localhost:5037"
      bundlerTool="Vite"
      additionalCustomProps={customProps}
    />
  )
}

const UpdatePolicyContainer = props => {
  const {
    match: {
      params: { customerId, policyId }
    },
    history,
    location: { search }
  } = props
  const urlParams = new URLSearchParams(search)
  return (
    <UpdatePolicyMicroFrontend
      customerId={customerId}
      policyId={policyId}
      policyContainsAccidentLine={
        urlParams.get('policyContainsAccidentLine') === 'true'
      }
      isDraft={urlParams.get('isDraft') === 'true'}
      onClose={type => {
        history.push(`/customers/${customerId}`)

        if (type) {
          GlobalSnackbarService.emit(
            getTranslationForKey(`POLICY.${type}`),
            'success'
          )
        }
      }}
      testData={urlParams.get('testData') === 'true'}
    />
  )
}

export default UpdatePolicyContainer
