import React, { useState } from 'react'
import { Switch, Tooltip } from '@material-ui/core'
import { useConfirmation } from '../../components/ConfirmationDialogContextProvider'
import DocumentsService from '../../services/DocumentsService'
import { GlobalSnackbarService } from '../GlobalSnackbarService'

type DocumentVisibilityToggleProps = {
  visibility: boolean
  customerId: string
  documentId: string
}
export function DocumentVisibilityToggle({
  visibility,
  customerId,
  documentId
}: DocumentVisibilityToggleProps) {
  const [visibleForCustomer, setVisibleForCustomer] =
    useState<boolean>(visibility)
  const confirmDialog = useConfirmation()

  if (!customerId || !documentId) {
    return null
  }

  const updateVisibility = (value: boolean) => {
    DocumentsService.updateVisibilityForCustomer(customerId, documentId, value)
      .then(() =>
        GlobalSnackbarService.emit(
          'Die Sichtbarkeit im Kundenportal wurde geändert',
          'success'
        )
      )
      .catch(e => GlobalSnackbarService.emit(e.message, 'error'))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibleForCustomer(event.target.checked)
    confirmDialog({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Änderung',
      description:
        'Bist du sicher, dass du die Sichtbarkeit im Kundenportal ändern möchtest?',
      primaryButtonText: 'Abbrechen',
      secondaryButtonText: 'Bearbeitung Bestätigen'
    })
      .then(() => {
        updateVisibility(event.target.checked)
      })
      .catch(() => setVisibleForCustomer(!event.target.checked))
  }

  return (
    <Tooltip
      title={
        visibleForCustomer
          ? 'Derzeit für Kunden sichtbar. Klicke hier, um es zu ändern.'
          : 'Derzeit für Kunden nicht sichtbar. Klicke hier, um es zu ändern.'
      }>
      <Switch
        size="small"
        checked={visibleForCustomer}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </Tooltip>
  )
}
