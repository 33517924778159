import { BackofficeAPI } from '@elementinsurance/cognito-auth'

export const partnerService = new (class PartnerService {
  create(data) {
    return BackofficeAPI.post('/partners', data)
  }

  disable(id) {
    return BackofficeAPI.post(`/partners/${id}/disable`)
  }

  enable(id) {
    return BackofficeAPI.post(`/partners/${id}/enable`)
  }

  promote(id) {
    return BackofficeAPI.post(`/partners/${id}/promote`)
  }

  loadAll() {
    return BackofficeAPI.get('/partners')
  }

  loadAllWithStatus(status) {
    return BackofficeAPI.get(`/partners?status=${status}`)
  }

  load(id) {
    return BackofficeAPI.get(`/partners/${id}`)
  }

  update(id, data) {
    return BackofficeAPI.put(`/partners/${id}`, data)
  }

  webhooks = {
    create: (id, data) => BackofficeAPI.post(`/partners/${id}/webhooks`, data),
    generateSharedSecret: (id, webhookId) =>
      BackofficeAPI.post(
        `/partners/${id}/webhooks/${webhookId}/generate-shared-secret`
      ),
    delete: (id, webhookId) =>
      BackofficeAPI.delete(`/partners/${id}/webhooks/${webhookId}`),
    update: (partnerId, webhookId, request) =>
      BackofficeAPI.put(
        `/partners/${partnerId}/webhooks/${webhookId}`,
        request
      ),
    loadAllfromPartner: id => BackofficeAPI.get(`/partners/${id}/webhooks`),
    get: async (partnerId, id) => {
      const webhooks = await this.webhooks.loadAllfromPartner(partnerId)
      return webhooks.find(webhook => webhook.id === id)
    },
    getAll: () => BackofficeAPI.get('/partners/webhooks')
  }
})()
