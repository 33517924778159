import * as React from 'react'
import payPalIcon from './paypal.svg'

export default function PayPalSvg() {
  return (
    <img
      src={payPalIcon}
      alt={'payPal'}
      style={{ verticalAlign: 'baseline' }}
    />
  )
}
