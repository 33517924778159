import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import { Author } from './Author'
import { formattedGermanDateTime } from '../Dates.ts'
import { DocumentVisibilityToggle } from './DocumentVisibilityToggle'
import { documentUrl } from '../../customers/customer-details/DocumentUrl'

const zeroWhiteSpaceStyle = {
  margin: 0,
  padding: 0
}

const documentLinkStyle = {
  whiteSpace: 'nowrap',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

export const EventDocumentList = ({ attachments, customerId }) => {
  return (
    <div style={{ marginTop: 12, marginLeft: 0, marginRight: 5 }}>
      {attachments && attachments.length > 0 && (
        <Typography variant="caption" gutterBottom>
          Dateien
        </Typography>
      )}

      <List dense={true} style={zeroWhiteSpaceStyle}>
        {attachments.map(attachment => (
          <ListItem key={attachment.id} style={zeroWhiteSpaceStyle}>
            <ListItemText
              primary={
                <a
                  style={documentLinkStyle}
                  title={attachment.filename}
                  href={documentUrl(attachment)}
                  target="_blank"
                  rel="noopener noreferrer">
                  {attachment.filename || 'Datei'}
                </a>
              }
              secondary={
                <small style={{ color: '#555' }}>
                  {formattedGermanDateTime(attachment.createdAt)} -{' '}
                  <AttachmentAuthor attachment={attachment} />
                  <DocumentVisibilityToggle
                    visibility={attachment.visibleForCustomer}
                    customerId={customerId}
                    documentId={attachment.id}
                  />
                </small>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

function AttachmentAuthor({ attachment }) {
  return attachment.createdBy ? (
    <Author
      identifier={attachment.createdBy}
      isCustomer={attachment.createdBy === attachment.customerId}
    />
  ) : (
    'Unbekannt'
  )
}
