import React from 'react'

export default function ErrorMessage({ error }) {
  if (error && error.message) {
    return (
      <div data-debugid="error" style={{ color: 'red' }}>
        {error.message}
      </div>
    )
  } else {
    return null
  }
}
