import React from 'react'
import { Field, Form } from 'react-final-form'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'

import ProgressVisualizer from '../ProgressVisualizer'
import ErrorMessage from '../ErrorMessage'
import Attachments from '../Attachments'
import * as Validators from '../Validators'
import { WhenDefined } from '../FieldRules'

export class AttachmentsForm extends React.Component {
  static propTypes = {
    onAttachmentsUpload: PropTypes.func,
    event: PropTypes.object
  }

  state = {
    submitting: false,
    error: null
  }

  onSubmit = formData => {
    this.setState({ submitting: true })

    this.props
      .onAttachmentsUpload(
        this.props.event,
        formData.attachments,
        formData.visibleForCustomer
      )
      .then(() => {
        this.setState({ error: null })
        this.setState({ submitting: false })
      })
      .catch(e => {
        this.setState({ error: e })
        this.setState({ submitting: false })
      })
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{ visibleForCustomer: false }}
        subscription={{ invalid: true }}
        render={({ handleSubmit, invalid }) => (
          <form>
            <Field
              name="attachments"
              component={Attachments}
              validate={Validators.required}
            />

            <WhenDefined field={'attachments'}>
              <Field name="visibleForCustomer" type="checkbox">
                {({ input, meta }) => (
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox onChange={input.onChange} />}
                        label="Veröffentlichen"
                      />
                      {input.value && (
                        <FormHelperText>
                          Die Dateien sind für den Kunden über das Portal
                          abrufbar und werden ggfs. über BiPRO an Makler
                          versendet
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </FormControl>
                )}
              </Field>
              {!this.state.submitting && (
                <SubmitButton handleSubmit={handleSubmit} disabled={invalid} />
              )}
            </WhenDefined>

            {this.state.submitting && <ProgressVisualizer />}

            {this.state.error && (
              <div style={{ marginTop: 10 }}>
                <ErrorMessage error={this.state.error} />
              </div>
            )}
          </form>
        )}
      />
    )
  }
}

export const SubmitButton = ({ handleSubmit, disabled }) => (
  <Button
    variant="contained"
    color="secondary"
    data-testid="attachments-form-submit-button"
    disabled={disabled}
    onClick={handleSubmit}
    fullWidth
    style={{ marginTop: 8 }}
    size="small">
    Hochladen
  </Button>
)
