import React from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

export function WhenFieldChanges({ field, becomes, set, to }) {
  return (
    <Field name={set} subscription={{}}>
      {(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <OnChange name={field}>
          {value => {
            if (value === becomes) {
              onChange(to)
            }
          }}
        </OnChange>
      )}
    </Field>
  )
}

export function WhenDefined({ field, children }) {
  return (
    <Field
      name={field}
      subscribe={{ value: true }}
      render={({ input }) => (input.value ? children : null)}
    />
  )
}

export function WhenUndefined({ field, children }) {
  return (
    <Field
      name={field}
      subscribe={{ value: true }}
      render={({ input }) => (!input.value ? children : null)}
    />
  )
}
