import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { FormControlLabel } from '@material-ui/core'

export const MARKETING_CONSENT_OPTIONS = [
  {
    label: 'Phone',
    value: 'PHONE'
  },
  {
    label: 'Email',
    value: 'EMAIL'
  }
]
const MARKETING_CONSENT_KEY = 'marketingConsents'

const MarketingConsentFields = ({ formInstance }) => {
  const currentConsentOptions =
    formInstance.getState()?.values?.[MARKETING_CONSENT_KEY] || []

  const [checkedOptions, updatecheckedOptions] = useState(currentConsentOptions)

  const onChange = (isChecked, value) => {
    let modifiedOptions = checkedOptions
    modifiedOptions = isChecked
      ? [...modifiedOptions, value]
      : checkedOptions.filter(option => option !== value)
    formInstance.change(MARKETING_CONSENT_KEY, modifiedOptions)
    updatecheckedOptions(modifiedOptions)
  }

  return (
    <>
      {MARKETING_CONSENT_OPTIONS.map(({ label, value }) => {
        return (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                onChange={({ target: { checked } }) => onChange(checked, value)}
                checked={checkedOptions.includes(value)}
              />
            }
            label={label}
          />
        )
      })}
    </>
  )
}

export default MarketingConsentFields
