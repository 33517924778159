import {
  BackofficeAPI,
  SandboxBackofficeAPI
} from '@elementinsurance/cognito-auth'

import { errorHandler, notFoundErrorHandler } from '../utils/RestErrorHandler'
import { ScheduleStatuses } from '../customers/customer-details/policy-details/payment-details/ScheduledPayments/types'

export class PaymentsService {
  static createUrlForPaymentMethodChange(
    policyId: string,
    isTest: boolean
  ): Promise<string> {
    const url = `/payments/policies/${policyId}/sign`
    const promise = isTest
      ? SandboxBackofficeAPI.post(url, null)
      : BackofficeAPI.post(url, null)

    return promise.then(data => data.signedUrl)
  }

  static loadLatestPaymentMethodByPolicyId(policyId) {
    return BackofficeAPI.get(`/payments/policies/${policyId}/method`).catch(
      errorHandler({ 404: 'Zahlungsmethode nicht gefunden' })
    )
  }

  static loadProductPaymentManagementType(productId) {
    return BackofficeAPI.get(`/payments/products/${productId}/configurations`)
      .then(e => e.paymentManagementType)
      .catch(notFoundErrorHandler())
  }

  static loadPaymentsHistory(policyId) {
    return BackofficeAPI.get(`/payments/policies/${policyId}/history`).catch(
      notFoundErrorHandler({ 404: null })
    )
  }

  // @docs https://payments-scheduling-service.private.element.in/swagger-ui/index.html
  static loadScheduledPayments(policyId, status = ScheduleStatuses.READY) {
    const promise = BackofficeAPI.get(
      `/scheduled-payments?policyId=${policyId}&status=${status}`
    )
    return promise.catch(errorHandler())
  }

  // @docs https://payments-scheduling-service.private.element.in/swagger-ui/index.html
  static setScheduledPayments(paymentDetails) {
    const url = '/scheduled-payments'

    const promise = BackofficeAPI.put(url, paymentDetails)
    return promise.catch(errorHandler())
  }

  static setPolicyRefundDetails(policyId, refundDetails, isTest) {
    const url = `/payments/policies/${policyId}/refund-details`
    const promise = isTest
      ? SandboxBackofficeAPI.put(url, refundDetails)
      : BackofficeAPI.put(url, refundDetails)

    return promise.catch(errorHandler())
  }

  static async loadPaymentTransfer(transferId) {
    return BackofficeAPI.get(`/payments/transfers/${transferId}`).catch(
      errorHandler()
    )
  }

  static async loadTransactionInDunning(policyId, transactionId) {
    return BackofficeAPI.get(
      `/payments/dunning/policies/${policyId}/transactions/${transactionId}`
    ).catch(errorHandler())
  }
}
