import { isValidIBAN } from 'ibantools'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { DateTime } from 'luxon'
import { valueIsString, removeBlankSpace } from '../stringUtils'

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/ // NOSONAR
const HEX_COLOR_REGEXP = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/
const NO_SPACES_REGEXP = /^\S*$/

export function email(value) {
  if (_isEmptyInputValue(value)) {
    return null // don't validate empty values to allow optional fields
  }
  return EMAIL_REGEXP.test(value) ? undefined : 'Muss eine E-Mail Adresse sein'
}

export function color(value) {
  if (_isEmptyInputValue(value)) {
    return null // don't validate empty values to allow optional fields
  }
  return HEX_COLOR_REGEXP.test(value)
    ? undefined
    : 'Falsches Farbformat, bitte verwenden das Hex-Farbformat (z.B. #ff0000)'
}

export function required(value) {
  if (
    value == null ||
    (valueIsString(value) && value.trim() === '') ||
    (Array.isArray(value) && !value.length)
  ) {
    return 'Pflichtfeld'
  }

  return undefined
}

export function path(value) {
  return NO_SPACES_REGEXP.test(value) ? undefined : 'Keine Leerzeichen'
}

export function maxLength(expectedLength) {
  return function (value) {
    if (!value) {
      return undefined
    }

    return value.length <= expectedLength
      ? undefined
      : `Darf maximal ${expectedLength} Zeichen lang sein`
  }
}

export function notIn(list) {
  return function (value) {
    if (!value) {
      return undefined
    }

    return list.indexOf(value.trim()) === -1
      ? undefined
      : `Wert ${value} wird bereits verwendet`
  }
}

export function compose(...validators) {
  return function (value, allValues) {
    return validators.reduce(
      (error, validator) => error || validator(value, allValues),
      undefined
    )
  }
}

function _isEmptyInputValue(value) {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0
}

export function iban(value) {
  if (!value) {
    return undefined
  }

  const iban = removeBlankSpace(value)

  return isValidIBAN(iban) ? undefined : 'IBAN nicht gültig'
}

export function phone(phoneNumber) {
  if (!phoneNumber) {
    return undefined
  }
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'DE')
  return parsedPhoneNumber && parsedPhoneNumber.isValid()
    ? undefined
    : 'Ungültige Telefonnummer, Format: z.B. +(49)XXXXXXXXXX'
}

export function phoneInternational(phoneNumber) {
  if (!phoneNumber) {
    return undefined
  }
  // eslint-disable-next-line
  const phoneRegExp = new RegExp(/^[+][0-9]{8,16}$/) // starting with +, only digits, from 8 to 16 length
  return phoneRegExp.test(phoneNumber)
    ? undefined
    : 'Ungültige Telefonnummer, Format: z.B. +(49)XXXXXXXXXX'
}

export function isUTCISOString(dateString) {
  return DateTime.fromISO(dateString).isValid
    ? undefined
    : 'Unvollständiges Datumsformat'
}

export function validUrl(url) {
  if (!url) {
    return undefined
  }
  try {
    new URL(url)
    return undefined
  } catch (e) {
    return 'Ungültige URL'
  }
}

export function securedUrl(url) {
  if (!url) {
    return undefined
  }
  return url.startsWith('https:') ? undefined : 'URL muss mit https beginnen'
}
