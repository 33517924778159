import * as React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FilterIcon from '@material-ui/icons/FilterList'
import Typography from '@material-ui/core/Typography'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing()
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  }
})

const SortableTableToolbarComponent = props => {
  const {
    actions,
    isFilterable,
    onFilterOpen,
    onSearch,
    numSelected,
    classes,
    selected,
    selectedActions,
    title
  } = props

  const showSelectionBar = numSelected > 0 && selectedActions

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: showSelectionBar
      })}>
      <div className={classes.title}>
        {showSelectionBar ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {title}{' '}
              {numSelected > 0 && (
                <span style={{ fontSize: '0.65em' }}>
                  &nbsp;({numSelected} selected)
                </span>
              )}
            </div>
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {showSelectionBar ? (
          selectedActions({ selected })
        ) : (
          <React.Fragment>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={event => onSearch(event.target.value)}
            />
            {isFilterable && (
              <Tooltip title="Filter">
                <IconButton
                  aria-label="Filter"
                  color="primary"
                  onClick={onFilterOpen}>
                  <FilterIcon />
                </IconButton>
              </Tooltip>
            )}
            {actions}
          </React.Fragment>
        )}
      </div>
    </Toolbar>
  )
}

SortableTableToolbarComponent.propTypes = {
  actions: PropTypes.node,
  classes: PropTypes.object.isRequired,
  isFilterable: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  onFilterOpen: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  selectedActions: PropTypes.func,
  title: PropTypes.node.isRequired,
  onSearch: PropTypes.func.isRequired
}

SortableTableToolbarComponent.defaultProps = {
  actions: null,
  selectedActions: null
}

export const SortableTableToolbar = withStyles(toolbarStyles)(
  SortableTableToolbarComponent
)
