import { CognitoUser } from '@elementinsurance/cognito-auth/dist/types/CognitoUser'
import React from 'react'
import { AuthorizationContext } from '../auth/AuthorizationFilter'

type GetAuthToken = () => Promise<string>

export function useGetAuthToken(): GetAuthToken | null {
  const auth = React.useContext(AuthorizationContext)
  const cognitoUser = auth?.authData

  return React.useMemo(() => {
    if (!cognitoUser) {
      return null
    }

    const getAuthToken = async () => {
      try {
        const session = await getSession(cognitoUser)
        return session.getIdToken().getJwtToken()
      } catch (e: any) {
        if (e.message !== 'Refresh Token has expired') {
          console.error('Getting auth token failed', e)
        }

        redirectToLogin()
        return 'N/A'
      }
    }

    return getAuthToken
  }, [cognitoUser])
}

function getSession(cognitoUser: CognitoUser) {
  return new Promise<any>((resolve, reject) => {
    cognitoUser.getSession((error, session) => {
      if (session) {
        resolve(session)
      } else {
        reject(error)
      }
    })
  })
}

function redirectToLogin() {
  window.location.replace('/')
}
