import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'

export function RightToolbarDialog({ onClose, open, children, title }) {
  return (
    <Dialog
      open={open}
      fullScreen
      aria-labelledby="form-dialog-title"
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') {
          onClose()
        }
      }}
      style={{ width: '50%', marginLeft: '50%' }}>
      <MuiDialogTitle disableTypography>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          name="closeButton"
          style={{
            position: 'absolute',
            right: 10,
            top: 10
          }}
          onClick={onClose}
          aria-label="Close">
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
