import { IconButton } from '@material-ui/core'
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox'
import React from 'react'

export function RemoveButton({ onClick }) {
  return (
    <IconButton size="small" aria-label="Delete" onClick={onClick}>
      <IndeterminateCheckBox color="primary" />
    </IconButton>
  )
}
