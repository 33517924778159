import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { TimelineEvent } from 'react-event-timeline'

import { AttachmentsForm } from './AttachmentsForm'
import { Author } from './Author'
import { EventAttachmentCountBadge } from './EventAttachmentCountBadge'
import { EventDocumentList } from './EventDocumentList'
import { formattedGermanDate, formattedGermanDateTime } from '../Dates.ts'
import { RightToolbarDialog } from '../../components/RightToolbarDialog'
import { Tooltip } from '@material-ui/core'

const cursorPointerStyle = { cursor: 'pointer', fontWeight: 'normal' }
const eventMinWidth = '250px'
const pinkColorHex = '#f50057'

export const OpenCollapsibleTimelineEvent = ({
  event,
  label,
  icon,
  iconColor,
  children,
  onClose,
  attachments,
  onAttachmentsUpload,
  hideAttachmentUpload,
  isFutureEvent,
  hideAttachments,
  displayEffectiveDate = true,
  labelStyle,
  bubbleStyle = { zIndex: 1, color: 'teal' },
  openIconColor = 'teal',
  ...noneEventProps
}) => (
  <TimelineEvent
    createdAt={
      <div data-testid="open-collapsible-timeline-event">
        <DateAndAuthor event={event} />
        <ShowRawDataDialogButton event={event} />
        <CollapsibleTimelineEventCloseButton onClose={onClose} />
      </div>
    }
    title={
      <div style={{ ...labelStyle, ...cursorPointerStyle }} onClick={onClose}>
        {label}
      </div>
    }
    subtitle={null}
    icon={
      <Icon onClick={onClose} style={cursorPointerStyle}>
        {icon}
      </Icon>
    }
    iconColor={isFutureEvent ? pinkColorHex : openIconColor}
    style={{
      backgroundColor: 'white',
      boxShadow: '0 0 3px 1px rgba(0,0,0,0.25)',
      left: -50,
      paddingLeft: 50,
      top: -10,
      paddingTop: 10,
      bottom: -10,
      paddingBottom: 10,
      borderRadius: 6,
      marginRight: -20,
      minWidth: eventMinWidth,
      marginTop: 20
    }}
    bubbleStyle={
      isFutureEvent ? { zIndex: 1, color: pinkColorHex } : bubbleStyle
    }
    {...noneEventProps}>
    <div
      style={{
        marginTop: 12,
        marginLeft: -40,
        marginRight: 5,
        fontWeight: 'normal'
      }}>
      {displayEffectiveDate && (
        <EffectiveDate
          date={event.effectiveDate}
          isFutureEvent={isFutureEvent}
        />
      )}

      {children}

      {!hideAttachmentUpload && <Divider />}

      {!hideAttachments && attachments?.length > 0 && (
        <EventDocumentList
          attachments={attachments}
          customerId={event.customerId}
        />
      )}

      {!attachments && !hideAttachmentUpload && (
        <AttachmentsForm
          event={event}
          onAttachmentsUpload={onAttachmentsUpload}
        />
      )}
    </div>
  </TimelineEvent>
)

export const ClosedCollapsibleTimelineEvent = ({
  event,
  label,
  icon,
  iconColor,
  children,
  onOpen,
  attachments,
  isFutureEvent,
  hideAttachments,
  bubbleStyle,
  labelStyle,
  className,
  ...noneEventProps
}) => (
  <TimelineEvent
    className={className}
    createdAt={
      <div data-testid={noneEventProps['data-testid']}>
        <DateAndAuthor event={event} />
        <CollapsibleTimelineEventOpenButton onOpen={onOpen} />
      </div>
    }
    title={
      <div style={cursorPointerStyle} onClick={onOpen}>
        <div style={{ ...labelStyle, fontSize: 16 }}>{label}</div>
        {!hideAttachments && attachments?.length > 0 && (
          <div>
            <EventAttachmentCountBadge attachments={attachments} />
          </div>
        )}
      </div>
    }
    subtitle={''}
    icon={
      <Icon onClick={onOpen} style={cursorPointerStyle}>
        {icon}
      </Icon>
    }
    iconColor={iconColor}
    style={{
      minWidth: eventMinWidth,
      marginTop: 20
    }}
    bubbleStyle={
      isFutureEvent
        ? { backgroundColor: pinkColorHex, color: 'white' }
        : bubbleStyle
    }
  />
)

export const SubTimeLineEvent = ({
  event,
  label,
  icon,
  iconColor,
  children,
  onClose,
  isFutureEvent,
  hideAttachments,
  displayEffectiveDate = true,
  labelStyle,
  bubbleStyle,
  className,
  ...noneEventProps
}) => {
  return (
    <TimelineEvent
      className={className}
      createdAt={
        <div>
          <DateAndAuthor event={event} />
        </div>
      }
      title={
        <div style={cursorPointerStyle}>
          <div style={{ ...labelStyle, fontSize: 13 }}>{label}</div>
        </div>
      }
      icon={<Icon style={cursorPointerStyle}>{icon}</Icon>}
      style={{
        minWidth: eventMinWidth,
        marginTop: 20
      }}
      bubbleStyle={
        isFutureEvent
          ? { backgroundColor: pinkColorHex, color: 'white' }
          : bubbleStyle
      }
      {...noneEventProps}>
      {children}
    </TimelineEvent>
  )
}
const OpenSubTimeLineEvent = ({
  event,
  label,
  icon,
  iconColor,
  children,
  onClose,
  attachments,
  onAttachmentsUpload,
  hideAttachmentUpload,
  isFutureEvent,
  hideAttachments,
  displayEffectiveDate = true,
  labelStyle,
  bubbleStyle = { zIndex: 1, color: 'teal' },
  openIconColor = 'teal',
  ...noneEventProps
}) => {
  return (
    <TimelineEvent
      createdAt={
        <div>
          <CollapsibleTimelineEventCloseButton onClose={onClose} />
        </div>
      }
      title={null}
      subtitle={null}
      style={{
        backgroundColor: 'white',
        boxShadow: '0 0 3px 1px rgba(0,0,0,0.25)',
        left: -50,
        paddingLeft: 50,
        top: -10,
        bottom: -10,
        paddingBottom: 10,
        borderRadius: 6,
        marginRight: -20,
        minWidth: eventMinWidth,
        marginTop: 20
      }}
      {...noneEventProps}>
      <div
        style={{
          marginTop: 12,
          marginLeft: -40,
          marginRight: 5,
          fontWeight: 'normal'
        }}>
        {displayEffectiveDate && (
          <EffectiveDate
            date={event.effectiveDate}
            isFutureEvent={isFutureEvent}
          />
        )}

        {children}
      </div>
    </TimelineEvent>
  )
}

export function CollapsibleTimelineEvent({
  isExpanded = false,
  isSubTimeLine = false,
  onEventOpened,
  onEventClosed,
  event,
  openBubbleStyle,
  closedBubbleStyle,
  ...rest
}) {
  const [open, setOpen] = useState(isExpanded)

  function handleOpen() {
    if (onEventOpened) {
      onEventOpened(event)
    }
    setOpen(true)
  }

  function handleClose() {
    if (onEventClosed) {
      onEventClosed(event)
    }
    setOpen(false)
  }

  const OpenTimeLineEventComp = isSubTimeLine
    ? OpenSubTimeLineEvent
    : OpenCollapsibleTimelineEvent

  return open ? (
    <OpenTimeLineEventComp
      event={event}
      onClose={handleClose}
      bubbleStyle={openBubbleStyle}
      {...rest}
    />
  ) : (
    <ClosedCollapsibleTimelineEvent
      event={event}
      onOpen={handleOpen}
      bubbleStyle={closedBubbleStyle}
      {...rest}
    />
  )
}

function DateAndAuthor({ event }) {
  return (
    <React.Fragment>
      <div>{formattedGermanDateTime(event.createdAt)}</div>
      <div>
        {
          <Author
            identifier={event.createdBy}
            isCustomer={event.createdBy === event.customerId}
          />
        }
      </div>
    </React.Fragment>
  )
}

export function CollapsibleTimelineEventOpenButton({ onOpen }) {
  return (
    <IconButton
      data-testid="collapsible-timeline-event-open-button"
      style={{ position: 'absolute', right: -3, top: -15 }}
      onClick={onOpen}>
      <Icon>expand_more</Icon>
    </IconButton>
  )
}

export function CollapsibleTimelineEventCloseButton({ onClose }) {
  return (
    <IconButton
      data-testid="collapsible-timeline-event-close-button"
      style={{ position: 'absolute', right: -5, top: -5 }}
      onClick={onClose}>
      <Icon>expand_less</Icon>
    </IconButton>
  )
}

function EffectiveDate({ date, isFutureEvent }) {
  return (
    <div style={{ marginBottom: 16 }}>
      Wirksam zum:{' '}
      <span style={isFutureEvent ? { color: pinkColorHex } : {}}>
        {formattedGermanDate(date)}
      </span>
    </div>
  )
}

function ShowRawDataDialogButton({ event }) {
  const [open, setOpen] = useState(false)
  return (
    <div style={{ position: 'absolute', right: 40, top: 5 }}>
      <Tooltip title="Rohdaten anzeigen">
        <IconButton size="small" onClick={() => setOpen(true)}>
          <Icon>search</Icon>
        </IconButton>
      </Tooltip>

      <RightToolbarDialog
        open={open}
        onClose={() => setOpen(false)}
        title={'Ereignis-Rohdaten'}>
        <pre>{JSON.stringify(event, null, '   ')}</pre>
      </RightToolbarDialog>
    </div>
  )
}
