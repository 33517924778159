import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

import FullscreenDialog from '../../../../utils/FullscreenDialog'
import { PaymentsService } from '../../../../services/PaymentsService'
import { GlobalSnackbarService } from '../../../../utils/GlobalSnackbarService'

interface Props {
  isTest: boolean
  policy: any
  onClose: (success?: boolean) => void
}

export const UpdatePaymentDetailsDialog: React.FC<Props> = ({
  isTest,
  policy,
  onClose
}) => {
  React.useEffect(() => {
    const handleEvents = (event: any) => {
      switch (event.type) {
        case 'ELEMENTINSURANCE:SUCCESS': {
          GlobalSnackbarService.emit(
            'Zahlungsdaten erfolgreich geändert.',
            'success'
          )
          onClose(true)
          return
        }

        default: {
          return
        }
      }
    }

    PaymentsService.createUrlForPaymentMethodChange(policy.id, isTest).then(
      signedUrl => {
        ;(window as any).ElementInsurance({
          containerId: 'payment-iframe',
          audience: 'B2B',
          signedUrl,
          onEvent: handleEvents,
          environment: isTest ? 'TEST' : 'PRODUCTION',
          theme: {
            primaryButton: {
              backgroundColor: '#1890FF',
              borderColor: '#1890FF',
              color: '#FFFFFF',
              hoverBackgroundColor: '#40A9FF',
              hoverBorderColor: '#40A9FF',
              hoverColor: '#FFFFFF'
            },
            collapsible: {
              borderColor: '#000'
            },
            collapsibleHeadline: {
              borderColor: '#fff'
            }
          }
        })
      }
    )
  }, [policy, isTest, onClose])

  return (
    <FullscreenDialog
      open={true}
      title={
        <>
          {`Zahlungsdaten ändern (Police: ${policy.id})`}
          <IconButton
            name="closeButton"
            style={{
              position: 'absolute',
              right: 10,
              top: 10
            }}
            onClick={() => onClose()}
            aria-label="Close">
            <CloseIcon />
          </IconButton>
        </>
      }
      content={<div id="payment-iframe" />}
      controls={
        <>
          <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
            Abbrechen
          </Button>
        </>
      }
    />
  )
}
