import * as React from 'react'
import Typography from '@material-ui/core/Typography'

export function Headline({ children, ...props }) {
  return (
    <Typography variant="h5" component="h3" {...props}>
      {children}
    </Typography>
  )
}
