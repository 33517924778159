import React, { Fragment } from 'react'
import styled from 'styled-components'
import { formatDate } from '../../../../utils/Formatters'
import { getTranslationForKey } from '../../../../i18n/getTranslationForKey'

const collapsedPaymentsCount = 5

export default function Payments({ payments, onToggleCollapsed, collapsed }) {
  const paymentsToRender = collapsed
    ? payments.slice(0, collapsedPaymentsCount)
    : payments
  return (
    <Fragment>
      <PaymentsList payments={paymentsToRender} />

      {payments.length > collapsedPaymentsCount && (
        <MoreButton
          data-testid="payments-more-button"
          href="#"
          onClick={onToggleCollapsed}>
          {collapsed
            ? getTranslationForKey('PAYMENTS.SHOW_MORE')
            : getTranslationForKey('PAYMENTS.SHOW_LESS')}
        </MoreButton>
      )}
    </Fragment>
  )
}

export function PaymentsList({ payments }) {
  return (
    <div>
      {payments.map((payment, i) => (
        <Payment key={i} payment={payment} />
      ))}

      {payments.length === 0 && <NoPaymentsPlaceholder />}
    </div>
  )
}

function Payment({ payment }) {
  const { dueDate, status } = payment
  const date = formatDate(dueDate)

  const knowStatus = ['UPCOMING', 'REVERSED', 'DUNNING', 'PAID', 'OUTSTANDING']
  let statusText = knowStatus.includes(status)
    ? getTranslationForKey(`PAYMENTS.STATUS.${status}`)
    : status

  return (
    <StyledInfo>
      {date} {statusText}
    </StyledInfo>
  )
}

export const NoPaymentsPlaceholder = () => (
  <StyledInfo data-testid="no-payments-placeholder" hint>
    {getTranslationForKey('PAYMENTS.STATUS.NO_PAYMENTS')}
  </StyledInfo>
)

export const MoreButton = styled.a`
  font-size: 10px;
`

const StyledInfo = styled.div`
  color: ${p => (p.warning ? 'red' : null)};
  font-style: ${p => (p.hint ? 'italic' : null)};
  font-size: 0.85em;
  padding: 0 0 0.65em;
`
