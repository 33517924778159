import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MuiInput from '@material-ui/core/Input'
import { FormattedMessage } from 'react-intl'
import { getErrorMessages } from '@elementinsurance/cognito-auth'

export function Input({ input, label, meta, placeholder, required, ...rest }) {
  const errorText =
    meta.touched && meta.error ? getErrorMessages(meta.error) : ''

  return (
    <FormattedMessage id={placeholder}>
      {msg => (
        <FormControl
          fullWidth={true}
          required={required}
          style={{ marginBottom: 10 }}>
          <InputLabel htmlFor={input.name}>{label}</InputLabel>
          <MuiInput
            placeholder={msg}
            {...rest}
            {...input}
            value={input.value || ''}
          />
          {errorText && (
            <FormHelperText component="div" style={{ color: 'red' }}>
              {errorText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </FormattedMessage>
  )
}
