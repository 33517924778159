import { useEffect } from 'react'

export default function useFeatureFlag(
  name: string,
  defaultValue = false
): boolean {
  const storageKey = 'flags:' + name
  const paramRawValue = new URLSearchParams(window.location.search).get(name)
  const paramValue =
    paramRawValue === 'true' || paramRawValue === 'false'
      ? JSON.parse(paramRawValue)
      : null
  const storedJson = window.localStorage.getItem(storageKey)
  const storedValue = storedJson ? JSON.parse(storedJson) : null
  const flagValue = paramValue ?? storedValue ?? defaultValue

  useEffect(() => {
    if (flagValue === defaultValue) {
      window.localStorage.removeItem(storageKey)
    } else {
      window.localStorage.setItem(storageKey, JSON.stringify(flagValue))
    }
  }, [storageKey, defaultValue, flagValue])

  return flagValue
}
