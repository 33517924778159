import { useEffect, useReducer } from 'react'
import * as Sentry from '@sentry/browser'

export enum ServiceCallStatus {
  NOT_ASKED = 'not_asked',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILURE = 'failure'
}
interface RequestState {
  data: object | null
  status: ServiceCallStatus
  error: string | null
}

enum RequestActionType {
  START = 'start',
  FINISH = 'finish',
  FAIL = 'fail'
}

interface StartRequestAction {
  type: RequestActionType.START
}

interface FinishRequestAction {
  type: RequestActionType.FINISH
  data: object
}

interface FailRequestAction {
  type: RequestActionType.FAIL
  error: string
}

type RequestAction =
  | StartRequestAction
  | FinishRequestAction
  | FailRequestAction

const reducer = (state, action: RequestAction): RequestState => {
  switch (action.type) {
    case RequestActionType.START:
      return { data: null, status: ServiceCallStatus.LOADING, error: null }
    case RequestActionType.FINISH:
      return {
        data: action.data,
        status: ServiceCallStatus.SUCCESS,
        error: null
      }
    case RequestActionType.FAIL:
      return {
        data: null,
        status: ServiceCallStatus.FAILURE,
        error: action.error
      }
    default:
      Sentry.captureException(
        'useService Reducer was called with unknown action type'
      )
      throw new Error('Technical Error')
  }
}

interface ServiceCallResult {
  data: object | null
  error: string | null
  status: ServiceCallStatus
}

function useService(fn): ServiceCallResult {
  const initialState = {
    data: null,
    error: null,
    status: ServiceCallStatus.NOT_ASKED
  }

  const [{ data, status, error }, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    let cancelled = false
    dispatch({ type: RequestActionType.START })

    fn().then(
      data => {
        if (cancelled) return
        dispatch({ type: RequestActionType.FINISH, data })
      },
      err => {
        if (cancelled) return
        Sentry.captureException(err)
        dispatch({ type: RequestActionType.FAIL, error: err })
      }
    )

    return () => {
      cancelled = true
    }
  }, [fn])

  return { data, error, status }
}

export default useService
