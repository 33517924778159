import React, { useState } from 'react'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { CheckCircle, Error, WarningOutlined } from '@material-ui/icons'
import { TimelineEvent } from 'react-event-timeline'

import { Tooltip } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { RightToolbarDialog } from '../../../../components/RightToolbarDialog'
import { PaymentsService } from '../../../../services/PaymentsService'
import { formatDate } from '../../../../utils/Formatters'
import { EventAttachmentCountBadge } from '../../../../utils/timeline/EventAttachmentCountBadge'
import { EventDocumentList } from '../../../../utils/timeline/EventDocumentList'
import { getTransactionDate, mapPaymentMethodIcon } from './PaymentDetailsUtils'

const cursorPointerStyle = { cursor: 'pointer', fontWeight: 'normal' }
const eventMinWidth = '250px'
const openContentStyle = {
  backgroundColor: 'white',
  boxShadow: '0 0 3px 1px rgba(0,0,0,0.25)',
  left: -50,
  paddingLeft: 50,
  top: -10,
  paddingTop: 10,
  bottom: -10,
  paddingBottom: 10,
  borderRadius: 6,
  marginRight: -48,
  minWidth: eventMinWidth,
  marginTop: 20
}

const closeContentStyle = {
  minWidth: eventMinWidth,
  marginTop: 20
}

function Title({ label, onClick, policyId, paymentMethodType, transaction }) {
  const createdTransactionDate = getTransactionDate(transaction)
  const { id, accountingStartDate, accountingEndDate, latestEventType } =
    transaction
  const [dunningStatus, setDunningStatus] = React.useState(undefined)

  React.useEffect(() => {
    const fetchDunningData = async () => {
      const dunningStatus = await PaymentsService.loadTransactionInDunning(
        policyId,
        id
      )
        .then(response => response && response.status)
        .catch(_error => undefined)

      setDunningStatus(dunningStatus)
    }

    fetchDunningData()
  }, [policyId, id])

  return (
    <React.Fragment>
      {accountingStartDate && accountingEndDate && (
        <div style={{ fontWeight: 'bold' }}>
          {formatDate(accountingStartDate)} - {formatDate(accountingEndDate)}
        </div>
      )}
      <div>{`Abbuchungstag: ${createdTransactionDate}`}</div>
      <Typography variant="body1">
        <Grid
          container
          direction="column"
          alignItems="justify"
          onClick={onClick}>
          <Grid container direction="row" alignItems="center" onClick={onClick}>
            <Grid item>
              <Icon
                color={'primary'}
                fontSize={'small'}
                style={{ marginRight: 3 }}>
                {mapPaymentMethodIcon(paymentMethodType)}
              </Icon>
            </Grid>
            <Grid item>
              <span style={{ fontSize: '16px' }}>{label}</span>
            </Grid>
          </Grid>
          {latestEventType === 'PAYMENT_TRANSACTION_REFUND_DATA_REQUIRED' && (
            <Grid item>
              <Tag
                icon={<WarningOutlined />}
                label="Rückerstattungsdetails erforderlich"
              />
            </Grid>
          )}
          {['STARTED', 'PARTIALLY_PAID', 'CLOSED'].includes(dunningStatus) && (
            <Grid item>
              <DunningStatus status={dunningStatus} />
            </Grid>
          )}
        </Grid>
      </Typography>
    </React.Fragment>
  )
}

function CollapsibleTimelineEventButtons({ transaction, onClick, open }) {
  return (
    <React.Fragment>
      {open && <ShowRawDataDialogButton data={transaction} />}
      <IconButton
        style={{ position: 'absolute', right: -3, top: -5 }}
        onClick={onClick}>
        <Icon>{open ? 'expand_less' : 'expand_more'}</Icon>
      </IconButton>
    </React.Fragment>
  )
}

export function ShowRawDataDialogButton({ data }) {
  const [open, setOpen] = useState(false)
  return (
    <div style={{ position: 'absolute', right: 40, top: 5 }}>
      <Tooltip title="Rohdaten anzeigen">
        <IconButton size="small" onClick={() => setOpen(true)}>
          <Icon>search</Icon>
        </IconButton>
      </Tooltip>
      <RightToolbarDialog
        open={open}
        onClose={() => setOpen(false)}
        title={'Ereignis-Rohdaten'}>
        <pre>{JSON.stringify(data, null, '   ')}</pre>
      </RightToolbarDialog>
    </div>
  )
}

function EventContent({ showContent, content, paymentDocuments }) {
  return showContent ? (
    <div
      style={{
        marginTop: 12,
        marginLeft: -40,
        marginRight: 5,
        fontWeight: 'normal'
      }}>
      {content}
      {paymentDocuments.length > 0 && (
        <div>
          <Divider />
          <EventDocumentList attachments={paymentDocuments} />
        </div>
      )}
    </div>
  ) : null
}

function DunningStatus({ status }) {
  if (status === 'CLOSED') {
    return (
      <Tag
        icon={<CheckCircle />}
        label="Mahnwesen geschlossen"
        color="primary"
      />
    )
  }

  return <Tag icon={<Error />} label="Mahnwesen gestartet" color="secondary" />
}

function Tag({ icon, label, color }) {
  return (
    <Chip
      icon={icon}
      color={color}
      size="small"
      variant="outlined"
      label={label}
    />
  )
}

export function PaymentsCollapsibleTimelineEvent({
  policyId,
  paymentMethod,
  paymentDocuments,
  label,
  transaction,
  icon,
  children
}) {
  const [showContent, setShowContent] = useState(false)
  const toggleOpened = () => setShowContent(value => !value)

  return (
    <TimelineEvent
      title={
        <Title
          label={label}
          onClick={toggleOpened}
          policyId={policyId}
          paymentMethodType={paymentMethod.type}
          paymentDocuments={paymentDocuments}
          transaction={transaction}
        />
      }
      contentStyle={{ boxShadow: 'none', padding: 0, margin: 0 }}
      icon={
        <Icon style={cursorPointerStyle} onClick={toggleOpened}>
          {icon.icon}
        </Icon>
      }
      iconColor={icon.color}
      bubbleStyle={{
        zIndex: 1,
        backgroundColor: icon.backgroundColor
      }}
      subtitle={
        <div style={cursorPointerStyle} onClick={toggleOpened}>
          {!showContent && paymentDocuments.length > 0 && (
            <EventAttachmentCountBadge attachments={paymentDocuments} />
          )}
        </div>
      }
      buttons={
        <CollapsibleTimelineEventButtons
          transaction={transaction}
          onClick={toggleOpened}
          open={showContent}
        />
      }
      style={showContent ? openContentStyle : closeContentStyle}>
      <EventContent
        content={children}
        paymentDocuments={paymentDocuments}
        showContent={showContent}
      />
    </TimelineEvent>
  )
}
