import React from 'react'
import Card from '@material-ui/core/Card'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import styled from 'styled-components'
import { withTheme } from '@material-ui/core/styles'

import { documentUrl, shadowFlowDocumentUrl } from '../DocumentUrl'
import PolicyTimeline from './timeline/PolicyTimeline'
import PaymentMethodTimeline from './timeline/PaymentMethodTimeline'
import { PolicyStatusHeader } from './PolicyStatusHeader'
import { AddGenericEventDialog } from '../AddGenericEventDialog'

import { ProductLine } from '../../../product-lines/ProductLine'
import PaymentDetails from './payment-details/PaymentDetails'
import PolicyPremium, { PolicyLinePremium } from './PolicyPremium'
import CustomerContext from '../CustomerContext'
import PolicyContext from './PolicyContext'
import { LabeledContent } from '../LabeledContent'
import ElementNameMappingContext from '../../../global-state/ElementNameMappingContext'
import { Link } from 'react-router-dom'
import * as antd from 'antd'
import { FixedColumnCountGridLayout } from '../../../components/GridLayouts'
import { formatPercentage } from '../../../utils/Formatters'

const policyLinePremiumColumnGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridColumnGap: 40,
  padding: 16
}

export function PolicyDetails(props) {
  const { isReadOnly } = React.useContext(CustomerContext)

  const {
    policy,
    product,
    documents,
    policyEventTypes,
    customer,
    policyDocuments,
    shadowFlowPolicyDocuments,
    policyContainsAccidentLine,
    productAllowsModification,
    isAddEventDialogOpen,
    onAddInsuredPersonToPolicy,
    onRemoveInsuredPersonFromPolicy,
    onRemovePolicyLineFromPolicy,
    onToggleAddEventDialogOpen,
    onHandleAddEventSubmit,
    onUploadAttachmentsToEvent,
    onRemoveInsuredDogFromPolicy,
    onUpdateExternalId
  } = props

  const payments = policy.paymentHistory

  return (
    <Card style={{ width: '100%', marginBottom: 20, marginTop: 20 }}>
      {product && (
        <PolicyStatusHeader
          policy={policy}
          product={product}
          onExternalIdUpdate={onUpdateExternalId}
        />
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '350px auto'
        }}>
        <div style={{ padding: 20 }}>
          {policyEventTypes && (
            <React.Fragment>
              <Fab
                name="addOpsEventDialogButton"
                data-testid="policyDetailsAddOpsEventButton"
                onClick={onToggleAddEventDialogOpen}
                style={{ width: 47, height: 47 }}
                color="secondary"
                disabled={isReadOnly}>
                <Icon>add</Icon>
              </Fab>

              <AddGenericEventDialog
                onSubmit={onHandleAddEventSubmit}
                onCancel={onToggleAddEventDialogOpen}
                isOpen={isAddEventDialogOpen}
                eventTypes={policyEventTypes}
              />
            </React.Fragment>
          )}

          {policy.events && policy.events.length > 0 && (
            <PolicyTimeline
              events={policy.events.slice().reverse()}
              documents={documents}
              eventTypes={policyEventTypes}
              onAttachmentsUpload={onUploadAttachmentsToEvent}
              testData={customer.testData}
              policyContainsAccidentLine={policyContainsAccidentLine}
              hasPendingRenewal={policy.hasPendingRenewal}
            />
          )}

          {payments &&
            payments.paymentMethods &&
            payments.paymentMethods.length > 0 && (
              <div style={{ paddingTop: '2em' }}>
                <h4>Zahlungsmethoden</h4>
                <PaymentMethodTimeline
                  customer={customer}
                  policy={policy}
                  paymentMethods={payments.paymentMethods}
                  documents={documents}
                  testData={customer.testData}
                />
              </div>
            )}
        </div>

        <div style={{ display: 'grid', gridTemplateRows: 'auto auto 1fr' }}>
          <PaymentSection
            policy={policy}
            customer={customer}
            documents={documents}
          />
          <PolicyLineContainerPaper elevation={1} square={true}>
            {/* MTAs for the general policy data are not supported for accident policies atm, remove
                             this condition once these MTAs are supported */}
            {product &&
              (policyContainsAccidentLine || productAllowsModification) && (
                <PolicyMtaButton
                  customerId={customer.id}
                  testData={customer.testData}
                  policy={policy}
                  policyContainsAccidentLine={policyContainsAccidentLine}
                  isReadOnly={isReadOnly}
                />
              )}
            {policy.policyLines.map(policyLine => (
              <PolicyContext.Provider
                value={{ policy }}
                key={policyLine.productLineId}>
                <Paper elevation={1}>
                  <div style={policyLinePremiumColumnGridStyle}>
                    <div>
                      <PolicyLineTitle
                        policy={policy}
                        policyLine={policyLine}
                        product={product}
                      />
                      <CommonAttributes
                        product={product}
                        policy={policy}
                        policyLine={policyLine}
                      />

                      <ProductLine
                        policyLines={policy.policyLines}
                        policyLine={policyLine}
                        product={product}
                        onInsuredPersonAdded={({
                          effectiveDate,
                          ...restFormData
                        }) =>
                          onAddInsuredPersonToPolicy({
                            customerId: policy.customerId,
                            policyId: policy.id,
                            productLineId: policyLine.productLineId,
                            addedInsuredPerson: { ...restFormData },
                            effectiveDate: effectiveDate
                          })
                        }
                        onInsuredPersonRemoved={({
                          removedInsuredPersonId,
                          effectiveDate
                        }) =>
                          onRemoveInsuredPersonFromPolicy({
                            customerId: policy.customerId,
                            policyId: policy.id,
                            productLineId: policyLine.productLineId,
                            removedInsuredPersonId: removedInsuredPersonId,
                            effectiveDate: effectiveDate
                          })
                        }
                        onPolicyLineRemoved={({ effectiveDate }) =>
                          onRemovePolicyLineFromPolicy({
                            customerId: policy.customerId,
                            policyId: policy.id,
                            productLineId: policyLine.productLineId,
                            effectiveDate: effectiveDate
                          })
                        }
                        onInsuredDogRemoved={({
                          removedInsuredDogId,
                          effectiveDate
                        }) =>
                          onRemoveInsuredDogFromPolicy({
                            customerId: policy.customerId,
                            policyId: policy.id,
                            productLineId: policyLine.productLineId,
                            removedInsuredDogId: removedInsuredDogId,
                            effectiveDate: effectiveDate
                          })
                        }
                      />
                    </div>
                  </div>
                </Paper>
              </PolicyContext.Provider>
            ))}
          </PolicyLineContainerPaper>
        </div>
      </div>

      <Accordion>
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <Typography variant="body1">Dokumente</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {policyDocuments.map(d => (
              <ListItem key={d.id}>
                <a
                  href={documentUrl(d)}
                  target="_blank"
                  rel="noopener noreferrer">
                  {d.filename}
                </a>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      {customer.testData && shadowFlowPolicyDocuments.length > 0 && (
        <Accordion>
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <Typography variant="body1">
              Shadow Flow Policy Documents
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {shadowFlowPolicyDocuments.map(d => (
                <ListItem key={d.id}>
                  <a href={shadowFlowDocumentUrl(d)}>{d.filename}</a>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </Card>
  )
}

function PolicyMtaButton({
  customerId,
  testData,
  policy,
  policyContainsAccidentLine
}) {
  const hasDraftEvent = policy.events.some(
    e => e.eventType === 'POLICY_MTA_DRAFT_CREATED_EVENT' && e.active
  )
  const button = (
    <Link
      style={{ marginBottom: 16 }}
      name="openButton"
      type="primary"
      size="large"
      component={antd.Button}
      to={`/customers/${customerId}/policies/${
        policy.id
      }/mta?policyContainsAccidentLine=${JSON.stringify(
        !!policyContainsAccidentLine
      )}&testData=${JSON.stringify(!!testData)}`}
      disabled={
        (policy.state !== 'ACTIVE' && policy.state !== 'PENDING') ||
        hasDraftEvent
      }>
      Vertragsdaten ändern
    </Link>
  )

  return hasDraftEvent ? (
    <antd.Tooltip
      placement="right"
      title="Vertragsdaten können erst geändert werden wenn der Entwurf bestätigt oder abgelehnt wird. ">
      {button}
    </antd.Tooltip>
  ) : (
    button
  )
}

function PaymentSection({ policy, customer, documents }) {
  return (
    <Paper elevation={1} square={true}>
      <div style={policyLinePremiumColumnGridStyle}>
        <PaymentDetails
          policy={policy}
          customer={customer}
          documents={documents}
        />
        <div style={{ textAlign: 'right' }}>
          <p>Preis:</p>
          <PolicyPremium policyLines={policy.policyLines} />
        </div>
      </div>
    </Paper>
  )
}

const policyLineHasExcessCoverage = ({ attributes: { excessCoverage } }) => {
  return (
    excessCoverage && (excessCoverage === 'true' || excessCoverage === true)
  )
}

function PolicyLineTitle({ policyLine, product }) {
  return (
    <Typography variant="h5" gutterBottom>
      <HeaderLine>
        <div>
          {product && (
            <span style={{ fontSize: 'smaller', fontWeight: 'bold' }}>
              {`${
                product.productLines.find(
                  productLine => productLine.name === policyLine.productLineId
                )?.description ?? '-'
              }`}
            </span>
          )}
          <span
            style={{
              fontSize: 'smaller'
            }}>{` (${policyLine.productLineId})`}</span>
        </div>
        <div>
          <span style={{ fontSize: 'smaller' }}>
            {policyLineHasExcessCoverage(policyLine) && (
              <>
                <ExcessCoverageIndicator />
                <div>
                  <small>nach Ablauf Sofortschutz:</small>
                  <PolicyLinePremium
                    payment={policyLine.fullCoverage.payment}
                    noPaymentFrequency
                  />
                </div>
              </>
            )}
          </span>
          <span style={{ fontSize: 'smaller' }}>
            <PolicyLinePremium
              payment={policyLine.payment}
              noPaymentFrequency
            />
          </span>
        </div>
      </HeaderLine>
    </Typography>
  )
}

function CommissionRate({ policy }) {
  const commission = policy.dynamicCommission
    ? `${formatPercentage(policy.dynamicCommission)} (${
        policy.dynamicCommission
      })`
    : 'statisch'

  return <LabeledContent label="Kommission" value={commission} />
}

export const PolicyLineContainerPaper = styled(({ children, ...rest }) => (
  <Paper elevation={2} data-testid="policy-line-container-paper" {...rest}>
    {children}
  </Paper>
))`
  padding: 16px;

  > div {
    margin-bottom: 16px;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`

export const ExcessCoverageIndicator = withTheme(({ theme }) => {
  const style = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 13,
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.common.white,
    position: 'relative',
    top: -2,
    padding: '5px 10px',
    marginLeft: 4,
    whiteSpace: 'noWrap'
  }
  return (
    <span data-testid="excess-coverage-indicator" style={style}>
      Sofortschutz aktiv
    </span>
  )
})

function getProductVariantName(product, elementName, variantKey) {
  return product &&
    product.productVariantMappings &&
    product.productVariantMappings[elementName]
    ? product.productVariantMappings[elementName][variantKey]
    : variantKey
}

function CommonAttributes({ product, policy, policyLine }) {
  const elementNameMapping = React.useContext(ElementNameMappingContext)
  const elementName = elementNameMapping.nameMap[policyLine.productLineId]
  const variant = policyLine.attributes['productVariant']
  return (
    <FixedColumnCountGridLayout columnCount={3}>
      <LabeledContent label="Produktversion" value={policy.productVersion} />
      <LabeledContent
        label="Produktvariante"
        value={getProductVariantName(product, elementName, variant)}
      />
      <CommissionRate policy={policy} />
    </FixedColumnCountGridLayout>
  )
}
const HeaderLine = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 40px;
  margin-bottom: 40px;
`
