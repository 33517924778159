import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSetPrompt } from '../utils/useSetPrompt'
import { BackofficeMicrofrontend } from '../utils/BackofficeMicrofrontend'

const RisksGroup = () => {
  const { pid, id } = useParams()
  const history = useHistory()
  const setPrompt = useSetPrompt()

  const customProps = React.useMemo(
    () => ({
      partnerId: pid,
      isCreationPage: id === 'new',
      flowId: id,
      setPrompt,
      onFlowCreate: ({ id }) => {
        history.push(`./${id}`)
        window.location.reload()
      }
    }),
    [history, setPrompt, id, pid]
  )

  return (
    <BackofficeMicrofrontend
      name="risks-group-config"
      prodUrl="https://bo-risks-group-config.web.app"
      localUrl="http://localhost:3022"
      additionalCustomProps={customProps}
    />
  )
}

export default RisksGroup
