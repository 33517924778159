import { action, runInAction, observable } from 'mobx'

import { customerService } from '../services/CustomerService'
import { partnerService } from '../services/PartnerService'
import DocumentsService from '../services/DocumentsService'
import { sortByField } from '../utils/Sort'

export function createStore() {
  return observable(
    {
      loading: false,
      loadCustomerError: null,
      loadMessageError: null,
      customers: null,
      selectedCustomer: null,
      messages: [],
      documents: [],
      shadowFlowDocuments: [],
      policies: [],
      partner: null,
      partners: [],

      loadPartners: function () {
        return partnerService.loadAll().then(partners => {
          runInAction(() => {
            this.partners = sortByField(partners, p => p.name, {
              ignoreCase: true
            })
          })
        })
      },

      searchCustomer: action(async function (request) {
        try {
          this.loading = true
          this.customers = await customerService.search(request)
        } finally {
          runInAction(() => {
            this.loading = false
          })
        }
      }),

      loadCustomer: action(function (customerId) {
        this.loading = true
        this.loadCustomerError = null
        this.policies = []
        this.selectedCustomer = null

        customerService
          .loadWithEvents(customerId)
          .then(customer => {
            runInAction(() => {
              this.selectedCustomer = customer
            })
            Promise.all([
              customerService.getPoliciesWithEvents(customerId),
              DocumentsService.getDocumentsOfCustomer(customerId),
              customer.testData
                ? DocumentsService.getShadowFlowDocumentsOfCustomer(customerId)
                : [],
              partnerService.load(customer.partnerId)
            ])
              .then(([policies, documents, shadowFlowDocuments, partner]) => {
                runInAction(() => {
                  this.partner = partner
                  this.policies = policies
                  this.documents = documents
                  this.shadowFlowDocuments = shadowFlowDocuments
                  this.loading = false
                })
              })
              .catch(e => {
                runInAction(() => {
                  this.loadCustomerError = e
                  this.loading = false
                })
              })
          })
          .catch(e => {
            runInAction(() => {
              this.loadCustomerError = e
              this.loading = false
            })
          })

        customerService
          .getMessages(customerId)
          .then(messageResponse => {
            runInAction(() => {
              this.messages = messageResponse
            })
          })
          .catch(e => {
            runInAction(() => {
              this.loadMessageError = e
            })
          })
      }),

      reloadCustomer: action(function () {
        this.loadCustomer(this.selectedCustomer.id)
      }),

      addMessage: action(function (message) {
        const messageToSend = {
          text: message.text,
          type: message.type
        }
        return customerService
          .addMessage(this.selectedCustomer.id, message.policyId, messageToSend)
          .then(storedMessage => {
            runInAction(() => {
              this.messages.push(storedMessage)
            })
          })
      }),

      addDocument: action(function (file, httpClientConfig) {
        return customerService
          .addDocument(this.selectedCustomer.id, file, httpClientConfig)
          .then(document => {
            runInAction(() => {
              this.documents.push(document)
            })
          })
      })
    },
    {
      addDocument: action.bound,
      addMessage: action.bound,
      loadCustomer: action.bound,
      loadPartners: action.bound,
      searchCustomer: action.bound
    }
  )
}

const store = createStore()
export default store
