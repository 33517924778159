import React, { useMemo } from 'react'
import { BackofficeMicrofrontend } from '../utils/BackofficeMicrofrontend'

type CustomerSearchProps = {
  onClose: () => void
}

const CustomerSearchContainer = ({ onClose }: CustomerSearchProps) => {
  const customProps = useMemo(
    () => ({
      onClose
    }),
    [onClose]
  )

  return (
    <BackofficeMicrofrontend
      name="customer-search"
      prodUrl="https://bo-customer-search.element.in/"
      localUrl="http://localhost:5055"
      bundlerTool="Vite"
      additionalCustomProps={customProps}
    />
  )
}

export default CustomerSearchContainer
