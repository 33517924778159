import * as React from 'react'
import { TOTPSetup } from '@elementinsurance/cognito-auth'
import { FormattedMessage } from 'react-intl'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  }
})

class MFASetupComponent extends React.Component {
  state = {
    link: null,
    loading: true,
    secret: null,
    done: false
  }

  componentDidMount() {
    this.props
      .onTOTPSetup()
      .then(state => this.setState({ loading: false, ...state }))
  }

  handleVerifyTOTPSetup = data => {
    this.setState({ loading: true })

    return this.props.onVerifyTOTPSetup(data).then(() => {
      this.setState({ loading: false, link: null, secret: null, done: true })
    })
  }

  render() {
    const { classes, history } = this.props
    const { done, loading } = this.state

    const showSetup = !loading && !done

    const Components = Object.assign({}, this.props.Components, {
      Wrapper: MFABox
    })

    return (
      <Paper elevation={2} className={classes.root}>
        <Typography gutterBottom={true} variant="h5">
          <FormattedMessage id="MFA_SETUP" />
        </Typography>

        {showSetup && (
          <TOTPSetup
            {...this.props}
            Components={Components}
            additionalData={this.state}
            onVerifyTOTPSetup={this.handleVerifyTOTPSetup}
          />
        )}

        {done && (
          <React.Fragment>
            <FormattedMessage id="MFA_SETUP_COMPLETED" tagName="p" />
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push('/settings')}>
              <FormattedMessage
                id="BACK_TO"
                values={{
                  target: (
                    <strong>
                      <FormattedMessage id="USER_DETAILS" />
                    </strong>
                  )
                }}
              />
            </Button>
          </React.Fragment>
        )}

        {loading && <LinearProgress />}
      </Paper>
    )
  }
}

export const MFASetup = withStyles(styles)(MFASetupComponent)

function MFABox({ children, loading = false }) {
  return (
    <div style={{ maxWidth: 500 }}>
      {children}
      {loading && <LinearProgress />}
    </div>
  )
}
