import React from 'react'
import { Field } from 'react-final-form'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

export default function CheckboxField({
  name,
  label,
  validate,
  value,
  ...rest
}) {
  return (
    <Field
      defaultValue={rest.defaultValue}
      validate={validate}
      type="checkbox"
      name={name}
      value={value}
      render={({ input, meta }) => {
        const { checked, name, onChange, ...restInput } = input
        const errorText = meta.touched && meta.error ? meta.error : ''

        return (
          <FormGroup>
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  {...rest}
                  inputProps={restInput}
                  onChange={onChange}
                  checked={checked}
                  name={name}
                />
              }
            />

            {errorText && <FormHelperText>{errorText}</FormHelperText>}
          </FormGroup>
        )
      }}
    />
  )
}
