// taken from the sdk

import React from 'react'
import PropTypes from 'prop-types'
import PromiseFileReader from './PromiseFileReader'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import attachmentIcon from './attachmentIcon.svg'

import { GlobalSnackbarService } from './GlobalSnackbarService'

export default class Attachments extends React.Component {
  static propTypes = {
    input: PropTypes.object.isRequired
  }

  isUnmounted = false

  state = {
    files: [],
    previewImages: []
  }

  setFileInputRef = input => {
    this.filesInput = input
  }

  onFilesSelected = e => {
    const files = Array.from(e.target.files)

    const maxSizeInMb = 10
    const allFilesSmallEnough = files.every(file => {
      const sizeInMb = file.size / 1024 / 1024
      return sizeInMb < maxSizeInMb
    })

    if (!allFilesSmallEnough) {
      this.setState({ files: [], previewImages: [] })
      this.filesInput.value = null
      this.props.input.onChange(null)
      GlobalSnackbarService.emit(
        `Eine Datei überschreitet die maximale Größe von ${maxSizeInMb}MB`,
        'error'
      )
    } else {
      Promise.all(
        files.map(f => {
          switch (f.type) {
            case 'image/png':
            case 'image/svg+xml':
            case 'image/jpeg':
              return PromiseFileReader.readAsDataUrl(f)
            case 'application/pdf':
            default:
              return attachmentIcon
          }
        })
      ).then(images => {
        if (!this.isUnmounted) {
          this.setState({ previewImages: images })
        }
      })

      this.setState({ files: files })
      this.props.input.onChange(files)
    }
  }

  buttonText() {
    if (this.state.files.length === 0) {
      return 'Dateien anhängen'
    } else if (this.state.files.length === 1) {
      return 'Datei ersetzen'
    } else {
      return 'Dateien ersetzen'
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-file-upload">
          <Button color="secondary" component="label" size="small" fullWidth>
            <Icon>attach_file</Icon>
            {this.buttonText()}

            {/* has some nesting magic so that the button around will trigger it */}
            <input
              style={{ display: 'none' }}
              type="file"
              multiple
              data-testid="inputGroupFile"
              className="form-file-upload__input"
              ref={this.setFileInputRef}
              id="inputGroupFile"
              onChange={this.onFilesSelected}
            />
          </Button>
        </div>

        {this.state.files.length > 0 && (
          <List dense>
            {this.state.files.map((f, index) => (
              <ListItem
                key={f.name}
                className={`form-file-upload__list-item type-${f.type}`}>
                <ListItemAvatar>
                  <Avatar>
                    <img
                      className="form-file-upload__list-item-img"
                      alt={f.name}
                      src={this.state.previewImages[index]}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className="form-file-upload__list-item-name"
                  primary={f.name}
                />
              </ListItem>
            ))}
          </List>
        )}
      </React.Fragment>
    )
  }
}
