import * as React from 'react'
import { Route } from 'react-router-dom'

import { MFASetup } from './MFASetup'
import { UserDetails } from './UserDetails'

export function Settings({
  authData,
  match,
  onTOTPSetup,
  onVerifyTOTPSetup,
  Components
}) {
  const base = match.url

  return (
    <React.Fragment>
      <Route
        exact
        path={`${base}/`}
        render={routerProps => <UserDetails user={authData} {...routerProps} />}
      />
      <Route
        exact
        path={`${base}/mfa-setup`}
        render={routerProps => (
          <MFASetup
            onTOTPSetup={onTOTPSetup}
            onVerifyTOTPSetup={onVerifyTOTPSetup}
            Components={Components}
            {...routerProps}
          />
        )}
      />
    </React.Fragment>
  )
}
