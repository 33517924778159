import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Settings } from '../../settings/Settings'
import {
  AuthorizationFilterHOC,
  AccessRights
} from '../../auth/AuthorizationFilter'
import LazyLoader from '../LazyLoader'

import UpdatePolicyContainer from '../../customers/customer-details/policy-details/UpdatePolicyContainerTemp'
import CustomerPortals from '../../customer-portals/CustomerPortals'
import RisksGroup from '../../risks/RisksGroup'
import CustomerPortalsFaqs from '../../customer-portals-faqs/CustomerPortalsFaqs'

import CustomerSearchContainer from '../../customers/CustomerSearchContainer'
import CustomerDetailsContainer from '../../customers/customer-details/CustomerDetailsContainer'
import QueueMessagePage from '../../queue-message/QueueMessagePage'
import AttributesCombinePage from '../../attributes-combine/AttributesCombinePage'
import { UserListPage } from '../../user-management/UserListPage'
import CreatePolicyWizardMicrofrontend from '../../policy-creation/CreatePolicyWizardMicrofrontend'

function AppContent({ auth }: any) {
  return (
    <main>
      <Switch>
        <Route exact path="/customers" component={CustomerSearchContainer} />
        <Route
          exact
          path="/customers/:customerId"
          component={CustomerDetailsContainer}
        />
        <Route
          exact
          path="/customers/:customerId/policies/:policyId/mta"
          component={UpdatePolicyContainer}
        />
        <Route
          path="/brands"
          component={AuthorizationFilterHOC(
            LazyBrands,
            AccessRights.ProductAdmin,
            true
          )}
        />
        <Route
          path="/partners"
          component={AuthorizationFilterHOC(
            LazyPartners,
            AccessRights.PartnerAdmin,
            true
          )}
        />
        <Route
          path="/product-line"
          component={AuthorizationFilterHOC(
            LazyProductLine,
            AccessRights.ProductAdmin,
            true
          )}
        />
        <Route
          path="/lines-of-business"
          component={AuthorizationFilterHOC(
            LazyLinesOfBusiness,
            AccessRights.ProductAdmin,
            true
          )}
        />
        <Route
          path="/insurance-products"
          component={AuthorizationFilterHOC(
            InsuranceProducts,
            AccessRights.InsuranceProductAdmin,
            true
          )}
        />
        <Route
          path="/products"
          component={AuthorizationFilterHOC(
            LazyProducts,
            AccessRights.ProductAdmin,
            true
          )}
        />
        <Route
          path="/document-creation"
          component={AuthorizationFilterHOC(
            LazyDocumentCreation,
            AccessRights.ProductAdmin,
            true
          )}
        />
        <Route
          path="/risks"
          component={AuthorizationFilterHOC(
            RisksGroup,
            AccessRights.ProductAdmin,
            true
          )}
        />
        <Route
          path="/purchase-flows"
          component={AuthorizationFilterHOC(
            props => (
              <React.Suspense fallback={<LinearProgress />}>
                <LazyPurchaseFlows {...props} />
              </React.Suspense>
            ),
            AccessRights.PurchaseFlowAdmin,
            true
          )}
        />

        <Route
          path="/customer-portals"
          component={AuthorizationFilterHOC(
            CustomerPortals,
            AccessRights.CustomerPortalAdmin,
            true
          )}
        />

        <Route
          exact
          path={`/customer-portal-faqs/:productId`}
          component={AuthorizationFilterHOC(
            CustomerPortalsFaqs,
            AccessRights.CustomerPortalAdmin,
            true
          )}
        />

        <Route
          exact
          path="/api-documentation/:partnerId"
          component={AuthorizationFilterHOC(
            LazyDocs,
            AccessRights.Element,
            true
          )}
        />

        <Route
          path="/test-policy"
          component={AuthorizationFilterHOC(
            () => (
              <CreatePolicyWizardMicrofrontend testData={true} />
            ),
            AccessRights.CreateTestPolicy,
            true
          )}
        />

        <Route
          path="/policy"
          component={AuthorizationFilterHOC(
            () => (
              <CreatePolicyWizardMicrofrontend testData={false} />
            ),
            AccessRights.CreateProductionPolicy,
            true
          )}
        />

        <Route
          path="/test-order"
          component={AuthorizationFilterHOC(
            () => (
              <CreatePolicyWizardMicrofrontend testData={true} />
            ),
            AccessRights.CreateTestPolicy,
            true
          )}
        />

        <Route
          path="/order"
          component={AuthorizationFilterHOC(
            () => (
              <CreatePolicyWizardMicrofrontend testData={false} />
            ),
            AccessRights.CreateProductionPolicy,
            true
          )}
        />

        <Route
          exact
          path="/queue-message"
          component={AuthorizationFilterHOC(
            QueueMessagePage,
            AccessRights.Element,
            true
          )}
        />

        <Route
          exact
          path="/attributes-combine"
          component={AuthorizationFilterHOC(
            AttributesCombinePage,
            AccessRights.Element,
            true
          )}
        />

        <Route
          exact
          path="/user-management"
          component={AuthorizationFilterHOC(
            UserListPage,
            AccessRights.UserAdmin,
            true
          )}
        />

        <Route
          path="/settings"
          render={routerProps => <Settings {...auth} {...routerProps} />}
        />

        <Redirect to="/customers" />
      </Switch>
    </main>
  )
}

export default React.memo(AppContent)

const LazyPurchaseFlows = LazyLoader({
  loader: () => import('../../purchase-flows/PurchaseFlows')
})

const LazyBrands = LazyLoader({
  loader: () => import('../../brands/Brands')
})

const LazyPartners = LazyLoader({
  loader: () => import('../../partners/Partners')
})

const LazyProducts = LazyLoader({
  loader: () => import('../../products/Products')
})

const LazyProductLine = LazyLoader({
  loader: () => import('../../product-line/ProductLine')
})

const LazyLinesOfBusiness = LazyLoader({
  loader: () => import('../../lines-of-business/LinesOfBusiness')
})

const InsuranceProducts = LazyLoader({
  loader: () => import('../../insurance-products/InsuranceProducts')
})

const LazyDocs = LazyLoader({
  loader: () => import('../../api-documentation/APIDocumentation')
})

const LazyDocumentCreation = LazyLoader({
  loader: () => import('../../document-creation/DocumentCreation')
})
