import React from 'react'
import PropTypes from 'prop-types'
import { CustomerDataFields } from './CustomerDataFields'
import { customerService } from '../../services/CustomerService'
import store from '../CustomerStore'
import arrayMutators from 'final-form-arrays'
import { ModalFormButton } from '../../components/ModalFormButton'
import ConfirmationDialogContext from '../../components/ConfirmationDialogContext'

export default class UpdateCustomerButton extends React.Component {
  static propTypes = {
    customer: PropTypes.object.isRequired
  }

  static contextType = ConfirmationDialogContext

  onClose = () => {
    store.loadCustomer(this.props.customer.id)
  }

  constructor(props) {
    super(props)

    let initialValues = { ...this.props.customer }

    initialValues.addresses = this.props.customer.addresses.map(value => {
      return {
        addressPrefix: value.addressPrefix,
        street: value.street,
        houseNumber: value.houseNumber,
        postCode: value.postCode,
        city: value.city,
        country: value.country
      }
    })

    this.state = {}
    this.state.initialValues = initialValues
  }

  submit = formData => {
    const { customer } = this.props
    const request = {
      testData: customer.testData,
      partnerId: this.props.partnerId,
      ...formData
    }

    if (customer.testData === false) {
      const confirmDialog = this.context
      return confirmDialog({
        variant: 'danger',
        catchOnCancel: true,
        title: 'Änderung im Produktivsystem',
        description:
          'Bitte bestätigen Sie die Bearbeitung eines Kunden im Produktivsystem.',
        primaryButtonText: 'Abbrechen',
        secondaryButtonText: 'Bearbeitung Bestätigen'
      })
        .then(() => customerService.update(customer.id, request))
        .catch(() => Promise.reject({ message: 'Änderung nicht bestätigt' }))
    }
    return customerService.update(customer.id, request)
  }

  render = () => {
    const { customer, ...rest } = this.props

    return (
      <ModalFormButton
        onSubmit={this.submit}
        onClose={this.onClose}
        titleLabel={`Kundendaten ändern (Kundennummer: ${customer.id})`}
        buttonLabel={'Kundendaten ändern'}
        successLabel={'Kundendaten erfolgreich geändert.'}
        {...rest}
        formProps={{
          initialValues: this.state.initialValues,
          mutators: {
            ...arrayMutators
          }
        }}
        formContent={
          <CustomerDataFields type={customer.type} allowEUCountries={true} />
        }
      />
    )
  }
}
