import React from 'react'
import PropTypes from 'prop-types'
import { policyService } from '../../../../services/PolicyService'
import ProgressVisualizer from '../../../../utils/ProgressVisualizer'
import Payments from './Payments'
import { DateTime } from 'luxon'

const mockPayments = [
  { dueDate: DateTime.local().minus({ months: 7 }), status: 'PAID' },
  { dueDate: DateTime.local().minus({ months: 6 }), status: 'PAID' },
  { dueDate: DateTime.local().minus({ months: 5 }), status: 'PAID' },
  { dueDate: DateTime.local().minus({ months: 4 }), status: 'PAID' },
  { dueDate: DateTime.local().minus({ months: 3 }), status: 'PAID' },
  { dueDate: DateTime.local().minus({ months: 2 }), status: 'PAID' },
  { dueDate: DateTime.local().minus({ months: 1 }), status: 'PAID' },
  { dueDate: DateTime.local(), status: 'UPCOMING' }
]

export default class PaymentsContainer extends React.Component {
  static propTypes = {
    policyId: PropTypes.string.isRequired,
    testData: PropTypes.bool.isRequired
  }

  state = {
    payments: null,
    error: null,
    isCollapsed: true
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.policyId !== this.props.policyId) {
      this.loadData()
    }
  }

  toggleCollapsed = e => {
    e.preventDefault()
    this.setState(state => ({ isCollapsed: !state.isCollapsed }))
  }

  loadData() {
    if (!this.props.testData) {
      policyService
        .loadPayments(this.props.policyId)
        .then(payments => {
          this.setState({ payments })
        })
        .catch(error => {
          console.error('Error loading payments', this.state.error)
          this.setState({ error })
        })
    } else {
      this.setState({ payments: mockPayments })
    }
  }

  render() {
    const { error, payments, isCollapsed } = this.state
    if (error) {
      return (
        <div style={{ color: '#bbb' }}>
          Information vorübergehend nicht verfügbar. Bitte versuchen Sie es
          später erneut.
        </div>
      )
    }

    if (!payments) {
      return <ProgressVisualizer />
    }

    return (
      <Payments
        onToggleCollapsed={this.toggleCollapsed}
        collapsed={isCollapsed}
        payments={payments}
      />
    )
  }
}
