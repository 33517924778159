import React from 'react'
import Divider from '@material-ui/core/Divider'
import { formatCurrency, formatDate } from '../../../../utils/Formatters'
import { allTransferEventsFileAttachments } from '../../../../utils/timeline/TimelineUtils'
import {
  capitalizeFirstLetter,
  filterPaymentMethod,
  getInvoiceNumber,
  getLastEvent,
  getPaymentTransactionLabel,
  getTransactionIcon,
  mapPaymentType,
  mapTransactionType,
  mask
} from './PaymentDetailsUtils'
import { PaymentEventDetailsTimeline } from './PaymentEventDetailsTimeline'
import PaymentEventDetailsTimelineFactory from './PaymentEventDetailsTimelineFactory'
import { PaymentMethods } from './PaymentMethodConst'
import { PaymentsCollapsibleTimelineEvent } from './PaymentsCollapsibleTimelineEvent'
import { SetRefundDetailsButton } from './SetRefundDetailsButton'

export default function PaymentHistoryTimelineEvent({
  customerId,
  policyId,
  transaction,
  paymentMethods,
  paymentTransfers,
  documents,
  ...rest
}) {
  let paymentMethod = filterPaymentMethod(
    transaction.paymentMethodId,
    paymentMethods
  )

  let paymentDocuments = allTransferEventsFileAttachments(
    transaction.events,
    documents
  )

  // TODO remove this later when transfers will be removed from the list of transactions
  // Hide transfer card
  if (transaction.eventTypes[0] === 'PAYMENT_TRANSACTION_TRANSFER_RECEIVED') {
    return null
  }

  return (
    <PaymentsCollapsibleTimelineEvent
      policyId={policyId}
      transaction={transaction}
      paymentMethod={paymentMethod}
      icon={getTransactionIcon(transaction)}
      paymentDocuments={paymentDocuments}
      label={getPaymentTransactionLabel(transaction)}
      documents={documents}
      {...rest}>
      <GenericPaymentContent
        customerId={customerId}
        policyId={policyId}
        transaction={transaction}
        paymentMethod={paymentMethod}
        paymentMethods={paymentMethods}
        paymentTransfers={paymentTransfers}
      />
    </PaymentsCollapsibleTimelineEvent>
  )
}

export function GenericPaymentContent({
  customerId,
  policyId,
  transaction,
  paymentMethod,
  paymentMethods,
  paymentTransfers
}) {
  const lastTransactionEvent = getLastEvent(transaction)
  const { amount, refundAmount, dueDate } = transaction

  const lastTransactionDate = formatDate(lastTransactionEvent.transactionDate)
  const invoiceNumber = getInvoiceNumber(transaction)

  const paymentEvents = PaymentEventDetailsTimelineFactory.create(
    transaction,
    paymentMethod,
    paymentMethods,
    paymentTransfers
  )
  return (
    <>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>
          {mapTransactionType(transaction.latestEventType)} am:{' '}
          {lastTransactionDate}
        </li>
        {paymentMethod && paymentMethod.type === 'INVOICE' && (
          <li>Rechnungsnummer: {invoiceNumber}</li>
        )}
        {dueDate && paymentMethod.type === 'INVOICE' && (
          <li>Zahlungsziel: {formatDate(dueDate)}</li>
        )}
        {refundAmount !== 0 && <li>Betrag: {formatCurrency(amount)}</li>}
        {transaction.outstandingAmount !== 0 && (
          <li>
            Offener Betrag: {formatCurrency(transaction.outstandingAmount)}
          </li>
        )}
        {transaction.refundedAmount !== 0 && (
          <li>
            Gezahlter Betrag nach Rückzahlung:{' '}
            {formatCurrency(transaction.amount + transaction.refundedAmount)}
          </li>
        )}
      </ul>
      <PaymentTransactionMethod
        paymentMethodChangeReason={transaction?.paymentMethodChangeReason}
        paymentMethod={paymentMethod}
      />
      <PaymentDisputeContent transaction={transaction} />
      <PaymentEventDetailsTimeline items={paymentEvents} />
      <RefundTransaction
        customerId={customerId}
        policyId={policyId}
        paymentMethod={paymentMethod}
        eventType={lastTransactionEvent.eventType}
      />
    </>
  )
}

export function PaymentDisputeContent({ transaction }) {
  const { latestEventType, amount, transactionDate } = transaction
  return [
    'PAYMENT_TRANSACTION_DISPUTE_OPENED',
    'PAYMENT_TRANSACTION_DISPUTE_FUNDS_WITHDRAWN',
    'PAYMENT_TRANSACTION_DISPUTE_UPDATED',
    'PAYMENT_TRANSACTION_DISPUTE_FUNDS_REINSTATED',
    'PAYMENT_TRANSACTION_DISPUTE_WON',
    'PAYMENT_TRANSACTION_DISPUTE_LOST',
    'PAYMENT_TRANSACTION_DISPUTE_WARNING_CLOSED'
  ].includes(latestEventType) ? (
    <>
      <b>Rückbuchung:</b>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>Betrag: {formatCurrency(amount)}</li>
        <li>Datum: {formatDate(transactionDate)}</li>
        <li>Status: {mapTransactionType(latestEventType)}</li>
      </ul>
    </>
  ) : null
}

export function PaymentTransactionMethod({
  paymentMethod,
  paymentMethodChangeReason
}) {
  const { provider, type, metadata } = paymentMethod
  const listStyle = { margin: 10, padding: '0 10px' }

  // TODO refactor this later
  if (type === PaymentMethods.PAYPAL) {
    return (
      <>
        <p>
          <b>Zahlungsmethode:</b> <span>{mapPaymentType(type)}</span>
        </p>
      </>
    )
  }

  const iban = metadata.iban
  const cardNumber = metadata.cardLastFourDigits
  const accountHolder = metadata.accountHolder

  const providerName =
    paymentMethodChangeReason && provider === 'STRIPE'
      ? `${capitalizeFirstLetter(provider)} vIBAN for Dunning`
      : `${capitalizeFirstLetter(provider)}`

  return (
    <>
      <b data-testid="paymentTransactionMethod-title">
        Zahlungsmethode ({providerName}):
      </b>
      <ul style={listStyle}>
        <li>{mapPaymentType(type)}</li>
        {cardNumber && <li>Kartennummer: {mask(cardNumber)}</li>}
        {accountHolder && <li>Kontoinhaber: {accountHolder}</li>}
        {iban && <li>IBAN: {iban}</li>}
      </ul>
    </>
  )
}

function RefundTransaction({ customerId, policyId, eventType, paymentMethod }) {
  return (
    eventType === 'PAYMENT_TRANSACTION_REFUND_DATA_REQUIRED' && (
      <>
        <Divider />
        <SetRefundDetailsButton
          customerId={customerId}
          policyId={policyId}
          testData={paymentMethod.testData}
        />
      </>
    )
  )
}
