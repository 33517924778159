import React from 'react'

export type NameMapping = {
  [key: string]: string
}

export type ElementNameMappingContext = {
  nameMap: NameMapping | null
}

export default React.createContext<ElementNameMappingContext | null>(null)
