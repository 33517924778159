import {
  formatCurrency,
  formatDate,
  formatDateTime
} from '../../../../utils/Formatters'
import { PaymentEventDetailsTimelineContext } from './PaymentEventDetailsTimelineContext'
import { PaymentMethods } from './PaymentMethodConst'
import {
  capitalizeFirstLetter,
  filterPaymentMethod,
  filterPaymentTransfer,
  getFirstEventByType,
  mapPaymentType
} from './PaymentDetailsUtils'
import { TimelineContextPayloadItem } from './TimelineContextPayloadItem'
import { TimelineContextPayloadType } from './TimelineContextPayloadType'
import { TimelinePayPalPaymentMethodDetailsContext } from './TimelinePayPalPaymentMethodDetailsContext'
import { TimelineConfirmedTransactionWithExtraFundsContext } from './TimelineConfirmedTransactionWithExtraFundsContext'

export default class PaymentEventDetailsTimelineFactory {
  private static readonly hiddenEventTypes = [
    'PAYMENT_TRANSACTION_IDEMPOTENTID_ADDED',
    'PAYMENT_TRANSACTION_TRANSFER_CHARGING',
    'PAYMENT_TRANSACTION_DISPUTE_FUNDS_WITHDRAWN',
    'PAYMENT_TRANSACTION_DISPUTE_UPDATED',
    'PAYMENT_TRANSACTION_DISPUTE_FUNDS_REINSTATED',
    'PAYMENT_TRANSACTION_DISPUTE_WARNING_CLOSED',
    'PAYMENT_TRANSACTION_PENDING',
    'PAYMENT_TRANSACTION_REFUND_PENDING',
    'PAYMENT_TRANSACTION_PAYOUT_PENDING'
  ]

  /**
   * Creates a collection of the {@link PaymentEventDetailsTimelineContext} items based on {@link @param transaction} event types.
   * The unsupported events are filtered (please see the {@link hiddenEventTypes} collection).
   *
   * @param paymentMethods - A collection of all payment methods. In case of the
   * "PAYMENT_TRANSACTION_REFUND_BY_FALLBACK_INITIATED" event, the collection is used
   * to fetch the payment method that includes the IBAN for the refund.
   */
  static create(
    transaction,
    paymentMethod,
    paymentMethods: Array<any>,
    paymentTransfers: Array<any>
  ): PaymentEventDetailsTimelineContext[] {
    const filteredEvents = transaction.events.filter(
      i => !this.hiddenEventTypes.includes(i.eventType)
    )

    const timeLineItems = filteredEvents.map((event, idx) => {
      return this.createPaymentEventDetailTimeLine(
        transaction,
        event,
        idx,
        filteredEvents,
        paymentMethod,
        paymentMethods,
        paymentTransfers
      )
    })

    timeLineItems.reverse()
    return timeLineItems
  }

  private static createPaymentEventDetailTimeLine(
    transaction,
    event,
    currentEventArrayIdx: number,
    allFilteredEvents,
    paymentMethod,
    paymentMethods,
    paymentTransfers: Array<any>
  ): PaymentEventDetailsTimelineContext {
    const formattedTransactionAmount = formatCurrency(transaction.amount)

    const detailTimeLineItem = {
      title: '',
      dateTime: formatDateTime(event.transactionDate),
      payloadItems: new Array<string | TimelineContextPayloadItem>()
    }

    switch (event.eventType) {
      case 'PAYMENT_TRANSACTION_CREATED':
        this.setPaymentTransactionCreatedFields(
          detailTimeLineItem,
          event,
          transaction,
          paymentMethod,
          paymentMethods
        )
        break
      case 'PAYMENT_TRANSACTION_CONFIRMED':
        detailTimeLineItem.title = `Bezahlt ${formatCurrency(
          this.getAmount(event)
        )}:`
        this.setDueDateField(detailTimeLineItem, event, paymentMethods)
        this.setPaymentTransferField(
          detailTimeLineItem,
          event,
          paymentTransfers
        )
        event.allowance &&
          detailTimeLineItem.payloadItems.push(
            `Allowance: ${formatCurrency(event.allowance)}`
          )
        if (paymentMethod.provider === PaymentMethods.PAYPAL) {
          this.setPaymentTransactionConfirmedPayPalPayerDetails(
            detailTimeLineItem,
            paymentMethod
          )
        }
        break
      case 'PAYMENT_TRANSACTION_TRANSFER_FUNDS_INSUFFICIENT':
        detailTimeLineItem.title = `Überweisung von ${formatCurrency(
          event.transferredAmount
        )}:`
        detailTimeLineItem.payloadItems.push(
          `Bezahlt: ${formatCurrency(event.collectedAmount)}`
        )
        this.setDueDateField(detailTimeLineItem, event, paymentMethods)
        this.setPaymentTransferField(
          detailTimeLineItem,
          event,
          paymentTransfers
        )
        break
      case 'PAYMENT_TRANSACTION_OUTSTANDING':
        detailTimeLineItem.title = `Zahlung von ${formatCurrency(
          event.outstandingAmount
        )} ausstehend:`
        this.setDueDateField(detailTimeLineItem, event, paymentMethods)
        break
      case 'PAYMENT_TRANSACTION_CONFIRMED_WITH_EXTRA_FUNDS':
        this.setPaymentTransactionConfirmedWithExtraFundsFields(
          transaction.idempotentId,
          detailTimeLineItem,
          event,
          paymentMethods,
          paymentTransfers
        )
        break
      case 'PAYMENT_TRANSACTION_OVERDUE':
        if (event.overdueAmount) {
          const formattedOverdueAmount = formatCurrency(event.overdueAmount)
          detailTimeLineItem.title = `Zahlung von ${formattedOverdueAmount} überfällig:`
        } else {
          detailTimeLineItem.title = `Zahlung überfällig:`
        }
        this.setDueDateField(detailTimeLineItem, event, paymentMethods)
        break
      case 'PAYMENT_TRANSACTION_AMOUNT_ADJUSTED':
        this.setPaymentTransactionAmountAdjustedFields(
          detailTimeLineItem,
          event,
          currentEventArrayIdx,
          allFilteredEvents
        )
        break
      case 'PAYMENT_TRANSACTION_REFUND_INITIATED':
        this.setPaymentTransactionRefundInitiatedFields(
          detailTimeLineItem,
          event,
          paymentMethod
        )
        break
      case 'PAYMENT_TRANSACTION_REFUND_CONFIRMED':
        this.setPaymentTransactionRefundConfirmedFields(
          detailTimeLineItem,
          paymentMethod,
          event
        )
        break
      case 'PAYMENT_TRANSACTION_REFUND_FAILED':
        this.setPaymentTransactionRefundFailedFields(
          detailTimeLineItem,
          transaction,
          paymentMethod
        )
        break
      case 'PAYMENT_TRANSACTION_REFUND_DATA_REQUIRED':
        const amount = this.getAbsFormattedCurrency(event.refundAmount)
        detailTimeLineItem.title = `IBAN für Rückzahlung von ${amount} angefordert:`
        break
      case 'PAYMENT_TRANSACTION_FAILED':
        this.setPaymentTransactionFailedFields(
          detailTimeLineItem,
          event,
          formattedTransactionAmount,
          transaction,
          paymentMethod,
          paymentMethods
        )
        break
      case 'PAYMENT_TRANSACTION_REFUND_BY_FALLBACK_INITIATED':
        this.setPaymentTransactionRefundByFallbackInitiatedFields(
          detailTimeLineItem,
          event,
          paymentMethods
        )
        break
      case 'PAYMENT_TRANSACTION_DISPUTE_OPENED':
        detailTimeLineItem.title = `Rücklastschrift von ${formattedTransactionAmount} initiiert:`
        break
      case 'PAYMENT_TRANSACTION_DISPUTE_WON':
      case 'PAYMENT_TRANSACTION_DISPUTE_RESOLVED_FOR_SELLER':
        detailTimeLineItem.title = `Rücklastschrift zugunsten von Element:`
        detailTimeLineItem.payloadItems.push(
          `Betrag von ${formattedTransactionAmount}`
        )
        break
      case 'PAYMENT_TRANSACTION_DISPUTE_LOST':
      case 'PAYMENT_TRANSACTION_DISPUTE_RESOLVED_FOR_BUYER':
        detailTimeLineItem.title = `Rücklastschrift zugunsten von Kunden:`
        detailTimeLineItem.payloadItems.push(
          `Betrag von ${formattedTransactionAmount}`
        )
        break
      case 'PAYMENT_TRANSACTION_PAYOUT_CONFIRMED':
        const payoutAmount = this.getAbsFormattedCurrency(transaction.amount)
        detailTimeLineItem.title = `Rückzahlung von ${payoutAmount} bestätigt:`
        break
      case 'PAYMENT_TRANSACTION_MANUAL_TRANSFER_FUNDS_INSUFFICIENT':
        const transferredAmount = this.getAbsFormattedCurrency(
          event.transferredAmount
        )
        detailTimeLineItem.title = `Überweisung von ${transferredAmount}`
        this.setDunningPaymentField(
          TimelineContextPayloadType.TransferFundsInsufficient,
          detailTimeLineItem,
          event,
          paymentTransfers
        )
        break
      case 'PAYMENT_TRANSACTION_CONFIRMED_WITH_MANUAL_TRANSFER':
        detailTimeLineItem.title = `Bezahlt ${formattedTransactionAmount}`
        this.setDunningPaymentField(
          TimelineContextPayloadType.ConfirmedWithManualTransfer,
          detailTimeLineItem,
          event,
          paymentTransfers
        )
        break
      case 'PAYMENT_TRANSACTION_PAYMENT_METHOD_CHANGED':
        const { paymentMethodChangeReason } = transaction

        const reasonTextMapping = {
          DUNNING: 'Dunning',
          CUSTOMER_REQUEST: 'Customer request'
        }

        const paymentReason = reasonTextMapping[paymentMethodChangeReason]

        detailTimeLineItem.title = `Änderung der Zahlungsmethode:`
        paymentReason &&
          detailTimeLineItem.payloadItems?.push(`Grund: ${paymentReason}`)

        break
      case 'PAYMENT_TRANSACTION_CANCELLED':
        this.setPaymentTransactionCancelled(detailTimeLineItem, event)
        break
      case 'PAYMENT_TRANSACTION_BOUNCED':
        detailTimeLineItem.title = `Zahlung abgelehnt:`
        break
      case 'PAYMENT_TRANSACTION_DISPUTE_REFUNDED':
        this.setPaymentTransactionDisputeRefundedFields(
          detailTimeLineItem,
          paymentMethod,
          event
        )
        break
      default:
        detailTimeLineItem.title = event.eventType
    }

    return detailTimeLineItem
  }

  /**
   * Sets field values of the event 'PAYMENT_TRANSACTION_CREATED'.
   * If the provider is TIA, the event is considered the initiation of the payout.
   */
  private static setPaymentTransactionCreatedFields(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event,
    transaction,
    paymentMethod,
    paymentMethods
  ) {
    if (transaction.provider === 'TIA' && event.amount < 0) {
      const amount = this.getAbsFormattedCurrency(event.amount)
      detailTimeLineItem.title = `Rückzahlung von ${amount} initiiert:`
      return
    }

    detailTimeLineItem.title = `Zahlung von ${formatCurrency(
      this.getAmount(event)
    )} erstellt:`
    this.setDueDateField(detailTimeLineItem, event, paymentMethods)
  }

  private static setPaymentTransactionRefundByFallbackInitiatedFields(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event,
    paymentMethods
  ) {
    const amount = this.getAbsFormattedCurrency(event.refundAmount)
    detailTimeLineItem.title = `Rückzahlung von ${amount} via TIA:`

    const iban = this.getRefundIban(event, paymentMethods)
    const payload = `Lastschrift, IBAN: ${iban}`
    detailTimeLineItem.payloadItems?.push(payload)
  }

  /**
   * Returns a refund IBAN that depends on the payment method type.
   * Legacy events do not have the field "refundPaymentMethodId".
   */
  private static getRefundIban(event, paymentMethods): string | undefined {
    const refundPaymentMethodId = event.refundPaymentMethodId
    if (!refundPaymentMethodId) {
      return undefined
    }

    const refundPaymentMethod = filterPaymentMethod(
      refundPaymentMethodId,
      paymentMethods
    )

    let iban
    if (refundPaymentMethod.type === PaymentMethods.DIRECT_DEBIT) {
      iban = refundPaymentMethod.metadata?.iban
    } else {
      iban = refundPaymentMethod.metadata?.refundIban
    }

    return iban
  }

  private static setPaymentTransactionAmountAdjustedFields(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event,
    currentEventArrayIdx: number,
    allFilteredEvents: Array<any>
  ) {
    detailTimeLineItem.title = 'Vertragsanpassung:'
    detailTimeLineItem.dateTime = undefined
    detailTimeLineItem.payloadItems?.push(
      `Angepasst am: ${formatDateTime(event.createdAt)}`
    )
    if (event.metadata?.mtaEffectiveDate) {
      detailTimeLineItem.payloadItems?.push(
        `Wirksam zum: ${formatDateTime(event.metadata.mtaEffectiveDate)}`
      )
    }
    detailTimeLineItem.payloadItems?.push(
      `neue Prämie ${formatCurrency(event.amount)}`
    )

    if (event.metadata?.mtaType !== 'PARTIALLY_PAID_WITH_DECREASED_PREMIUM') {
      return
    }
  }

  private static setPaymentTransactionFailedFields(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event,
    formattedTransactionAmount: string,
    transaction,
    paymentMethod,
    paymentMethods
  ) {
    detailTimeLineItem.title = `Zahlung von ${formattedTransactionAmount} gescheitert:`
    this.setDueDateField(detailTimeLineItem, event, paymentMethods)
    if (event.providerData?.failureMessage) {
      detailTimeLineItem.payloadItems?.push(
        `Grund: ${event.providerData?.failureMessage}`
      )
    }
  }

  private static setPaymentTransactionRefundInitiatedFields(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event,
    paymentMethod
  ) {
    const refundAmount = this.getAbsFormattedCurrency(event.refundAmount)
    detailTimeLineItem.title = `Rückzahlung von ${refundAmount} initiiert:`

    if (paymentMethod.provider !== PaymentMethods.PAYPAL) {
      this.setPaymentTransactionRefundPayload(detailTimeLineItem, paymentMethod)
    } else {
      const payPalMethod = mapPaymentType(PaymentMethods.PAYPAL)
      const payload = `Zahlungsmethode: ${payPalMethod}`
      detailTimeLineItem.payloadItems?.push(payload)
    }
  }

  private static setPaymentTransactionConfirmedPayPalPayerDetails(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    paymentMethod
  ) {
    this.setPaymentTransactionConfirmedPayPalDetails(
      detailTimeLineItem,
      payPalPaymentContext => {
        payPalPaymentContext.payerName = paymentMethod.metadata?.payerName
        payPalPaymentContext.payerEmail = paymentMethod.metadata?.payerEmail
      }
    )
  }

  private static setPaymentTransactionConfirmedPayPalReceiverDetails(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    paymentMethod
  ) {
    this.setPaymentTransactionConfirmedPayPalDetails(
      detailTimeLineItem,
      payPalPaymentContext => {
        payPalPaymentContext.receiverName = paymentMethod.metadata?.payerName
        payPalPaymentContext.receiverEmail = paymentMethod.metadata?.payerEmail
      }
    )
  }

  private static setPaymentTransactionConfirmedPayPalDetails(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    delegate: (
      payPalPaymentDetailsContext: TimelinePayPalPaymentMethodDetailsContext
    ) => void
  ) {
    const methodDetailsContext = new TimelinePayPalPaymentMethodDetailsContext()
    delegate(methodDetailsContext)

    detailTimeLineItem.payloadItems?.push({
      type: TimelineContextPayloadType.PayPalPaymentDetails,
      payload: methodDetailsContext
    })
  }

  private static setPaymentTransactionRefundConfirmedFields(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    paymentMethod,
    event
  ) {
    const refundAmount = this.getAbsFormattedCurrency(event.refundAmount)
    detailTimeLineItem.title = `Rückzahlung von ${refundAmount} bestätigt:`
    if (paymentMethod.provider !== PaymentMethods.PAYPAL) {
      this.setPaymentTransactionRefundPayload(detailTimeLineItem, paymentMethod)
    } else {
      this.setPaymentTransactionConfirmedPayPalReceiverDetails(
        detailTimeLineItem,
        paymentMethod
      )
    }
  }

  private static setPaymentTransactionDisputeRefundedFields(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    paymentMethod,
    event
  ) {
    const refundAmount = this.getAbsFormattedCurrency(event.refundAmount)
    detailTimeLineItem.title = `Rückzahlung wegen Disput von ${refundAmount} bestätigt:`
    this.setPaymentTransactionRefundPayload(detailTimeLineItem, paymentMethod)
  }

  private static setPaymentTransactionRefundFailedFields(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    transaction,
    paymentMethod
  ) {
    const refundAmount = this.getAbsFormattedCurrency(transaction.refundAmount)
    detailTimeLineItem.title = `Rückzahlung von ${refundAmount} gescheitert:`

    this.setPaymentTransactionRefundPayload(detailTimeLineItem, paymentMethod)
  }

  private static setPaymentTransactionRefundPayload(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    paymentMethod
  ) {
    let payload: string
    const providerName = capitalizeFirstLetter(paymentMethod.provider)

    switch (paymentMethod.type) {
      case PaymentMethods.DIRECT_DEBIT:
        payload = `Lastschrift, IBAN: ${paymentMethod.metadata?.iban}, ${providerName}`
        break
      case PaymentMethods.INVOICE:
        payload = `Lastschrift, IBAN: ${paymentMethod.metadata?.transferSenderIban}, ${providerName}`
        break
      case PaymentMethods.EXTERNAL_COLLECTION:
      case PaymentMethods.PAYPAL:
        payload = `${providerName}`
        break
      default:
        payload = `Kreditkarte: *****${paymentMethod.metadata?.cardLastFourDigits}, ${providerName}`
    }
    detailTimeLineItem.payloadItems?.push(payload)
  }

  private static getAbsFormattedCurrency(refundAmount: number) {
    const absValue = Math.abs(refundAmount)
    return formatCurrency(absValue)
  }

  private static setDueDateField(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event,
    paymentMethods
  ) {
    paymentMethods
      .filter(
        p => p.id === event.paymentMethodId && p.type === PaymentMethods.INVOICE
      )
      .map(p => {
        if (event.dueDate) {
          detailTimeLineItem.payloadItems?.push(
            `Zahlungsziel: ${formatDate(event.dueDate)}`
          )
        }
      })
  }

  private static setDunningPaymentField(
    type,
    detailTimeLineItem,
    event,
    paymentTransfers: Array<any>
  ) {
    const transferId =
      type === TimelineContextPayloadType.ConfirmedWithManualTransfer
        ? event.transfers?.slice(-1).pop()
        : event.transferId

    const transfer = filterPaymentTransfer(transferId, paymentTransfers)

    if (transfer) {
      detailTimeLineItem.payloadItems?.push({
        type,
        payload: transfer
      })
    }
  }

  private static setPaymentTransferField(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event,
    paymentTransfers: Array<any>
  ) {
    const transfer = this.getPaymentTransfer(event, paymentTransfers)

    if (transfer) {
      detailTimeLineItem.payloadItems?.push({
        type: TimelineContextPayloadType.SharedTransfer,
        payload: transfer
      } as TimelineContextPayloadItem)
    }
  }

  private static setPaymentTransactionConfirmedWithExtraFundsFields(
    idempotentId: string,
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event,
    paymentMethods,
    paymentTransfers: Array<any>
  ) {
    detailTimeLineItem.title = `Bezahlt ${formatCurrency(event.amount)}:`

    const overchargedTransfer = this.getOverchargedPaymentTransfer(
      event,
      paymentTransfers
    )
    if (!overchargedTransfer) {
      detailTimeLineItem.payloadItems?.push(
        `Überbezahlter Betrag: ${formatCurrency(event.extraFunds)}`
      )
      return
    }

    const refundIban = this.getRefundIbanForOverchargedTransfer(
      overchargedTransfer.events,
      paymentMethods
    )

    detailTimeLineItem.payloadItems?.push({
      type: TimelineContextPayloadType.TransactionConfirmedWithExtra,
      payload: {
        idempotentId,
        refundIban,
        transfer: overchargedTransfer
      } as TimelineConfirmedTransactionWithExtraFundsContext
    } as TimelineContextPayloadItem)
  }

  /**
   * Returns a refund IBAN for the overcharged transfer.
   * The event type PAYMENT_TRANSFER_REFUND_BY_FALLBACK includes a reference
   * to the payment method used to get a refund.
   */
  private static getRefundIbanForOverchargedTransfer(
    events,
    paymentMethods
  ): string | undefined {
    const refundByFallbackTransferEvent = getFirstEventByType(
      events,
      'PAYMENT_TRANSFER_REFUND_BY_FALLBACK'
    )

    if (refundByFallbackTransferEvent) {
      return this.getRefundIban(refundByFallbackTransferEvent, paymentMethods)
    }
    return undefined
  }

  private static getOverchargedPaymentTransfer(event, paymentTransfers) {
    const transferIds: Array<string> = event.transfers
    if (!transferIds) {
      return undefined
    }

    for (const transferId of transferIds) {
      const transfer = filterPaymentTransfer(transferId, paymentTransfers)
      if (transfer) {
        const overchargedEvent = getFirstEventByType(
          transfer.events,
          'PAYMENT_TRANSFER_OVERCHARGED'
        )
        if (overchargedEvent) {
          return transfer
        }
      }
    }

    return undefined
  }

  /*
   * Returns the related transfer or "undefined" in case if the policy was created before payment transfers were introduced.
   */
  private static getPaymentTransfer(event, paymentTransfers) {
    let transferId
    switch (event.eventType) {
      case 'PAYMENT_TRANSACTION_TRANSFER_FUNDS_INSUFFICIENT':
        transferId = event.transferId
        break
      case 'PAYMENT_TRANSACTION_CONFIRMED':
        transferId = event.transfers?.slice(-1).pop()
    }

    return filterPaymentTransfer(transferId, paymentTransfers)
  }

  private static setPaymentTransactionCancelled(
    detailTimeLineItem: PaymentEventDetailsTimelineContext,
    event
  ) {
    detailTimeLineItem.title = `Zahlung storniert:`
    const translatedCancellationReason = this.getCancellationReason(
      event.metadata?.cancellationReason
    )
    detailTimeLineItem.payloadItems?.push(
      `Grund: ${translatedCancellationReason}`
    )
  }

  private static getCancellationReason(cancellationReason) {
    if (cancellationReason === 'CANCELLATION') {
      return 'Vertrag aufgehoben'
    }
    return cancellationReason
  }

  private static getAmount(event) {
    const legacyEvents = [
      'PaymentTransactionCreatedEventV1',
      'PaymentTransactionConfirmedEventV1'
    ]
    if (legacyEvents.includes(event.type)) {
      return event.amount / 100
    }
    return event.amount
  }
}
