import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import useSWR from 'swr'
import { MtaApiService } from '../services/MtaApiService'

const Conflicts = ({ policyId, effectiveDate }) => {
  const { data } = useSWR(
    ['getConflictingClaimsAfterADate', policyId, effectiveDate],
    () => MtaApiService.getConflictingClaimsAfterADate(policyId, effectiveDate),
    { onError: error => console.error(error), revalidateOnFocus: false } // TODO: add swr context to globally set up revalidateOnFocus
  )

  if (data && data.claimResponse.length === 0) {
    return (
      <div>
        <div className="card">
          <h4>Keine Konflikte</h4>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="card">
        <h4
          style={{
            backgroundColor: 'red',
            width: '100px',
            height: '20px',
            color: 'white'
          }}>
          Konflikte
        </h4>
        {data?.claimResponse.map((conflict, index) => (
          <div className="card-body" key={index}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>CLAIMS</TableCell>
                  <TableCell>
                    {conflict.claimId} - {conflict.state}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Conflicts
