import * as React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { PaymentEventDetailsTimelineContext } from './PaymentEventDetailsTimelineContext'
import { PaymentEventDetailsTimelineTransfer } from './PaymentEventDetailsTimelineTransfer'
import { TimelineContextPayloadItem } from './TimelineContextPayloadItem'
import { TimelineContextPayloadType } from './TimelineContextPayloadType'
import { PaymentEventDetailsTimelinePayPalPaymentDetails } from './PaymentEventDetailsTimelinePayPalPaymentDetails'
import { TimelinePayPalPaymentMethodDetailsContext } from './TimelinePayPalPaymentMethodDetailsContext'
import { PaymentEventDetailsTimelineConfirmedTransactionWithExtraFunds } from './PaymentEventDetailsTimelineConfirmedTransactionWithExtraFunds'
import { TimelineConfirmedTransactionWithExtraFundsContext } from './TimelineConfirmedTransactionWithExtraFundsContext'

const useStyles = makeStyles(() => ({
  root: {
    'padding-left': '0px',
    'padding-bottom': '10px',
    'min-height': '45px'
  },
  missingOppositeContent: {
    '&:before': {
      display: 'none'
    }
  }
}))

export const PaymentEventDetailsTimeline: React.FC<Props> = ({ items }) => {
  const classes = useStyles()

  const timeLineItems = items.map((item, idx) => {
    return (
      <React.Fragment key={`${item.dateTime}-${item.title}-${idx}`}>
        <TimelineItem
          classes={{
            root: classes.root,
            missingOppositeContent: classes.missingOppositeContent
          }}>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body2" color="primary">
              {item.title}
            </Typography>
            {item.dateTime && (
              <Typography variant="body2" color="textSecondary">
                {item.dateTime}
              </Typography>
            )}
            {item.payloadItems &&
              item.payloadItems.map((p, payloadIdx) => {
                return createPayload(item, p, payloadIdx)
              })}
          </TimelineContent>
        </TimelineItem>
      </React.Fragment>
    )
  })

  return (
    <Timeline align="left" className={classes.root}>
      {timeLineItems}
    </Timeline>
  )
}

function createPayload(
  item: any,
  payloadItem: string | TimelineContextPayloadItem,
  idx: number
) {
  if (typeof payloadItem === 'string') {
    return (
      <React.Fragment key={`${item.dateTime}-${item.title}-payload-${idx}`}>
        <Typography variant="body2" color="initial">
          {payloadItem}
        </Typography>
      </React.Fragment>
    )
  }

  let fragment
  switch (payloadItem.type) {
    case TimelineContextPayloadType.TransferFundsInsufficient:
    case TimelineContextPayloadType.ConfirmedWithManualTransfer:
      fragment = (
        <React.Fragment key={`${item.dateTime}-${item.title}-payload-${idx}`}>
          <PaymentEventDetailsTimelineTransfer
            transfer={payloadItem.payload}
            title="Transfer for dunning payment / Mahnzahlung:"
          />
        </React.Fragment>
      )
      break
    case TimelineContextPayloadType.SharedTransfer:
      fragment = (
        <React.Fragment key={`${item.dateTime}-${item.title}-payload-${idx}`}>
          <PaymentEventDetailsTimelineTransfer transfer={payloadItem.payload} />
        </React.Fragment>
      )
      break
    case TimelineContextPayloadType.PayPalPaymentDetails:
      const payPalPaymentMethod =
        payloadItem.payload as TimelinePayPalPaymentMethodDetailsContext
      fragment = (
        <React.Fragment key={`${item.dateTime}-${item.title}-payload-${idx}`}>
          <PaymentEventDetailsTimelinePayPalPaymentDetails
            context={payPalPaymentMethod}
          />
        </React.Fragment>
      )
      break
    case TimelineContextPayloadType.TransactionConfirmedWithExtra:
      const transactionWithExtraFundsContext =
        payloadItem.payload as TimelineConfirmedTransactionWithExtraFundsContext
      fragment = (
        <React.Fragment key={`${item.dateTime}-${item.title}-payload-${idx}`}>
          <PaymentEventDetailsTimelineConfirmedTransactionWithExtraFunds
            context={transactionWithExtraFundsContext}
          />
        </React.Fragment>
      )
      break
  }

  return fragment
}

interface Props {
  items: PaymentEventDetailsTimelineContext[]
}
