import React from 'react'
import { ModalFormButton } from '../../../../../components/ModalFormButton'
import { NoPaymentsPlaceholder } from '../Payments'
import TransfersHistoryTimeline from './TransfersHistoryTimeline'

export function TransfersDetailsButton({
  policy,
  paymentMethod,
  customer,
  ...rest
}) {
  function onClose() {}

  function onSubmit(formData) {}

  const transfersForPaymentMethod =
    policy.paymentHistory.paymentTransfers.filter(
      el => el.paymentMethodId === paymentMethod.id
    )

  return (
    <ModalFormButton
      onClose={onClose}
      onSubmit={onSubmit}
      submitDisabled={true}
      fullWidth
      titleLabel={`Überweisungen (Police: ${policy.id}, Externe Id: ${policy.externalId})`}
      buttonLabel={'Überweisungen'}
      {...rest}
      formContent={
        <div>
          {paymentMethod.providerData.sourceId != null ? (
            transfersForPaymentMethod?.length > 0 ? (
              <TransfersHistoryTimeline
                transfers={transfersForPaymentMethod.reverse()}
                paymentMethod={paymentMethod}
                policy={policy}
              />
            ) : (
              <NoPaymentsPlaceholder />
            )
          ) : (
            <div>
              <a
                href={buildStripeUrl(
                  paymentMethod.providerData.customerId,
                  paymentMethod.testData
                )}
                target="_blank"
                rel="noopener noreferrer">
                Link zum Stripe Dashboard für weitere Details
              </a>
            </div>
          )}
        </div>
      }
    />
  )
}

function buildStripeUrl(customerId, testData) {
  if (testData) {
    return `https://dashboard.stripe.com/test/customers/${customerId}/cash_balance_transactions/eur`
  } else {
    return `https://dashboard.stripe.com/customers/${customerId}/cash_balance_transactions/eur`
  }
}
