import React from 'react'
import { observer } from 'mobx-react'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { sortByField } from '../../../utils/Sort'
import { documentUrl } from '../DocumentUrl'
import MessageForm from './MessageForm'
import { CustomCardHeader } from '../LabeledContent'
import { Alert } from 'antd'
import { getTranslationForKey } from '../../../i18n/getTranslationForKey'

export default observer(CommunicationLog)

export function CommunicationLog({
  messages,
  documents,
  policies,
  customerMessagesError
}) {
  let log = messages.map(m => ({ type: 'message', item: m }))
  log.push.apply(
    log,
    documents.map(d => ({ type: 'document', item: d }))
  )
  log = sortByField(log, l => new Date(l.item.createdAt).getTime(), {
    order: 'descending'
  })

  return (
    <Paper elevation={1} style={{ marginTop: 20 }}>
      <CustomCardHeader
        leftColumnRender={
          <Typography variant="subtitle1" component="h3">
            Kommunikation
          </Typography>
        }
      />

      <div style={{ display: 'flex' }}>
        <MessageForm
          style={{ flex: 1, margin: '0px 16px' }}
          policies={policies}
        />
      </div>
      {customerMessagesError && (
        <Alert
          style={{ margin: '0px 16px 16px' }}
          message={getTranslationForKey('CUSTOMER.LOAD.MESSAGES.ERROR')}
          type="error"
          showIcon
        />
      )}

      <Divider />

      {log.map((entry, index) => (
        <Entry key={index} type={entry.type} item={entry.item} />
      ))}
    </Paper>
  )
}

function Entry({ type, item }) {
  switch (type) {
    case 'message':
      return <Message message={item} />
    case 'document':
      return <Document document={item} />
    default:
      console.warn(
        `No implementation found for communication log type [${type}]`
      )
      return null
  }
}

function Message({ message }) {
  const icon = message.type === 'NOTE' ? 'note' : 'mail_outline'
  const orderIdPrefix = message.orderId
    ? '[Order ' + message.orderId + ']'
    : null
  return (
    <ListItem>
      <Header icon={icon} date={message.createdAt} />
      {orderIdPrefix && (
        <div style={{ marginRight: '5px' }}>{orderIdPrefix}</div>
      )}
      <div style={{ whiteSpace: 'pre-wrap' }}>{message.text}</div>
    </ListItem>
  )
}

function Document({ document }) {
  const icon =
    document.type === 'BACKOFFICE_ATTACHMENT' ? 'attach_file' : 'mail_outline'
  return (
    <ListItem>
      <Header icon={icon} date={document.createdAt} />
      <a href={documentUrl(document)} target="_blank" rel="noopener noreferrer">
        {document.filename}
      </a>
    </ListItem>
  )
}

export function Header({ icon, date }) {
  const dateString = new Date(date).toLocaleString()

  return (
    <div style={{ marginRight: 16, opacity: 0.5, whiteSpace: 'nowrap' }}>
      <i style={{ verticalAlign: 'middle' }} className="material-icons">
        {icon}
      </i>
      <span style={{ verticalAlign: 'middle', paddingLeft: 10 }}>
        {dateString}
      </span>
    </div>
  )
}
