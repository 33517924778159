import Tooltip from '@material-ui/core/Tooltip'
import InputLabel from '@material-ui/core/InputLabel'
import { HelpOutline } from '@material-ui/icons'
import React from 'react'

export default function LabelField({
  floatingLabelText,
  fieldNote,
  name,
  shrinkLabel,
  required
}) {
  return fieldNote ? (
    <Tooltip
      title={<span style={{ fontSize: '1.4em' }}>{fieldNote}</span>}
      placement="right">
      <InputLabel htmlFor={name} shrink={shrinkLabel} required={required}>
        {floatingLabelText}
        <HelpOutline />
      </InputLabel>
    </Tooltip>
  ) : (
    <InputLabel htmlFor={name} shrink={shrinkLabel} required={required}>
      {floatingLabelText}
    </InputLabel>
  )
}
