import React, { useEffect, useState } from 'react'
import { useInternalPolicy } from '../../../services/PolicyService'
import { ServiceCallStatus } from '../../../utils/useService'
import ProgressVisualizer from '../../../utils/ProgressVisualizer'
import { InternalPolicy } from './model/InternalPolicy'
import { InternalPolicyContext } from './InternalPolicyContext'

export function InternalPolicyProvider({ forPolicyId, children }) {
  const [internalPolicy, setInternalPolicy] = useState<InternalPolicy | null>(
    null
  )
  const policyCall = useInternalPolicy(forPolicyId)

  useEffect(() => {
    if (policyCall.status === ServiceCallStatus.SUCCESS) {
      setInternalPolicy(InternalPolicy.for(policyCall.policyResponse))
    }
  }, [policyCall.status, policyCall.policyResponse, forPolicyId])

  if (!internalPolicy) return <ProgressVisualizer />

  return (
    <InternalPolicyContext.Provider value={{ internalPolicy }}>
      {children({ internalPolicy })}
    </InternalPolicyContext.Provider>
  )
}
