import React from 'react'
import { DateTime } from 'luxon'
import { Field } from 'react-final-form'
import FormControl from '@material-ui/core/FormControl'
import LabelField from './LabelField'
import DatePicker from 'react-date-picker'
import FormHelperText from '@material-ui/core/FormHelperText'

export default function DatePickerField({
  name,
  fullWidth = true,
  floatingLabelText,
  validate,
  shrinkLabel,
  allowNull = false,
  required = false,
  style,
  parse,
  format,
  formatOnBlur = false,
  fieldProps = {},
  step,
  inputProps,
  fieldNote,
  component = 'input',
  ...rest
}) {
  return (
    <Field
      name={name}
      validate={validate}
      allowNull={allowNull}
      formatOnBlur={formatOnBlur}
      step={step}
      parse={value => DateTime.fromJSDate(value).toISODate()}
      {...fieldProps}
      render={({ input, meta }) => {
        const date = (
          input.value?.length > 0
            ? DateTime.fromISO(input.value)
            : DateTime.now().startOf('day')
        ).toJSDate()
        const errorText =
          (meta.touched && meta.error) || (!meta.touched && meta.submitError)
            ? meta.error || meta.submitError
            : ''

        return (
          <FormControl
            fullWidth={fullWidth}
            style={style || { paddingTop: '40px' }}>
            <LabelField
              name={name}
              shrinkLabel={shrinkLabel}
              required={required}
              floatingLabelText={floatingLabelText}
              fieldNote={fieldNote}
            />
            <DatePicker id={name} {...rest} {...input} value={date} />
            <FormHelperText style={{ color: 'red' }}>
              {errorText}
            </FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}
