import { PolicyDto, PolicyLineDto } from '../dtos/InternalPolicyResponseDtos'
import { Element } from './Element'
import { PolicyUpdateRequestDto } from '../dtos/PolicyUpdateRequestDto'
import { PersonToInsure } from './PersonToInsure'
import { PolicyUpdateRequestConverter } from '../dtos/PolicyUpdateRequestConverter'
import { DateTime } from 'luxon'
import { NameMapping } from '../../global-state/ElementNameMappingContext'

export class PolicyUpdate {
  private _elements: Array<Element>

  private constructor(elements: Array<Element>) {
    this._elements = elements
  }

  public static for(policyDto: PolicyDto, elementNameMapping: NameMapping) {
    const elements = PolicyUpdate.createElements(
      policyDto.policyLines,
      elementNameMapping
    )
    return new PolicyUpdate(elements)
  }

  private static createElements(
    policyLinesDto: Array<PolicyLineDto>,
    elementNameMapping: NameMapping
  ): Array<Element> {
    return policyLinesDto.map(pl =>
      Element.from(pl, elementNameMapping[pl.productLineId])
    )
  }

  public removePersonFromElement(personId: string, elementName: string) {
    this.getElementByName(elementName).removePersonWithId(personId)
  }

  public removeDogFromElement(dogId: string, elementName: string) {
    this.getElementByName(elementName).removeInsuredEntityWithId(dogId)
  }

  public addPersonToElement(person: PersonToInsure, elementName: string) {
    this.getElementByName(elementName).addPerson(person)
  }

  public removeElement(elementName: string) {
    this._elements = this._elements.filter(el => el.name !== elementName)
  }

  public getElementByName(name: string) {
    const targetElement = this._elements.find(el => el.name === name)
    if (!targetElement) {
      throw new Error(
        `Can't find element with name ${name} to remove insured person from`
      )
    }
    return targetElement
  }

  public toUpdatePolicyRequest(): PolicyUpdateRequestDto {
    return PolicyUpdateRequestConverter.createFrom(this)
  }

  public toUpdatePolicyRequestEffectiveAt(
    effectiveDate: DateTime
  ): PolicyUpdateRequestDto {
    return PolicyUpdateRequestConverter.createFromEffectiveAt(
      this,
      effectiveDate
    )
  }

  get elements(): Array<Element> {
    return this._elements
  }
}
