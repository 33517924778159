import React from 'react'
import blueLogo from './logo-blue.svg'

const defaultStyle = {
  verticalAlign: 'middle'
}

export function ElementLogo({ style = {}, ...props }) {
  return (
    <img
      alt="Element"
      src={blueLogo}
      style={Object.assign({}, defaultStyle, style)}
      {...props}
    />
  )
}
