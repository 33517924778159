import * as React from 'react'
import Users from './Users'

export class UserListPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Users name="Element" pool="element" />
        <Users name="Externals" pool="externals" />
        <Users name="Claims Handlers" pool="claims-handlers" />
      </React.Fragment>
    )
  }
}
