import * as React from 'react'
import EmailIcon from '@material-ui/icons/Email'
import { FormattedMessage } from 'react-intl'

import IconButtonWithConfirmation from '../utils/IconButtonWithConfirmation'

export function UserListResendInvitationAction({ user, resendInvitation }) {
  if (user.status !== 'FORCE_CHANGE_PASSWORD') {
    return null
  }

  return (
    <FormattedMessage id="USER_MANAGEMENT.RESEND_INVITATION">
      {txt => (
        <IconButtonWithConfirmation
          confirmationTitle={txt}
          buttonCaption={txt}
          icon={EmailIcon}
          onOk={() => resendInvitation(user.username)}>
          <FormattedMessage
            id="USER_MANAGEMENT.RESEND_INVITATION_CONFIRMATION"
            tagName="div"
            values={{ user: <strong>{user.attributes.email}</strong> }}
          />
        </IconButtonWithConfirmation>
      )}
    </FormattedMessage>
  )
}
