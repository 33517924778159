import React, { useState } from 'react'
import { Layout } from 'antd'

import AppContent from './AppContent'
import AppMenu from './AppMenu'
import styles from './AppLayout.module.scss'

export default function AppLayout({ auth }) {
  const [open, setOpen] = useState(
    JSON.parse(window.localStorage.getItem('isLayoutSiderOpen') || 'true')
  )

  return (
    <Layout className={styles.layoutMain}>
      <Layout.Sider
        onCollapse={() => {
          window.localStorage.setItem('isLayoutSiderOpen', !open + '')
          setOpen(!open)
        }}
        collapsedWidth={80}
        collapsed={!open}
        theme="light"
        collapsible
        width={250}>
        <AppMenu auth={auth} />
      </Layout.Sider>
      <Layout className={styles.content}>
        <AppContent auth={auth} />
      </Layout>
    </Layout>
  )
}
