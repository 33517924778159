import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grow
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

const FullscreenDialog = props => (
  <Dialog
    data-testid="full-screen-dialog"
    open={props.open}
    fullScreen={true}
    TransitionComponent={Transition}>
    <DialogTitle>{props.title}</DialogTitle>

    <Divider />

    <DialogContent style={{ paddingTop: 8 }}>
      <div style={{ maxWidth: 1020, minWidth: 800, margin: 'auto' }}>
        {props.content}
        {props.children}
      </div>
    </DialogContent>

    {props.footer && <Divider />}

    {props.footer}

    <Divider />

    <DialogActions>{props.controls}</DialogActions>
  </Dialog>
)

export default withTheme(FullscreenDialog)

const Transition = React.forwardRef((props, ref) => (
  <Grow {...props} ref={ref} />
))
