import { useCallback } from 'react'
import { BackofficeAPI } from '@elementinsurance/cognito-auth'
import useService, { ServiceCallStatus } from '../utils/useService'

export const productService = new (class ProductService {
  loadAll() {
    return BackofficeAPI.get('/products?states=ALL')
  }

  loadOverview(partnerId, brandId, states = 'ALL') {
    const baseUrl = `/products/list?states=${states}`
    const params: string[] = []
    if (partnerId) {
      params.push(`partnerId=${partnerId}`)
    }
    if (brandId) {
      params.push(`brandId=${brandId}`)
    }

    const urlParams = params.join('&')
    const url = `${baseUrl}&${urlParams}`
    return BackofficeAPI.get(url)
  }

  load(productId, version) {
    if (version) {
      return BackofficeAPI.get(
        `/products/${productId}?requestedVersion=${version}`
      )
    } else {
      return BackofficeAPI.get(`/products/${productId}`)
    }
  }

  loadProductEvents(productId) {
    return BackofficeAPI.get(`/products/${productId}/events`)
  }

  loadLineIdToElementNameMap() {
    return BackofficeAPI.get('/products/lineid-to-elementname-map')
  }

  loadProductLines() {
    return BackofficeAPI.get('/productlines')
  }

  save(product) {
    if (product.id) {
      return BackofficeAPI.put(`/products/${product.id}`, product)
    } else {
      return BackofficeAPI.post('/products', product)
    }
  }

  promoteProductVersion(productId, eventId) {
    return BackofficeAPI.put(
      `/products/${productId}/versions/${eventId}/PROMOTE`,
      {}
    )
  }

  rerunTests(productId, eventId) {
    return BackofficeAPI.put(
      `/products/${productId}/versions/${eventId}/RERUN`,
      {}
    )
  }
})()

export function useProduct(
  productId: string,
  productVersion: string
): { productResponse: any; status: ServiceCallStatus; error: string | null } {
  const productServiceCallback = useCallback(
    () => productService.load(productId, productVersion),
    [productId, productVersion]
  )
  const { data, status, error } = useService(productServiceCallback)

  return { productResponse: data, status, error }
}
