import { PolicyDto } from '../../../../policy-update/dtos/InternalPolicyResponseDtos'
import { DateTime } from 'luxon'

export class InternalPolicy {
  private _coverStartDateTime: DateTime;
  [x: string]: any

  constructor(coverStartDateTime: DateTime) {
    this._coverStartDateTime = coverStartDateTime
  }

  public static for(response: PolicyDto) {
    const coverStartDateTime = DateTime.fromISO(response.coverage.coverStart, {
      zone: 'utc'
    }).setZone('local')
    const internalPolicy = new InternalPolicy(coverStartDateTime)
    return Object.assign(internalPolicy, response) as InternalPolicy
  }

  get coverStartDateTime(): DateTime {
    return this._coverStartDateTime
  }
}
