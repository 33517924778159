import React from 'react'
import { FormattedPremium } from '../../../utils/FormattedPremium'
import { getTranslationForKey } from '../../../i18n/getTranslationForKey'

export default function PolicyPremium({ policyLines }) {
  if (policyLinesArePaidMonthly(policyLines)) {
    const payment = {
      frequency: {
        unit: 'MONTH',
        period: 1
      },
      premium: sumPremiumOfAll({ policyLines })
    }

    return <PolicyLinePremium payment={payment} monthlyPaid />
  } else {
    return policyLines.map(pl => (
      <PolicyLinePremium key={pl.productLineId} payment={pl.payment} />
    ))
  }
}

export const PaymentInfo = ({ children }) => (
  <div style={{ textAlign: 'right' }}>{children}</div>
)

function PaymentData({ payment, noPaymentFrequency }) {
  const periodAddendumText =
    payment?.frequency?.period && payment.frequency.period !== 1
      ? '(' + payment.frequency.period + ')'
      : ''

  return (
    <>
      <strong>
        <FormattedPremium value={payment.premium} />
      </strong>
      {!noPaymentFrequency && (
        <div>
          <small>
            {periodAddendumText}
            {getTranslationForKey(
              `PAYMENT.FREQUENCY.UNIT.${payment.frequency.unit}`
            )}
          </small>
        </div>
      )}
    </>
  )
}

export function PolicyLinePremium({
  payment,
  noPaymentFrequency,
  monthlyPaid
}) {
  payment = payment || {
    frequency: {
      period: 1,
      unit: 'YEAR'
    }
  }

  return (
    <PaymentInfo>
      <PaymentData payment={payment} noPaymentFrequency={noPaymentFrequency} />
      {monthlyPaid && (
        <ExtraInfo>
          <div>
            <strong>
              <FormattedPremium value={payment.premium * 12} />
            </strong>
          </div>
          <div>
            <small>{getTranslationForKey('PAYMENT.FREQUENCY.UNIT.YEAR')}</small>
          </div>
        </ExtraInfo>
      )}
    </PaymentInfo>
  )
}

export const ExtraInfo = ({ children }) => <div>{children}</div>

function policyLinesArePaidMonthly(policyLines) {
  const policyLinesWithPaymentObject = policyLines.filter(pl =>
    paymentObjectPresent(pl.payment)
  )
  return (
    policyLinesWithPaymentObject.length > 0 &&
    policyLinesWithPaymentObject.every(
      pl => pl.payment.frequency.unit === 'MONTH'
    )
  )
}

function paymentObjectPresent(payment) {
  return (
    payment != null &&
    'frequency' in payment &&
    'unit' in payment.frequency &&
    'period' in payment.frequency &&
    'premium' in payment
  )
}

function sumPremiumOfAll({ policyLines }) {
  return policyLines
    .filter(pl => pl.payment)
    .map(pl => pl.payment.premium)
    .reduce((curr, val) => curr + val, 0)
}
