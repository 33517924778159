import React, { useState } from 'react'
import { Timeline } from 'react-event-timeline'
import PropTypes from 'prop-types'
import TransfersHistoryTimelineEvent from './TransfersHistoryTimelineEvent'

export default function TransfersHistoryTimeline({
  transfers,
  paymentMethod,
  policy,
  ...noneEventProps
}) {
  const [openEvents, setOpenEvents] = useState([])

  const handleEventOpened = newlyOpenedEvent => {
    let newOpenEvents = [...openEvents]
    newOpenEvents.push(newlyOpenedEvent)
    setOpenEvents(newOpenEvents)
  }

  const handleEventClosed = newlyClosedEvent => {
    let newOpenEvents = [...openEvents]
    newOpenEvents = newOpenEvents.filter(
      event => event.eventId !== newlyClosedEvent.eventId
    )
    setOpenEvents(newOpenEvents)
  }

  return (
    <div className="payment-history-timeline-wrapper" style={{ width: '85%' }}>
      <Timeline>
        {createPaymentEventDetails(transfers).map(transfer => (
          <TransfersHistoryTimelineEvent
            key={transfer.id}
            transfer={transfer}
            paymentMethod={paymentMethod}
            policy={policy}
            onEventOpened={handleEventOpened}
            onEventClosed={handleEventClosed}
            {...noneEventProps}
          />
        ))}
      </Timeline>
    </div>
  )
}

function createPaymentEventDetails(transfers) {
  return transfers.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt)
  })
}
TransfersHistoryTimeline.propTypes = {
  transfers: PropTypes.array.isRequired,
  paymentMethod: PropTypes.string.isRequired
}
