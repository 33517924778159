import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  coverEndIsInPast,
  coverStartIsInFuture,
  isCancelledInTheFuture,
  renderCancellationDate,
  renderCoverEnd,
  renderCoverStart
} from './helpers/PolicyCoverageUtils'
import Typography from '@material-ui/core/Typography'
import { Chip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { CancelPolicyButton } from './CancelPolicyButton'
import Copyable from '../../../utils/Copyable'
import CustomerContext from '../CustomerContext'
import EditableField from './EditableField'

export const PolicyStatusHeader = ({ policy, product, onExternalIdUpdate }) => {
  const variant = selectHeaderVariant(policy, product)
  const externalIdLabel = 'Externe Id:'
  const { isReadOnly } = useContext(CustomerContext)
  return (
    <StyledHeader>
      <StyledTypography variant="subtitle1" component="h3">
        <div>
          Id: <Copyable>{policy.id}</Copyable>
        </div>
        {policy.externalId && (
          <EditableField
            fieldValue={policy.externalId}
            fieldLabel={externalIdLabel}
            errorText="Externe ID darf nicht leer sein"
            isRequired
            onDone={onExternalIdUpdate}
            isReadOnly={isReadOnly}>
            {externalIdLabel} <Copyable>{policy.externalId}</Copyable>
          </EditableField>
        )}
      </StyledTypography>
      <h3 style={{ justifySelf: 'center' }}>{product.name}</h3>
      <StatusBox variant={variant} />
      <CoverageVisualization variant={variant} policy={policy} />
    </StyledHeader>
  )
}

PolicyStatusHeader.propTypes = {
  policy: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

const CoverageVisualization = ({ variant, policy }) => {
  switch (variant) {
    case 'TempExpired':
      return (
        <StyledCoverageTimeline warning>
          <StyledStart>
            <span>{renderCoverStart(policy)}</span>
            <CancelAtBeginButton policy={policy} />
          </StyledStart>
          <StyledEnd>{renderCoverEnd(policy)}</StyledEnd>
        </StyledCoverageTimeline>
      )
    case 'TempActive':
      return (
        <StyledCoverageTimeline active>
          <StyledStart>
            <span>{renderCoverStart(policy)}</span>
            <CancelAtBeginButton policy={policy} />
          </StyledStart>
          <StyledEnd>{renderCoverEnd(policy)}</StyledEnd>
        </StyledCoverageTimeline>
      )
    case 'Canceled':
      return (
        <StyledCoverageTimeline warning>
          <StyledStart>
            <span>{renderCoverStart(policy)}</span>
            <CancelAtBeginButton policy={policy} />
          </StyledStart>
          <StyledEnd>
            <CancelButton policy={policy} />
            {renderCoverEnd(policy)}
          </StyledEnd>
        </StyledCoverageTimeline>
      )
    case 'CanceledWithinBegin':
      return (
        <StyledCoverageTimeline warning>
          <StyledStart>{renderCoverStart(policy)}</StyledStart>
          <StyledEnd>{renderCoverEnd(policy)}</StyledEnd>
        </StyledCoverageTimeline>
      )
    case 'Future':
      return (
        <StyledCoverageTimeline>
          <StyledStart>
            <span>{renderCoverStart(policy)}</span>
            <CancelAtBeginButton policy={policy} />
          </StyledStart>
        </StyledCoverageTimeline>
      )
    case 'Active':
      return (
        <StyledCoverageTimeline active>
          <StyledStart>
            <span>{renderCoverStart(policy)}</span>
            <CancelAtBeginButton policy={policy} />
          </StyledStart>
          <StyledEnd>
            <CancelButton policy={policy} />
          </StyledEnd>
        </StyledCoverageTimeline>
      )
    case 'FutureNonRenewable':
    case 'TempFuture':
      return (
        <StyledCoverageTimeline>
          <StyledStart>
            <span>{renderCoverStart(policy)}</span>
            <CancelAtBeginButton policy={policy} />
          </StyledStart>
          <StyledEnd>{renderCoverEnd(policy)}</StyledEnd>
        </StyledCoverageTimeline>
      )
    case 'ActiveCanceledInTheFuture':
      return (
        <StyledCoverageTimeline active>
          <StyledStart>
            <span>{renderCoverStart(policy)}</span>
            <CancelAtBeginButton policy={policy} />
          </StyledStart>
          <StyledEnd>
            <CancelButton policy={policy} />
            <span>{renderCancellationDate(policy.events)}</span>
          </StyledEnd>
        </StyledCoverageTimeline>
      )
    case 'ActiveNonRenewable':
      return (
        <StyledCoverageTimeline active>
          <StyledStart>
            <span>{renderCoverStart(policy)}</span>
            <CancelAtBeginButton policy={policy} />
          </StyledStart>
          <StyledEnd>
            <span>{renderCoverEnd(policy)}</span>
            <CancelButton policy={policy} />
          </StyledEnd>
        </StyledCoverageTimeline>
      )
    default:
      return null
  }
}

function selectHeaderVariant(policy, product) {
  const isPolicyCancelable =
    product.productLines && product.productLines.every(pl => pl.cancelable)

  if (product.hasTemporaryProductLines && !isPolicyCancelable) {
    if (policy.state === 'ACTIVE' || policy.state === 'PENDING') {
      if (coverStartIsInFuture(policy)) {
        return 'TempFuture'
      } else if (coverEndIsInPast(policy)) {
        return 'TempExpired'
      } else {
        return 'TempActive'
      }
    } else if (policy.state === 'REVOKED') {
      return 'CanceledWithinBegin'
    }
  } else {
    if (policy.state === 'ACTIVE' || policy.state === 'PENDING') {
      if (coverStartIsInFuture(policy)) {
        if (!product.renewable) {
          return 'FutureNonRenewable'
        }
        return 'Future'
      } else if (coverEndIsInPast(policy)) {
        return 'TempExpired'
      } else {
        if (!product.renewable) {
          return 'ActiveNonRenewable'
        } else if (isCancelledInTheFuture(policy.events)) {
          return 'ActiveCanceledInTheFuture'
        } else {
          return 'Active'
        }
      }
    } else if (policy.state === 'CANCELED') {
      return 'Canceled'
    } else if (policy.state === 'REVOKED') {
      return 'CanceledWithinBegin'
    } else if (policy.state === 'PENDING_APPROVAL') {
      return 'PendingApproval'
    }
  }
  return null
}

export function CancelButton({ policy }) {
  const { isReadOnly } = React.useContext(CustomerContext)
  if (isCancelledInTheFuture(policy.events)) {
    return (
      <CancelPolicyButton
        caption="Vertrag aufheben"
        policy={policy}
        isReadOnly={isReadOnly}
        highlighted={true}
      />
    )
  }

  return (
    <CancelPolicyButton
      caption="Vertrag aufheben"
      policy={policy}
      isReadOnly={isReadOnly}
    />
  )
}

export function CancelAtBeginButton({ policy }) {
  const { isReadOnly } = React.useContext(CustomerContext)
  return (
    <CancelPolicyButton
      caption="Ab Beginn stornieren"
      cancelAtCoverStart={true}
      policy={policy}
      isReadOnly={isReadOnly}
    />
  )
}

export function StatusBox({ variant }) {
  const statusBoxContent = {
    TempActive: 'aktiv',
    TempExpired: 'abgelaufen',
    TempFuture: 'noch nicht aktiv',
    Active: 'aktiv',
    Canceled: 'aufgehoben',
    CanceledWithinBegin: 'storniert ab Beginn',
    Future: 'noch nicht aktiv',
    FutureNonRenewable: 'noch nicht aktiv',
    ActiveNonRenewable: 'aktiv',
    ActiveCanceledInTheFuture: 'aktiv, aufgehoben in der Zukunft',
    PendingApproval: 'Kundigung in Prüfung'
  }

  return (
    <Chip
      label={statusBoxContent[variant] || 'unbekannter Status'}
      variant="outlined"
      style={{ justifySelf: 'right' }}
    />
  )
}

export const StyledHeader = styled.div`
  background: ghostWhite;
  padding: 8px 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 16px;
  justify-items: stretch;
  align-items: stretch;
`

export const StyledTypography = styled(Typography)`
  grid-column: 1;
`

const StyledCoverageTimeline = styled.div`
  grid-column: 1 / span 3;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    width: 100%;
    border-bottom: 1px solid ${p => (p.active ? 'teal' : '#ccc')};
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
`
const StartEndDate = styled.div`
  background: ghostWhite;
  color: #000;
  font-size: 13px;
  position: absolute;
  z-index: 2;
  top: 50%;
`

export const StyledStart = styled(StartEndDate)`
  padding-right: 8px;
  left: ${p => (p.future ? '45%' : '0')};
  transform: translate(${p => (p.future ? '-45%' : '0')}, -50%);
`

export const StyledEnd = styled(StartEndDate)`
  padding-left: 8px;
  right: 0;
  text-align: right;
  transform: translate(0%, -50%);
`
