import { API, BackofficeAPI } from '@elementinsurance/cognito-auth'
import { errorHandler, notFoundErrorHandler } from '../utils/RestErrorHandler'
import { PaymentsService } from './PaymentsService.ts'
import { DateTime } from 'luxon'

export const customerService = new (class CustomerService {
  loadWithEvents(id) {
    return Promise.all([this.load(id), this.loadCustomerEvents(id)])
      .then(([customer, events]) => {
        customer.events = events
        return customer
      })
      .catch(errorHandler())
  }

  load(id) {
    return BackofficeAPI.get(`/customers/${id}`).catch(
      errorHandler({ 401: 'Unauthorized' }, { 404: 'Kunde nicht gefunden' })
    )
  }

  search({ query, policyId, testData, partnerId }) {
    const params = new URLSearchParams()

    if (policyId) {
      params.set('policyId', policyId)
    } else {
      const searchQuery = buildSearchQuery(testData, query, partnerId)
      params.set('query', searchQuery)
    }

    return BackofficeAPI.get(`/customers?${params.toString()}`).catch(
      errorHandler()
    )
  }

  async getPoliciesWithEvents(customerId) {
    let policies = await this.loadPolicies(customerId)
    // this uses the public policy response with elements instead of policy lines and less data

    if (policies) {
      await Promise.all(
        policies.map(async policy => {
          policy.events = await this.loadPolicyEvents(customerId, policy.id)

          policy.paymentHistory = await PaymentsService.loadPaymentsHistory(
            policy.id
          )

          // TODO switch later to use the one from payment history
          const paymentMethod =
            await PaymentsService.loadLatestPaymentMethodByPolicyId(policy.id) // always delivers a 200
          if (paymentMethod.paymentMethodId) {
            policy.paymentMethod = paymentMethod
          }
        })
      )
    }

    return policies
  }

  showClaims(policies) {
    return policies.length < 2
  }

  loadCustomerEvents(customerId) {
    return BackofficeAPI.get(`/customers/${customerId}/events`).catch(
      errorHandler({ 404: 'Historie für Kunden nicht gefunden' })
    )
  }

  loadPolicies(customerId) {
    const effectiveDate = DateTime.local().toUTC().toISO().toString()
    return BackofficeAPI.get(
      `/customers/${customerId}/policies?effectiveDate=${effectiveDate}`
    ).catch(notFoundErrorHandler({ 404: null }, []))
  }

  loadPolicyEvents(customerId, policyId) {
    return BackofficeAPI.get(
      `/customers/${customerId}/policies/${policyId}/events`
    ).catch(errorHandler())
  }

  addDocument(customerId, file, config) {
    const formData = new FormData()
    formData.append('file', file)

    // see https://github.com/spring-cloud/spring-cloud-netflix/blob/master/docs/src/main/asciidoc/spring-cloud-netflix.adoc#uploading-files-through-zuul
    // why we prefix the URL with /zuul
    return API.post(
      `/zuul/customers/${customerId}/documents`,
      formData,
      config
    ).catch(errorHandler())
  }

  getMessages(customerId) {
    return BackofficeAPI.get(`/customers/${customerId}/messages`).catch(
      errorHandler()
    )
  }

  addMessage(customerId, policyId, message) {
    return BackofficeAPI.post(
      `/customers/${customerId}/policies/${policyId}/messages`,
      message
    ).catch(errorHandler())
  }

  getCustomerEventTypes() {
    return BackofficeAPI.get('/customer-event-types').catch(errorHandler())
  }

  addGenericCustomerEvent(customerId, eventType) {
    return BackofficeAPI.post(`/customers/${customerId}/generic-events`, {
      eventType: eventType
    }).catch(errorHandler())
  }

  update(customerId, updateCustomerRequest) {
    return BackofficeAPI.put(`/customers/${customerId}`, updateCustomerRequest)
  }
})()

function buildSearchQuery(testData, query, partnerId) {
  let queryParams = `(testData:${testData})`

  if (query) {
    queryParams = `(${query}) AND ${queryParams}`
  }
  if (partnerId) {
    queryParams = `(partnerId:${partnerId}) AND ${queryParams}`
  }
  return queryParams
}
