import * as React from 'react'
import PropTypes from 'prop-types'
import { FORM_ERROR } from 'final-form'
import { FormattedMessage } from 'react-intl'

import { GlobalSnackbarService } from '../utils/GlobalSnackbarService'
import { UserService } from '../services/UserService'
import { UserListView } from './UserListView'
import { EditUserDialog } from './EditUserDialog'
import { NewUserDialog } from './NewUserDialog'

export default class Users extends React.Component {
  static propTypes = {
    filters: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    name: PropTypes.string.isRequired,
    newUserValuesHook: PropTypes.func,
    pool: PropTypes.string.isRequired
  }

  static defaultProps = {
    filters: {},
    newUserValuesHook: values => values
  }

  state = {
    currentUser: null,
    loading: false,
    users: [],
    editUserOpen: false,
    newUserOpen: false
  }

  componentDidMount() {
    this._load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.pool !== prevProps.pool) {
      this._load()
    }
  }

  handleEditUser = user => {
    this.setState({ currentUser: user, editUserOpen: true })
  }

  handleEditUserCancel = () => {
    this.setState({ currentUser: null, editUserOpen: false })
  }

  handleEditUserOk = values => {
    const { enabled, groups, mfaEnabled, user } = values
    const { username, email } = user

    Promise.all([
      this._updateEnabled(enabled, user.enabled, username),
      this._updateMfaEnabled(mfaEnabled, !!user.preferredMfaSetting, username),
      this._updateGroups(groups, user.groups, username, email)
    ]).then(ignored => {
      GlobalSnackbarService.emit(
        <FormattedMessage id="USER_MANAGEMENT.EDIT_USER_SUCCESS" />,
        'success'
      )
      this.setState({ currentUser: null, editUserOpen: false })
      this._load()
    })
  }

  handleNewUser = () => {
    this.setState({ newUserOpen: true })
  }

  handleNewUserCancel = () => {
    this.setState({ newUserOpen: false })
  }

  handleNewUserOk = values => {
    return UserService.create(
      this.props.pool,
      this.props.newUserValuesHook(values)
    )
      .then(data => {
        GlobalSnackbarService.emit(
          <FormattedMessage id="USER_MANAGEMENT.CREATE_USER_SUCCESS" />,
          'success'
        )
        this.setState({ newUserOpen: false })
        this._load()
      })
      .catch(error => {
        GlobalSnackbarService.emit(
          <FormattedMessage
            id="USER_MANAGEMENT.CREATE_USER_ERROR"
            values={{ message: error.message }}
          />,
          'error'
        )
        return { [FORM_ERROR]: error.message }
      })
  }

  handleResendInvitation = user => {
    return UserService.resendInvitation(this.props.pool, user)
      .then(data => {
        GlobalSnackbarService.emit(
          <FormattedMessage id="USER_MANAGEMENT.RESEND_INVITATION_SUCCESS" />,
          'success'
        )
      })
      .catch(error => {
        GlobalSnackbarService.emit(
          <FormattedMessage
            id="USER_MANAGEMENT.RESEND_INVITATION_ERROR"
            values={{ message: error.message }}
          />,
          'error'
        )
      })
  }

  _load() {
    this.setState({ loading: true, users: [] })

    UserService.loadAll(this.props.pool, this.props.filters)
      .then(users => {
        this.setState({ loading: false, users })
      })
      .catch(error => {
        GlobalSnackbarService.emit(
          <FormattedMessage
            id="USER_MANAGEMENT.LOAD_USERS_ERROR"
            values={{ message: error.message }}
          />,
          'error'
        )
        this.setState({ loading: false })
      })
  }

  _updateEnabled(enabled, currentEnabled, username) {
    if (!enabled && currentEnabled) {
      return UserService.disableUser(this.props.pool, username).catch(error => {
        GlobalSnackbarService.emit(
          <FormattedMessage
            id="USER_MANAGEMENT.DISABLE_USER_ERROR"
            values={{ message: error.message }}
          />,
          'error'
        )
      })
    }

    if (enabled && !currentEnabled) {
      return UserService.enableUser(this.props.pool, username).catch(error => {
        GlobalSnackbarService.emit(
          <FormattedMessage
            id="USER_MANAGEMENT.ENABLE_USER_ERROR"
            values={{ message: error.message }}
          />,
          'error'
        )
      })
    }

    return Promise.resolve({})
  }

  _updateGroups(groups, currentGroups, username, email) {
    return Promise.all(
      Object.keys(groups).map(group => {
        const targetValue = groups[group]

        // user is in group but should not be anymore
        if (!targetValue && currentGroups.indexOf(group) > -1) {
          return UserService.removeUserFromGroup(
            this.props.pool,
            group,
            username
          ).catch(error => {
            GlobalSnackbarService.emit(
              <FormattedMessage
                id="USER_MANAGEMENT.REMOVE_USER_FROM_GROUP"
                values={{ email, group, message: error.message }}
              />,
              'error'
            )
          })
        }

        // user is not in group but should be
        if (targetValue && currentGroups.indexOf(group) === -1) {
          return UserService.addUserToGroup(
            this.props.pool,
            group,
            username
          ).catch(error => {
            GlobalSnackbarService.emit(
              <FormattedMessage
                id="USER_MANAGEMENT.ADD_USER_TO_GROUP"
                values={{ email, group, message: error.message }}
              />,
              'error'
            )
          })
        }

        return Promise.resolve({})
      })
    )
  }

  _updateMfaEnabled(enabled, currentEnabled, username) {
    if (!enabled && currentEnabled) {
      return UserService.disableUserMfa(this.props.pool, username).catch(
        error => {
          GlobalSnackbarService.emit(
            <FormattedMessage
              id="USER_MANAGEMENT.DISABLE_MFA_ERROR"
              values={{ message: error.message }}
            />,
            'error'
          )
        }
      )
    }

    return Promise.resolve({})
  }

  render() {
    return (
      <React.Fragment>
        <UserListView
          name={this.props.name}
          isLoading={this.state.loading}
          users={this.state.users}
          onEditUser={this.handleEditUser}
          onNewUser={this.handleNewUser}
          onResendInvitation={this.handleResendInvitation}
        />
        {this.state.editUserOpen && (
          <EditUserDialog
            pool={this.props.pool}
            uuid={this.state.currentUser.username}
            onCancel={this.handleEditUserCancel}
            onOk={this.handleEditUserOk}
          />
        )}
        {this.state.newUserOpen && (
          <NewUserDialog
            onCancel={this.handleNewUserCancel}
            onOk={this.handleNewUserOk}
          />
        )}
      </React.Fragment>
    )
  }
}
