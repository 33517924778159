import React, { useRef } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { Box } from '@material-ui/core'
import PaymentSharedTransferService from './PaymentSharedTransferService'
import { PaymentTransferReferenceContext } from './PaymentTransferReferenceContext'
import { capitalizeFirstLetter, getTinyId } from './PaymentDetailsUtils'
import { formatCurrency, formatDate } from '../../../../utils/Formatters'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
      'font-size': '90%'
    },
    tooltipBoxPaddingTop: {
      'padding-top': '1rem'
    },
    underlineHover: {
      'text-decoration': 'underline'
    },
    sharedPolicyDetails: {
      'padding-left': '2.3em'
    }
  })
)

export const PaymentEventDetailsTimelineTransfer: React.FC<Props> = ({
  transfer,
  title = 'Überweisung:'
}) => {
  const [sharedTransfers, setSharedTransfers] = React.useState(
    new Array<PaymentTransferReferenceContext>()
  )

  React.useEffect(() => {
    const fetchData = async () => {
      const sharedTransferCollection =
        await PaymentSharedTransferService.createSharedTransferDetailsContext(
          transfer
        )

      if (sharedTransferCollection?.length) {
        setSharedTransfers(sharedTransferCollection)
      }
    }

    fetchData()
  }, [transfer])

  const classes = useStyles()
  const buttonRef = useRef(null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = () => {
    setAnchorEl(buttonRef.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const transferTitle = getTinyId(transfer.id)

  return (
    <div>
      <Typography variant="body2" color="initial">
        <span>{title}</span>&nbsp;
        <Link
          className={classes.underlineHover}
          onClick={handleClick}
          component="button"
          ref={buttonRef}
          color="initial"
          variant="inherit">
          {transferTitle}
        </Link>
      </Typography>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography className={classes.typography}>
          <Box>
            <Box>
              <b>Überweisung:</b>&nbsp;{transferTitle}
            </Box>
            <Box>
              Eingegangen am: {formatDate(transfer.events[0].transferEventDate)}
            </Box>
            <Box>Überwiesener Betrag: {formatCurrency(transfer.amount)}</Box>
            <Box>
              In Rechnung gestellter Betrag:{' '}
              {formatCurrency(transfer.amountCharged)}
            </Box>
          </Box>
          <Box className={classes.tooltipBoxPaddingTop}>
            <Box>
              <b>
                Überweisungsdaten ({capitalizeFirstLetter(transfer.provider)}):
              </b>
            </Box>
            <Box>Referenz: {transfer.metadata?.transferReference}</Box>
            <Box>Kontoinhaber: {transfer.metadata?.transferSenderName}</Box>
            <Box>IBAN: {transfer.metadata?.transferSenderIban}</Box>
          </Box>
          {createSharedTransferView(sharedTransfers, classes)}
        </Typography>
      </Popover>
    </div>
  )
}

function createSharedTransferView(
  sharedTransfers: Array<PaymentTransferReferenceContext>,
  classes
) {
  let sharedTransferView = <></>
  const sharedTransfersElements = sharedTransfers?.map((i, idx) => (
    <Box key={`${i.policyId}-${idx}`}>
      Police: {i.policyId}
      <ul className={classes.sharedPolicyDetails}>
        <li>
          Überweisungsnummer:&nbsp;
          {getTinyId(i.paymentTransferId)}
        </li>
        <li>bezahlt:&nbsp;{formatCurrency(i.amountCharged)}</li>
      </ul>
    </Box>
  ))

  if (sharedTransfersElements?.length > 0) {
    sharedTransferView = (
      <>
        <Box className={classes.tooltipBoxPaddingTop}>
          <b>Andere verbundene mit der Überweisung Police:</b>
        </Box>
        {sharedTransfersElements}
      </>
    )
  }

  return sharedTransferView
}

interface Props {
  transfer: any

  title?: string
}
