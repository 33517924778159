import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'

import { GlobalSnackbarService } from '../utils/GlobalSnackbarService'
import { SnackbarContentWithVariants } from './SnackbarContentWithVariants'

export class GlobalSnackbar extends React.Component {
  state = {
    open: false,
    messageInfo: {}
  }

  queue = []

  componentDidMount() {
    GlobalSnackbarService.addListener(this.emit)
  }

  emit = (message, variant) => {
    this.queue.push({
      message,
      variant:
        ['success', 'warning', 'error', 'info'].indexOf(variant) === -1
          ? 'info'
          : variant,
      key: new Date().getTime()
    })

    if (this.state.open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ open: false })
    } else {
      this.processQueue()
    }
  }

  processQueue = () => {
    if (this.queue.length > 0) {
      this.setState({
        messageInfo: this.queue.shift(),
        open: true
      })
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }

  handleExited = () => {
    this.processQueue()
  }

  render() {
    const { message, variant, key } = this.state.messageInfo

    return (
      <Snackbar
        key={key}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        TransitionProps={{ onExit: this.handleExited }}>
        <SnackbarContentWithVariants
          variant={variant}
          message={message}
          onClose={this.handleClose}
        />
      </Snackbar>
    )
  }
}
