import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import FileCopy from '@material-ui/icons/FileCopy'
import { GlobalSnackbarService } from './GlobalSnackbarService'

export default function Copyable({ children }) {
  if (React.isValidElement(children)) {
    console.error(
      'Cannot make a react element copyable to clipboard. Only text!'
    )
    return children
  }

  const handleCopy = e => {
    e.stopPropagation()
    navigator.clipboard.writeText(children)
    GlobalSnackbarService.emit('In die Zwischenablage kopiert', 'success')
  }

  return (
    <>
      {children}
      <IconButton size="small" onClick={handleCopy} style={{ marginLeft: 5 }}>
        <FileCopy style={{ fontSize: 14 }} />
      </IconButton>
    </>
  )
}
