import React from 'react'

import { DateTime } from 'luxon'
import 'react-day-picker/lib/style.css'
import TextField from '../TextField'
import * as Validators from '../../utils/Validators'

export default function NativeDayPickerField({
  name,
  floatingLabelText,
  validate,
  ...rest
}) {
  return (
    <TextField
      type="date"
      name={name}
      data-testid={name}
      floatingLabelText={floatingLabelText}
      {...rest}
      validate={Validators.compose(validate)}
      parse={inputValue =>
        inputValue && DateTime.fromISO(inputValue).isValid
          ? DateTime.fromISO(inputValue, { zone: 'local' }).toISODate()
          : inputValue
      }
      format={formValue =>
        formValue && DateTime.fromISO(formValue).isValid
          ? DateTime.fromISO(formValue).toLocal().toISODate()
          : formValue
      }
    />
  )
}
