import React from 'react'
import { formatCurrency, formatDate } from '../../../../../utils/Formatters'

import {
  capitalizeFirstLetter,
  getPaymentTransferLabel,
  getTinyId,
  getFirstEventByType
} from '../PaymentDetailsUtils'
import { TransfersCollapsibleTimelineEvent } from './TransfersCollapsibleTimelineEvent'
import green from '@material-ui/core/colors/green'
import PaymentSharedTransferService from '../PaymentSharedTransferService'
import LinearProgress from '@material-ui/core/LinearProgress'

export default function TransfersHistoryTimelineEvent({
  transfer,
  paymentMethod,
  policy,
  ...noneEventProps
}) {
  const style = { fontSize: 16 }

  const ComponentToRender = GenericPaymentHistoryTimelineTransaction

  return (
    <ComponentToRender
      transfer={transfer}
      paymentMethod={paymentMethod}
      policy={policy}
      label={getPaymentTransferLabel(transfer)}
      hideAttachmentUpload={true}
      titleStyle={style}
      subtitleStyle={style}
      iconStyle={{ fontSize: 12, height: 10 }}
      iconColor={green[700]}
      contentStyle={{ boxShadow: 'none', padding: 0, margin: 0 }}
      {...noneEventProps}
    />
  )
}

function GenericPaymentHistoryTimelineTransaction({
  transfer,
  paymentMethod,
  policy,
  ...rest
}) {
  return (
    <TransfersCollapsibleTimelineEvent
      transfer={transfer}
      paymentMethod={paymentMethod}
      icon="payments"
      {...rest}>
      <PaymentTransferContent transfer={transfer} policy={policy} />
    </TransfersCollapsibleTimelineEvent>
  )
}

function PaymentTransferContent({ transfer, policy }) {
  const [sharedTransfers, setSharedTransfers] = React.useState([])
  const [isLoading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchData = async () => {
      const sharedTransferCollection =
        await PaymentSharedTransferService.createSharedTransferDetailsContext(
          transfer
        )
      setLoading(false)
      if (sharedTransferCollection?.length > 0) {
        setSharedTransfers(sharedTransferCollection)
      }
    }

    fetchData()
  }, [transfer])

  const transferReceivedEvent =
    getFirstEventByType(transfer.events, 'PAYMENT_TRANSFER_RECEIVED') ||
    getFirstEventByType(transfer.events, 'PAYMENT_TRANSFER_SHARED')
  const transferReceivedOn = formatDate(transferReceivedEvent.createdAt)
  const metadata = transferReceivedEvent && transferReceivedEvent.metadata
  const { transferReference, transferSenderIban, transferSenderName } = metadata
  return (
    <>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        {transferReceivedOn && <li>Eingegangen am: {transferReceivedOn}</li>}
        <li>Überweisungsnummer: {getTinyId(transfer.id)}</li>
        <li>
          Police {policy.id}: {formatCurrency(transfer.amountCharged)} bezahlt
        </li>
      </ul>
      <ExtraAmount transfer={transfer} />
      <b>Überweisungsdaten ({capitalizeFirstLetter(transfer.provider)}):</b>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>Referenz: {transferReference || 'Unbekannt'}</li>
        {transferSenderName && <li>Kontoinhaber: {transferSenderName}</li>}
        {transferSenderIban && <li>IBAN: {transferSenderIban}</li>}
      </ul>
      {isLoading && <LinearProgress />}
      {!isLoading && <SharedTransfersList sharedTransfers={sharedTransfers} />}
    </>
  )
}

function SharedTransfersList({ sharedTransfers }) {
  return sharedTransfers && sharedTransfers.length > 0 ? (
    <>
      <b>Andere verbundene mit der Überweisung Police:</b>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        {sharedTransfers.map(sharedTransfer => (
          <li
            key={`${sharedTransfer.policyId}-${sharedTransfer.paymentTransferId}`}>
            Police: {sharedTransfer.policyId}
            <ul>
              <li>
                Überweisungsnummer:&nbsp;
                {getTinyId(sharedTransfer.paymentTransferId)}
              </li>
              <li>
                bezahlt:&nbsp;{formatCurrency(sharedTransfer.amountCharged)}
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </>
  ) : null
}

function ExtraAmount({ transfer }) {
  const amount = transfer.extraAmount || transfer.overchargedAmount

  return amount ? (
    <>
      <b>Rückzahlung (zusätzlich):</b>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>Zusätzlicher Betrag: {formatCurrency(amount)}</li>
      </ul>
    </>
  ) : null
}
