import { DateTime } from 'luxon'
import { PolicyUpdate } from '../entities/PolicyUpdate'
import { PolicyUpdateNetworkInterface } from '../boundaries/PolicyUpdateNetworkInterface'
import { PolicyUpdateUserInterface } from '../boundaries/PolicyUpdateUserInterface'
import { PolicyUpdatePersistenceInterface } from '../boundaries/PolicyUpdatePersistenceInterface'
import { UseCase } from './UseCase'

export type RemoveInsuredPersonInput = {
  customerId: string
  policyId: string
  productLineId: string
  removedInsuredPersonId: string
  effectiveDate: DateTime
}

export class RemoveInsuredPerson implements UseCase {
  constructor(
    private _networkInterface: PolicyUpdateNetworkInterface,
    private _userInterface: PolicyUpdateUserInterface,
    private _store: PolicyUpdatePersistenceInterface
  ) {}

  async execute(input: RemoveInsuredPersonInput) {
    try {
      const policyUpdate: PolicyUpdate = this._store.loadPolicyUpdate()

      policyUpdate.removePersonFromElement(
        input.removedInsuredPersonId,
        input.productLineId
      )

      const requestBody = policyUpdate.toUpdatePolicyRequestEffectiveAt(
        input.effectiveDate
      )

      await this._networkInterface.updateCustomersPolicy(
        input.customerId,
        input.policyId,
        requestBody
      )

      this._store.storePolicyUpdate(policyUpdate)

      this._userInterface.reloadCustomerView()
    } catch (e) {
      this._userInterface.showError(e)
    }
  }
}
