import React from 'react'
import grey from '@material-ui/core/colors/grey'
import { FormattedPremium, Money } from '../../../../utils/FormattedPremium'
import {
  CollapsibleTimelineEvent,
  SubTimeLineEvent
} from '../../../../utils/timeline/CollapsibleTimelineEvent'
import { DateTime } from 'luxon'
import { getTranslationForKey } from '../../../../i18n/getTranslationForKey'
import {
  formattedGermanDate,
  formattedGermanDateFromTimezone
} from '../../../../utils/Dates'
import { yellow } from '@material-ui/core/colors'
import { Divider, Button, Typography } from 'antd'
import { Timeline } from 'react-event-timeline'

import styles from './DraftTimelineEvent.module.scss'
import { Link } from 'react-router-dom'

import { TimelineEventApprovalComponent } from '../../../../utils/timeline/TimelineEventApprovalComponent'

const POLICY_MTA_DRAFT_DISCARDED_EVENT = 'POLICY_MTA_DRAFT_DISCARDED_EVENT'
const POLICY_MTA_DRAFT_EXECUTED_EVENT = 'POLICY_MTA_DRAFT_EXECUTED_EVENT'
// TODO: remove testData parameter, as soon as we will be sure that it's not needed for the MTA button
export default function PolicyTimelineEvent({
  event,
  testData,
  eventList,
  ...noneEventProps
}) {
  const style = { fontSize: 16 }

  // gregor: remove this once we know how to differentiate between even types from policies and claims service
  const isClaimServiceEvent = !!event.claimId

  const isFutureEvent = isEffectiveDateInFuture(event)

  const ComponentToRender = timelineEventComponent(
    event.eventType,
    isClaimServiceEvent
  )

  return (
    <ComponentToRender
      event={event}
      eventList={eventList}
      label={`${isFutureEvent ? 'Zukünftig: ' : ''} ${event.eventDescription}`}
      isFutureEvent={isFutureEvent}
      titleStyle={style}
      subtitleStyle={style}
      iconStyle={{ fontSize: 12, height: 10 }}
      iconColor={grey['700']}
      contentStyle={{ boxShadow: 'none', padding: 0, margin: 0 }}
      testData={testData}
      {...noneEventProps}
    />
  )
}

function timelineEventComponent(eventType, isClaimServiceEvent) {
  switch (eventType) {
    case 'POLICY_CREATED':
      return PolicyCreatedTimelineEvent
    case 'POLICY_CREATED_PENDING':
      return PolicyCreatedPendingTimelineEvent
    case 'POLICY_ACTIVATED':
      return PolicyActivatedTimelineEvent
    case 'MTA_OPTION_ADD':
    case 'MTA_CHANGE_LOCATION':
    case 'MTA_CHANGE_LIVING_SPACE':
    case 'MTA_CHANGE':
    case 'MTA_CHANGE_PREMIUM':
      return PolicyUpdatedTimelineEvent
    case 'MTA_CANCELLATION_RECEIVED':
      return PolicyCanceledTimelineEvent
    case 'MTA_CANCELLATION_NOT_PAYED':
      return PolicyRevokedTimelineEvent
    case 'PAYMENT_DETAILS_CHANGED':
      return PaymentDetailsChangedTimelineEvent
    case 'POLICY_RENEWED':
      return PolicyRenewedTimelineEvent
    case 'POLICY_RENEWAL_PENDING':
      return PolicyRenewalPendingTimelineEvent
    case 'CLAIM_CREATED':
      return ClaimCreatedTimelineEvent
    case 'CLAIM_CLOSED':
      return isClaimServiceEvent
        ? ClaimClosedTimelineEvent
        : GenericPolicyTimelineEvent
    case 'CLAIM_DECLINED':
      return ClaimDeclinedTimelineEvent
    case 'RESERVE_ALLOCATED':
      return ReserveAllocatedTimelineEvent
    case 'RESERVE_CHANGED':
      return ClaimReserveChangedTimelineEvent
    case 'CLAIM_3RD_PARTY_ADDED':
      return ClaimThirdPartyAddedTimelineEvent
    case 'CLAIM_PREMIUM_PAYMENT_CHECK_COMPLETED':
      return ClaimCheckCompletedTimelineEvent
    case 'CLAIM_MATERIAL_CHECK_COMPLETED':
      return ClaimCheckCompletedTimelineEvent
    case 'POLICY_COVERAGE_CHECK_COMPLETED':
      return ClaimCheckCompletedTimelineEvent
    case 'POLICY_EXTERNAL_ID_CHANGED':
      return PolicyExternalIdChanged
    case 'PAYMENT_CREATED':
      return PaymentCreatedTimelineEvent
    case 'CLAIM_REOPENED':
      return ClaimReopenedTimelineEvent
    case 'BONUS_CREATED':
      return BonusCreatedTimelineEvent
    case 'CREDITOR_CONFIRMATION':
    case 'OWNERSHIP_TRANSFER':
      return MustardYellowGenericTimelineEvent
    case 'POLICY_MTA_DRAFT_CREATED_EVENT':
      return MtaDraftTimelineEvent
    case 'SCHEDULED_CANCELLATION':
      return ScheduledCancellationEvent
    case 'POLICY_APPROVAL_REQUIRED':
      return ApprovalRequiredEvent
    case 'POLICY_APPROVAL_PROCESSED':
      return ApprovalProcessedTimelineEvent
    // We might not need this event in future
    case POLICY_MTA_DRAFT_DISCARDED_EVENT:
    case POLICY_MTA_DRAFT_EXECUTED_EVENT:
      return EmptyComponent
    default:
      return GenericPolicyTimelineEvent
  }
}

export function PolicyCreatedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="fiber_new"
      data-testid="policy-created-timeline-event"
      {...rest}>
      {event.documentsSigningDate && (
        <div>
          Vertragsunterzeichnung:{' '}
          {formattedGermanDate(event.documentsSigningDate)}
        </div>
      )}
      Prämie: <FormattedPremium value={event.premium} />
    </CollapsibleTimelineEvent>
  )
}

export function PolicyCreatedPendingTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent event={event} icon="assignment_late" {...rest}>
      {event.documentsSigningDate && (
        <div>
          Vertragsunterzeichnung:{' '}
          {formattedGermanDate(event.documentsSigningDate)}
        </div>
      )}
      Prämie: <FormattedPremium value={event.premium} />
    </CollapsibleTimelineEvent>
  )
}

export function PolicyActivatedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="assignment_turned_in"
      {...rest}
    />
  )
}

export function PolicyUpdatedTimelineEvent({
  event,
  attachments,
  testData,
  eventList,
  ...rest
}) {
  const { source, reason } = event
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="edit"
      attachments={attachments}
      data-testid="policy-updated-timeline-event"
      {...rest}>
      Prämie:{' '}
      <FormattedPremium
        value={
          event.type === 'PolicyUpdatedEventV2'
            ? event.quote.premium
            : event.premium
        }
      />
      {source && <AdditionalInfo labelKey="SOURCE" valueKey={source} />}
      {reason && <AdditionalInfo labelKey="REASON" valueKey={reason} />}
      {reason && reason === 'NO_CLAIM_DISCOUNT' && (
        <ShowNcdInfo eventData={event} eventList={eventList} />
      )}
    </CollapsibleTimelineEvent>
  )
}

const ShowNcdInfo = ({ eventData, eventList }) => {
  const getNonAssistanceLine = lines =>
    lines.find(line => line.productLineId !== 'boat-kasko-assistance')
  const newNoClaimDiscount =
    100 -
    parseInt(
      getNonAssistanceLine(eventData.quote.policyLines).attributes.noClaimsBonus
    )
  const pastEvent = eventList
    .filter(e => e.createdAt < eventData.createdAt)
    .find(e => e.policyLines || e.quote)
  const previousNoClaimDiscount = pastEvent.policyLines
    ? 100 -
      parseInt(
        getNonAssistanceLine(pastEvent.policyLines).attributes.noClaimsBonus
      )
    : 100 -
      parseInt(
        getNonAssistanceLine(pastEvent.quote.policyLines).attributes
          .noClaimsBonus
      )
  const numberOfClaims = getNonAssistanceLine(
    eventData.nonPriceRelevantPolicyData.nonPriceRelevantPolicyLineData
  ).claimsDuringRecalculation
  return (
    <>
      <div>
        <Typography.Text>
          {numberOfClaims} Schäden bis{' '}
          {formattedGermanDate(eventData.createdAt)}
        </Typography.Text>
      </div>
      <div>
        <Typography.Text>
          NCD war {previousNoClaimDiscount}% ist jetzt {newNoClaimDiscount}%
        </Typography.Text>
      </div>
    </>
  )
}

export function PolicyCanceledTimelineEvent({
  event,
  attachments,
  testData,
  ...rest
}) {
  event.effectiveDate = event.cancelDate
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="cancel"
      data-testid="policy-cancelled-timeline-event"
      {...rest}
      attachments={attachments}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>zum {formattedGermanDate(event.cancelDate)}</li>
        {event.cancellationRequestDate ? (
          <li>
            Angefordert am {formattedGermanDate(event.cancellationRequestDate)}
          </li>
        ) : (
          ''
        )}
        <li>
          Kündigungsquelle:{' '}
          <b>
            {getTranslationForKey(
              `TIMELINE_EVENT.CANCELLATION.SOURCE.${event.cancellationSource}`
            )}
          </b>
        </li>
        <li>
          Kündigungsgrund:{' '}
          <b>
            {getTranslationForKey(
              `TIMELINE_EVENT.CANCELLATION.REASON.${event.cancellationReason}`
            )}
          </b>
        </li>
        {event.newPolicyId ? (
          <li>
            Neu Policy ID: <b>{event.newPolicyId}</b>
          </li>
        ) : (
          ''
        )}
      </ul>
    </CollapsibleTimelineEvent>
  )
}

export function PolicyRevokedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent event={event} icon="cancel" {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>
          Kündigungsquelle:{' '}
          <b>
            {getTranslationForKey(
              `TIMELINE_EVENT.CANCELLATION.SOURCE.${event.cancellationSource}`
            )}
          </b>
        </li>
        <li>
          Kündigungsgrund :{' '}
          <b>
            {getTranslationForKey(
              `TIMELINE_EVENT.CANCELLATION.REASON.${event.cancellationReason}`
            )}
          </b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

export function PaymentDetailsChangedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="attach_money"
      data-testid="payment-details-changed-timeline-event"
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>{event.iban}</li>
        <li>Kontoinhaber: {event.accountHolder}</li>
        <li>SEPA-Mandats-Id: {event.sepaMandateId}</li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

const PolicyRenewalContent = ({ renewalDate }) => {
  return (
    <ul style={{ margin: 10, padding: '0 10px' }}>
      <li>Vertragsverlängerung zum: {formattedGermanDate(renewalDate)}</li>
    </ul>
  )
}

export function PolicyRenewedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="repeat"
      data-testid="policy-renewed-timeline-event"
      {...rest}>
      <PolicyRenewalContent renewalDate={event.renewalDate} />
    </CollapsibleTimelineEvent>
  )
}

export function ClaimCreatedTimelineEvent({ event, ...rest }) {
  const claimCreatedDateTime = formattedGermanDateFromTimezone(
    event.incidentDate,
    event.incidentDateTimezone
  )

  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="flag"
      hideAttachmentUpload={true}
      hideAttachments={true}
      data-testid="claim-created-timeline-event"
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <ClaimIdListItem event={event} />
        <li>
          Schadendatum: <br />
          <b>{claimCreatedDateTime} Uhr</b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

export function ClaimClosedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="outlined_flag"
      data-testid="claim-closed-timeline-event"
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>
          Schließungsgrund: <b>{event.closingReason}</b>
        </li>
        <ClaimIdListItem event={event} />
      </ul>
    </CollapsibleTimelineEvent>
  )
}

export function ClaimDeclinedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="outlined_flag"
      data-testid="claim-declined-timeline-event"
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <ClaimIdListItem event={event} />
      </ul>
    </CollapsibleTimelineEvent>
  )
}

export function ReserveAllocatedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="library_add"
      hideAttachmentUpload={true}
      data-testid="reserve-allocated-timeline-event"
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <ClaimIdListItem event={event} />
        <li>
          {event.reserveType === 'REPLACEMENT' ? 'Entschädigungs' : 'Kosten'}
          reserve von:
          <br />
          <b>
            <Money
              value={event.reserve.amount}
              currencyType={event.currencySymbol}
            />
          </b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

function ClaimReserveChangedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="library_add"
      hideAttachmentUpload={true}
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <ClaimIdListItem event={event} />
        <li>
          {event.reserveType === 'REPLACEMENT' ? 'Entschädigungs' : 'Kosten'}
          reserve
          <br /> geändert um:
          <br />
          <b>
            <Money
              value={event.amount.amount}
              currencyType={event.amount.currencyCode}
            />
          </b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

export function PaymentCreatedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="library_add"
      hideAttachmentUpload={true}
      data-testid="payment-created-timeline-event"
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <ClaimIdListItem event={event} />
        <li>
          {event.reserveType === 'REPLACEMENT' ? 'Entschädigungs' : 'Kosten'}
          zahlung von:
          <br />
          <b>
            <Money
              value={event.payment.amount}
              currencyType={event.currencyCode}
            />
          </b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

function ClaimReopenedTimelineEvent({ event, ...rest }) {
  const claimReopenDate = formattedGermanDateFromTimezone(
    event.claimReopenDate,
    event.incidentDateTimezone
  )

  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="library_add"
      hideAttachmentUpload={true}
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <ClaimIdListItem event={event} />

        <li>
          Geöffnet am: <b>{claimReopenDate}</b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

export function ClaimThirdPartyAddedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="library_add"
      hideAttachmentUpload={true}
      data-testid="claim-third-party-added-timeline-event"
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <ClaimIdListItem event={event} />
        <li>
          Name: <br />
          <b>
            {event.title ? event.title : ''} {event.firstName} {event.lastName}
          </b>
        </li>
        <li>
          Kontaktdaten: <br />
          Telefon: <b> {event.phone}</b>
          <br />
          e-Mail: <b>{event.email}</b>
        </li>

        {event.address && (
          <li>
            Adresse: <br />
            Straße:{' '}
            <b>
              {event.address.street} {event.address.houseNumber}
            </b>
            <br />
            Ort:{' '}
            <b>
              {event.address.postCode} {event.address.city}
            </b>
          </li>
        )}

        {(event.iban || event.accountHolder) && (
          <li>
            Auszahlungskonto: <br />
            IBAN: <b>{event.iban}</b>
            <br />
            Kontoinhaber: <b>{event.accountHolder}</b>
          </li>
        )}
      </ul>
    </CollapsibleTimelineEvent>
  )
}

function ClaimCheckCompletedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="playlist_add_check"
      hideAttachmentUpload={true}
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>
          {getTranslationForKey('CLAIMS.CHECK_RESULT')}:{' '}
          <b>
            {getTranslationForKey(
              `CLAIMS.CHECK_RESULT.${event.checkResult.status}`
            )}
          </b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

function PolicyExternalIdChanged({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="edit"
      hideAttachmentUpload={true}
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>
          Externe ID: <b>{event.externalId}</b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

function ClaimIdListItem({ event }) {
  return (
    <li>
      Schaden-Id: <br />
      <b>{event.claimId}</b>
      {event.externalClaimId && (
        <div>
          <b>{event.externalClaimId}</b>
        </div>
      )}
    </li>
  )
}

function BonusCreatedTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="flag"
      hideAttachmentUpload={true}
      {...rest}>
      {event.type === 'NoClaimBonusCreated' && (
        <ul style={{ margin: 10, padding: '0 10px' }}>
          <li>
            Schadensfreie Jahre
            <br />
            {event.claimFreeYears}
          </li>
          <li>
            Bonus Betrag
            <br />
            <b>
              <Money
                value={event.amount.amount}
                currencyType={event.amount.currencySymbol}
              />
            </b>
          </li>
        </ul>
      )}
    </CollapsibleTimelineEvent>
  )
}

function MustardYellowGenericTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="priority_high"
      openBubbleStyle={{ zIndex: 1, color: yellow[700] }}
      closedBubbleStyle={{ backgroundColor: yellow[700], color: 'white' }}
      openIconColor={yellow[700]}
      {...rest}
    />
  )
}

const MtaDraftCreatedTimeLineEvent = ({
  event,
  label,
  policyContainsAccidentLine,
  testData,
  ...rest
}) => {
  const { customerId, policyId, active, reason, source } = event

  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="edit"
      openBubbleStyle={{
        zIndex: 1,
        color: '#40A9FF',
        borderColor: '#40A9FF',
        borderStyle: 'dashed'
      }}
      closedBubbleStyle={{
        color: '#40A9FF',
        borderColor: '#40A9FF',
        borderStyle: 'dashed'
      }}
      labelStyle={{ color: '#1890FF' }}
      className={styles.draftCreatedEvent}
      hideAttachmentUpload
      displayEffectiveDate={false}
      label="Vertragsanpassungs Entwurf"
      {...rest}>
      <div>
        <Divider style={{ margin: '8px 0' }} />
        <AdditionalInfo labelKey="REASON" valueKey={reason} />
        <AdditionalInfo labelKey="SOURCE" valueKey={source} />
        {active && (
          <div style={{ textAlign: 'right' }}>
            <Link
              component={Button}
              type="primary"
              size="small"
              to={`/customers/${customerId}/policies/${policyId}/mta?isDraft=true&policyContainsAccidentLine=${policyContainsAccidentLine}&testData=${testData}`}
              style={{ margin: 0 }}>
              Vorschau
            </Link>
          </div>
        )}
      </div>
    </CollapsibleTimelineEvent>
  )
}

const MtaDraftSubTimeLineEvent = props => {
  const { event: draftCreatedEvent, eventList } = props
  const { draftId } = draftCreatedEvent
  const draftEndStateEvent = eventList.find(evt => {
    return (
      evt.draftId === draftId &&
      (evt.eventType === POLICY_MTA_DRAFT_EXECUTED_EVENT ||
        evt.eventType === POLICY_MTA_DRAFT_DISCARDED_EVENT)
    )
  })

  const { eventType, discardReason } = draftEndStateEvent
  const isDraftDiscarded = eventType === POLICY_MTA_DRAFT_DISCARDED_EVENT
  const isInternalDiscard = discardReason === 'AUTOMATIC_CHANGE'
  const cardLabel = isDraftDiscarded
    ? 'Vertragsanpassung abgelehnt'
    : 'Vertragsanpassung erfolgreich'

  if (isInternalDiscard) {
    return <EmptyComponent />
  }

  return (
    <CollapsibleTimelineEvent
      contentStyle={{ boxShadow: 'none' }}
      event={draftEndStateEvent}
      icon="edit"
      isSubTimeLine
      openBubbleStyle={{
        zIndex: 1,
        borderStyle: 'dashed',
        borderColor: '#BFBFBF',
        color: '#BFBFBF'
      }}
      closedBubbleStyle={{
        borderStyle: 'dashed',
        borderColor: '#BFBFBF',
        color: '#BFBFBF'
      }}
      label={cardLabel}
      hideAttachmentUpload
      displayEffectiveDate={false}>
      <Timeline className={styles.subTimeLineWrapper}>
        <DraftSubTimeLineEvent
          event={draftEndStateEvent}
          isDraftDiscarded={isDraftDiscarded}
          label={cardLabel}
        />
        <DraftSubTimeLineEvent
          event={draftCreatedEvent}
          label="Vertragsanpassungs Entwurf"
        />
      </Timeline>
    </CollapsibleTimelineEvent>
  )
}

export const PolicyRenewalPendingTimelineEvent = props => {
  const { event, hasPendingRenewal, isLatestEvent } = props
  const eventColor = isLatestEvent || hasPendingRenewal ? '#FFB74D' : '#BFBFBF'
  return (
    <CollapsibleTimelineEvent
      contentStyle={{ boxShadow: 'none' }}
      event={event}
      icon="repeat"
      data-testid="policy-renewal-pending-timeline-event"
      openBubbleStyle={{
        zIndex: 1,
        color: eventColor,
        borderColor: eventColor,
        borderStyle: 'dashed'
      }}
      closedBubbleStyle={{
        color: eventColor,
        borderColor: eventColor,
        borderStyle: 'dashed'
      }}
      labelStyle={{ color: eventColor }}
      className={styles.draftDiscardedEvent}
      hideAttachmentUpload
      displayEffectiveDate={false}
      label={event.eventDescription}>
      <PolicyRenewalContent renewalDate={event.renewalDate} />
    </CollapsibleTimelineEvent>
  )
}

const MtaDraftTimelineEvent = props => {
  const {
    event: { active }
  } = props
  const DraftTimeLineComponent = active
    ? MtaDraftCreatedTimeLineEvent
    : MtaDraftSubTimeLineEvent
  return <DraftTimeLineComponent {...props} />
}

function DraftSubTimeLineEvent({
  event,
  label,
  isDraftDiscarded = false,
  ...rest
}) {
  const { discardReason } = event
  return (
    <SubTimeLineEvent
      className={styles.draftDiscardedEvent}
      event={event}
      icon="edit"
      bubbleStyle={{
        zIndex: 1,
        borderStyle: 'dashed',
        borderColor: '#BFBFBF',
        color: '#BFBFBF'
      }}
      hideAttachmentUpload
      label={label}
      labelStyle={{ color: '#BFBFBF' }}
      contentStyle={{ boxShadow: 'none', backgroundColor: 'transparent' }}
      {...rest}>
      {isDraftDiscarded && (
        <div style={{ margin: '-8px' }}>
          <AdditionalInfo
            labelKey="REASON"
            valueKey={`DISCARD_REASON.${discardReason}`}
          />
        </div>
      )}
    </SubTimeLineEvent>
  )
}

export function ScheduledCancellationEvent({ event, ...rest }) {
  event.effectiveDate = event.cancellationDate
  const color = '#FFB74D'
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="schedule"
      data-testid="scheduled-cancellation-timeline-event"
      openBubbleStyle={{
        zIndex: 1,
        color: color,
        borderColor: color,
        borderStyle: 'dashed'
      }}
      closedBubbleStyle={{
        color: color,
        borderColor: color,
        borderStyle: 'dashed'
      }}
      label={event.eventDescription}
      labelStyle={{ color: color }}
      hideAttachmentUpload={true}
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>
          Plandatum: <b>{formattedGermanDate(event.scheduledDate)}</b>
        </li>
        <li>
          Status:{' '}
          <b>
            {getTranslationForKey(
              `TIMELINE_EVENT.CANCELLATION.STATUS.${event.cancellationStatus}`
            )}
          </b>
        </li>
        <li>
          Kündigungsquelle:{' '}
          <b>
            {getTranslationForKey(
              `TIMELINE_EVENT.CANCELLATION.SOURCE.${event.cancellationSource}`
            )}
          </b>
        </li>
        <li>
          Kündigungsgrund:{' '}
          <b>
            {getTranslationForKey(
              `TIMELINE_EVENT.CANCELLATION.REASON.${event.cancellationReason}`
            )}
          </b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

export const ApprovalRequiredEvent = props => {
  const { event, isLatestEvent, attachments } = props
  const color = isLatestEvent ? '#FFB74D' : '#BFBFBF'
  event.effectiveDate = event.createdAt
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="check_circle"
      data-testid="approval-required-timeline-event"
      openBubbleStyle={{
        zIndex: 1,
        color: color,
        borderColor: color,
        borderStyle: 'dashed'
      }}
      closedBubbleStyle={{
        color: color,
        borderColor: color,
        borderStyle: 'dashed'
      }}
      label={event.eventDescription}
      labelStyle={{ color: color }}
      hideAttachmentUpload={true}
      attachments={attachments}>
      <TimelineEventApprovalComponent props={props} />
    </CollapsibleTimelineEvent>
  )
}

export function ApprovalProcessedTimelineEvent({ event, ...rest }) {
  const outcome = event?.outcome

  rest.label =
    outcome === 'APPROVED' ? 'Freigabe erteilt' : 'Freigabe verweigert'

  event.effectiveDate = event.createdAt
  const icon = outcome === 'APPROVED' ? 'done_all' : 'clear'
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon={icon}
      data-testid="approval-processed-timeline-event"
      {...rest}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <li>
          Outcome: <b>{event.outcome}</b>
        </li>
      </ul>
    </CollapsibleTimelineEvent>
  )
}

// this is needed as Timeline expects react-comp, but we don't want anything to display
const EmptyComponent = () => <></>

const AdditionalInfo = ({ labelKey, valueKey }) => {
  return (
    <div>
      <Typography.Text>
        {
          <>
            {getTranslationForKey(labelKey)}
            {' : '}
            {getTranslationForKey(valueKey)}
          </>
        }
      </Typography.Text>
    </div>
  )
}

export function GenericPolicyTimelineEvent({ event, ...rest }) {
  return (
    <CollapsibleTimelineEvent
      event={event}
      icon="assignment_late"
      data-testid="generic-policy-timeline-event"
      {...rest}
    />
  )
}

function isEffectiveDateInFuture(event) {
  if (!event.effectiveDate) {
    return false
  }

  return (
    DateTime.local() <
    DateTime.fromISO(event.effectiveDate, { zone: 'utc' }).toLocal()
  )
}
