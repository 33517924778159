import * as React from 'react'
import { useMemo } from 'react'
import { useIsAuthorized, AccessRights } from '../auth/AuthorizationFilter'
import { BackofficeMicrofrontend } from '../utils/BackofficeMicrofrontend'

export default function CustomerPortals() {
  const userHasWriteRights = useIsAuthorized(AccessRights.CustomerPortalAdmin)

  const customProps = useMemo(
    () => ({
      userHasWriteRights
    }),
    [userHasWriteRights]
  )

  return (
    <BackofficeMicrofrontend
      name="customer-portal-config"
      prodUrl="https://bo-customer-portal-config.web.app"
      localUrl="http://localhost:3030"
      additionalCustomProps={customProps}
    />
  )
}
