import React from 'react'
import { AccessRights, useIsAuthorized } from '../../auth/AuthorizationFilter'
import CustomerContext from './CustomerContext'

const VODAFONE_PRIVATE_CYBER = '5b4e3063-9893-441c-910e-bf5e544d4f84'
const VODAFONE_PRIVATE_CYBER_GIGA_KOMBI = 'c247e213-bceb-483b-afc7-3b1e42938e11'

export function CustomerContextProvider({ policies, testData, children }) {
  const isInVodafoneGroupInsurance = policies.some(policy =>
    [VODAFONE_PRIVATE_CYBER, VODAFONE_PRIVATE_CYBER_GIGA_KOMBI].includes(
      policy.productId
    )
  )
  const hasProductionAccess = useIsAuthorized(AccessRights.ProductionCustomer)
  return (
    <CustomerContext.Provider
      value={{
        isReadOnly: !hasProductionAccess && !testData,
        isInVodafoneGroupInsurance
      }}>
      {children}
    </CustomerContext.Provider>
  )
}
