import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

export default class IconButtonWithConfirmation extends Component {
  static propTypes = {
    confirmationTitle: PropTypes.node.isRequired,
    buttonCaption: PropTypes.node.isRequired,
    buttonDisabled: PropTypes.bool,
    color: PropTypes.string,
    okButtonDisabled: PropTypes.bool,
    style: PropTypes.object,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    icon: PropTypes.elementType.isRequired
  }

  static defaultProps = {
    okButtonDisabled: false,
    color: 'secondary'
  }

  state = {
    open: false
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleCancel = () => {
    this.setState({ open: false })
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  handleOk = () => {
    this.setState({ open: false })
    this.props.onOk()
  }

  render() {
    return (
      <React.Fragment>
        <Dialog open={this.state.open} maxWidth="md">
          <DialogTitle>{this.props.confirmationTitle}</DialogTitle>

          <DialogContent style={{ paddingTop: 8 }}>
            {this.props.children}
          </DialogContent>

          <DialogActions>
            <Button
              name="cancelButton"
              label="Cancel"
              variant="contained"
              onClick={this.handleCancel}>
              Abbrechen
            </Button>
            <Button
              name="okButton"
              variant="contained"
              color="secondary"
              onClick={this.handleOk}
              disabled={this.props.okButtonDisabled}>
              Bestätigen
            </Button>
          </DialogActions>
        </Dialog>

        <Tooltip title={this.props.buttonCaption}>
          <IconButton
            aria-label={this.props.buttonCaption}
            color={this.props.color}
            disabled={this.props.buttonDisabled}
            onClick={this.handleOpen}
            style={this.props.style}>
            <this.props.icon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }
}
