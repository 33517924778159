import {
  InsuredEntityDto,
  InsuredPersonDto,
  PartDto,
  PolicyLineDto
} from '../dtos/InternalPolicyResponseDtos'
import { PersonToInsure } from './PersonToInsure'
import { InsuredEntity } from './InsuredEntity'

export class Element {
  constructor(
    readonly name: string,
    readonly attributes: object,
    readonly options?: ElementOption,
    private _personsToInsure?: Array<PersonToInsure>,
    private _insuredEntities?: Array<InsuredEntity>
  ) {}

  public static from(
    policyLineDto: PolicyLineDto,
    elementName: string
  ): Element {
    const name = elementName
    const attributes = policyLineDto.attributes
    const options = Element.createOptions(policyLineDto.options)
    const personsToInsure = policyLineDto.insuredPersons
      ? Element.createPersonsToInsure(policyLineDto.insuredPersons || undefined)
      : undefined
    const insuredEntities = policyLineDto.insuredEntities
      ? Element.createInsuredEntities(
          policyLineDto.insuredEntities || undefined
        )
      : undefined
    return new Element(
      name,
      attributes,
      options,
      personsToInsure,
      insuredEntities
    )
  }

  private static createOptions(optionsDto: Array<PartDto>) {
    const homeOptions = (
      optionId: string,
      enabled: boolean,
      deductible: number,
      coverageSum?: number
    ) =>
      ({
        glassOption: { enabled, attributes: { deductible } },
        elementaryDamageOption: { enabled, attributes: { deductible } },
        bikeOption: {
          enabled,
          attributes: { deductible, insuranceSum: coverageSum }
        }
      })[optionId]

    const options = {}

    optionsDto.forEach((option: PartDto) => {
      const optionName = option.id.split('Option')[0]
      options[optionName] = homeOptions(
        option.id,
        true,
        option.deductible,
        option.coverageSum
      )
    })

    return options
  }

  private static createInsuredEntities(
    insuredEntities: Array<InsuredEntityDto>
  ): Array<InsuredEntity> {
    if (!insuredEntities) {
      return []
    }
    return insuredEntities.map(insuredEntity => InsuredEntity.of(insuredEntity))
  }

  private static createPersonsToInsure(
    insuredPersonDtos: Array<InsuredPersonDto>
  ): Array<PersonToInsure> {
    if (!insuredPersonDtos) {
      return []
    }
    return insuredPersonDtos.map(insuredPersonDto =>
      PersonToInsure.of(insuredPersonDto)
    )
  }

  public removePersonWithId(id: string) {
    if (!this._personsToInsure) {
      throw new Error('Policy does not have insured Persons')
    }
    if (!this._personsToInsure.find(person => person.id === id)) {
      throw new Error(
        'Did not find an insured person with that id in this policy'
      )
    }

    this._personsToInsure = this._personsToInsure.filter(
      person => person.id !== id
    )
  }

  public removeInsuredEntityWithId(id: string) {
    if (!this._insuredEntities) {
      throw new Error('Policy does not have insured Entities')
    }
    if (!this._insuredEntities.find(dog => dog.id === id)) {
      throw new Error(
        'Did not find an insured entity with that id in this policy'
      )
    }
    this._insuredEntities = this._insuredEntities.filter(dog => dog.id !== id)
    Object.keys(this.attributes).forEach(key => {
      if (key === 'numberOfInsuredEntities') {
        this.attributes[key]--
      }
    })
  }

  public addPerson(person: PersonToInsure) {
    if (!this._personsToInsure)
      throw new Error('Policy should not contain insured persons')
    this._personsToInsure.push(person)
  }

  get personsToInsure(): Array<PersonToInsure> {
    return this._personsToInsure || []
  }

  get insuredEntities(): Array<InsuredEntity> {
    return this._insuredEntities || []
  }
}

type ElementOption = {
  [s: string]: ElementPolicyOption
}

type ElementPolicyOption = {
  enabled: boolean
  attributes: Map<string, object>
}
