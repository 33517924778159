import React from 'react'
import { Field } from 'react-final-form'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

// strict mode allows to discard the test-data in case is not a valid option
export default function SelectField({
  name,
  fullWidth = true,
  floatingLabelText,
  validate,
  required,
  children,
  parse,
  format,
  initialValue,
  allowedOptions,
  strict = false,
  ...rest
}) {
  return (
    <Field
      name={name}
      validate={validate}
      parse={parse}
      format={format}
      initialValue={initialValue}
      render={({ input, meta }) => {
        const errorText = meta.touched && meta.error ? meta.error : ''
        if (strict && !allowedOptions.includes(input.value)) {
          input = {
            ...input,
            value: ''
          }
        }
        return (
          <FormControl fullWidth={fullWidth}>
            <InputLabel
              htmlFor={name}
              required={required}
              shrink={rest.multiple}>
              {floatingLabelText}
            </InputLabel>
            <Select native {...rest} {...input} id={name}>
              <option value="" hidden disabled />
              {children}
            </Select>
            <FormHelperText style={{ color: 'red' }}>
              {errorText}
            </FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}
