import { InsuredPersonDto, PartDto } from '../dtos/InternalPolicyResponseDtos'
import {
  confirmationValueNames,
  insuredPersonPricingAttributeNames,
  insuredPersonAttributeNames
} from '../../product-lines/components/Accident/AccidentInsuredPersonPricingAttributes'
import pick from 'lodash/pick'

export class PersonToInsure {
  constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,

    readonly beneficiaryType: string,
    readonly beneficiaryFirstName?: string,
    readonly beneficiaryLastName?: string,
    readonly beneficiaryDateOfBirth?: string,

    readonly pricingAttributes?: PricingAttributes,
    readonly options?: PersonOptions,
    readonly configuration?: Configuration
  ) {}

  public static of(insuredPersonDto: InsuredPersonDto): PersonToInsure {
    const id = insuredPersonDto.id
    const firstName = insuredPersonDto.firstName
    const lastName = insuredPersonDto.lastName

    const beneficiaryType = insuredPersonDto.beneficiaryType
    const beneficiaryFirstName = insuredPersonDto.beneficiaryFirstName
    const beneficiaryLastName = insuredPersonDto.beneficiaryLastName
    const beneficiaryDateOfBirth =
      beneficiaryType === 'ELECTED' && insuredPersonDto.beneficiaryDateOfBirth
        ? insuredPersonDto.beneficiaryDateOfBirth
        : undefined

    const pricingAttributes = pick(insuredPersonDto, [
      ...insuredPersonPricingAttributeNames,
      ...confirmationValueNames,
      ...insuredPersonAttributeNames
    ])

    const options: PersonOptions = {}
    let configuration: Configuration | undefined = undefined

    const hasOptions =
      Array.isArray(insuredPersonDto.options) && insuredPersonDto.options.length

    if (hasOptions) {
      insuredPersonDto.options.forEach(
        (option: PartDto) => (options[option.id] = {})
      )
    }

    if (insuredPersonDto.partBeforeTargetPrice) {
      //has target pricing
      configuration = {
        premiumAdjustmentTargetPrice: insuredPersonDto.baseProduct.grossPremium
      } as Configuration

      if (hasOptions) {
        insuredPersonDto.options.forEach(option => {
          options[option.id] = {
            configuration: {
              premiumAdjustmentTargetPrice: option.grossPremium
            }
          }
        })
      }
    }

    return new PersonToInsure(
      id,
      firstName,
      lastName,
      beneficiaryType,
      beneficiaryType === 'ELECTED' ? beneficiaryFirstName : undefined,
      beneficiaryType === 'ELECTED' ? beneficiaryLastName : undefined,
      beneficiaryType === 'ELECTED' ? beneficiaryDateOfBirth : undefined,
      pricingAttributes,
      options,
      configuration
    )
  }
}

type PricingAttributes = {
  [s: string]: any
}

type PersonOptions = {
  [s: string]: any
}

type Configuration = {
  commission?: number
  premiumAdjustmentFactor?: number
  premiumAdjustmentFactors?: Array<PremiumAdjustmentFactor>
  premiumAdjustmentTargetPrice: number
}

type PremiumAdjustmentFactor = {
  name: string
  value: number
}
