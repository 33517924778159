import React from 'react'
import _ from 'lodash'

export default function PolicyLinesSummary({
  policyLines,
  previousPolicyLines = []
}) {
  if (!policyLines || !previousPolicyLines || previousPolicyLines.length === 0)
    return <div>Keine inhaltlichen Änderungen</div>

  if (policyLines.length > 1) {
    return (
      <div>
        {policyLines.map(pl => {
          const previousPolicyLine = findPolicyLineById(
            previousPolicyLines,
            pl.productLineId
          )
          return (
            <div key={pl.productLineId}>
              <h4>{pl.productLineId}</h4>
              <AttributesSummary
                policyLine={pl}
                previousPolicyLine={previousPolicyLine}
              />
              <InsuredPersonsSummary
                policyLine={pl}
                previousPolicyLine={previousPolicyLine}
              />
              <InsuredEntitiesSummary
                policyLine={pl}
                previousPolicyLine={previousPolicyLine}
              />
            </div>
          )
        })}
      </div>
    )
  } else {
    const policyLine = policyLines[0]
    const previousPolicyLine = findPolicyLineById(
      previousPolicyLines,
      policyLine.productLineId
    )
    return (
      <>
        <AttributesSummary
          policyLine={policyLine}
          previousPolicyLine={previousPolicyLine}
        />
        <InsuredPersonsSummary
          policyLine={policyLine}
          previousPolicyLine={previousPolicyLine}
        />
        <InsuredEntitiesSummary
          policyLine={policyLine}
          previousPolicyLine={previousPolicyLine}
        />
      </>
    )
  }
}

export function AttributesSummary({ policyLine, previousPolicyLine }) {
  const attributes = previousPolicyLine
    ? attributesDifference(policyLine.attributes, previousPolicyLine.attributes)
    : policyLine.attributes

  const attributeNames = Object.keys(attributes).sort()
  return (
    <ul style={{ margin: 10, padding: '0 10px' }}>
      {attributeNames.map(attrName => (
        <li key={attrName}>
          {attrName}: <b>{attributes[attrName]}</b>
        </li>
      ))}
    </ul>
  )
}

export function InsuredPersonsSummary({ policyLine, previousPolicyLine = {} }) {
  const insuredPersons = policyLine.insuredPersons
  const previousInsuredPersons = previousPolicyLine.insuredPersons

  if (!insuredPersons || !previousInsuredPersons) {
    return null
  }

  if (insuredPersons.length === 0 && previousInsuredPersons.length === 0) {
    return null
  }

  const insuredPersonWasRemoved =
    insuredPersons.length < previousInsuredPersons.length

  const modifiedInsuredPerson = insuredPersonWasRemoved
    ? _.differenceBy(previousInsuredPersons, insuredPersons, 'id')[0]
    : _.differenceBy(insuredPersons, previousInsuredPersons, 'id')[0]

  if (modifiedInsuredPerson === undefined) {
    return <div>Keine inhaltlichen Änderungen</div>
  }

  const label = insuredPersonWasRemoved
    ? 'Versicherte Person entfernt:'
    : 'Versicherte Person hinzugefügt:'

  return (
    <ul style={{ margin: 10, padding: '0 10px' }}>
      <li>
        <span>{label}</span>
        <br />
        <>
          {modifiedInsuredPerson.firstName +
            ' ' +
            modifiedInsuredPerson.lastName}
        </>
      </li>
    </ul>
  )
}

function InsuredEntitiesSummary({ policyLine, previousPolicyLine = {} }) {
  const insuredEntities = policyLine.insuredEntities
  const previousInsuredEntities = previousPolicyLine.insuredEntities

  if (!insuredEntities?.length || !previousInsuredEntities?.length) {
    return null
  }

  const uniqueIds = Array.from(
    new Set([
      ...insuredEntities.map(x => x.id),
      ...previousInsuredEntities.map(x => x.id)
    ])
  )

  return (
    <ul style={{ margin: 10, padding: '0 10px' }}>
      {uniqueIds.map(id => (
        <li key={id}>
          <InsuredEntityDiff
            id={id}
            insuredEntity={insuredEntities.find(x => x.id === id)}
            previousInsuredEntity={previousInsuredEntities.find(
              x => x.id === id
            )}
          />
        </li>
      ))}
    </ul>
  )
}

function InsuredEntityDiff({ id, insuredEntity, previousInsuredEntity }) {
  if (!insuredEntity) {
    return (
      <>
        <div>Versichertes Element entfernt</div>
        <div>id: {id}</div>
      </>
    )
  }

  if (!previousInsuredEntity) {
    return (
      <>
        <div>Versichertes Element hinzugefügt</div>
        <div>id: {id}</div>
      </>
    )
  }

  const changedAttributes = attributesDifference(
    insuredEntity,
    previousInsuredEntity
  )

  const changedAttributeCount = Object.keys(changedAttributes).length

  if (changedAttributeCount === 0) {
    return (
      <>
        <div>Versichertes Element unverändert</div>
        <div>id: {insuredEntity.id}</div>
      </>
    )
  }

  return (
    <>
      <div>Versichertes Element geändert</div>
      <div>id: {insuredEntity.id}</div>
      <ul>
        {Object.entries(changedAttributes).map(([key, value]) => (
          <li key={key}>
            {key}: {value}
          </li>
        ))}
      </ul>
    </>
  )
}

function attributesDifference(newAttrs, oldAttrs) {
  const newAttrList = Object.keys(newAttrs).map(name => ({
    name,
    value: newAttrs[name]
  }))
  const oldAttrList = Object.keys(oldAttrs).map(name => ({
    name,
    value: oldAttrs[name]
  }))

  const diff = _.differenceWith(newAttrList, oldAttrList, _.isEqual)

  return diff.reduce((attributes, attribute) => {
    attributes[attribute.name] = attribute.value
    return attributes
  }, {})
}

function findPolicyLineById(policyLines, productLineId) {
  return policyLines.filter(pl => pl.productLineId === productLineId)[0]
}
