import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * Prompt is a message to be shown before navigating away from a route.
 * @returns A function to set the prompt. Set `null` to remove the prompt.
 */
export function useSetPrompt() {
  const history = useHistory()
  // What if two microfrontends were changed and both want to set a prompt?
  // My opinion, UX should never allow us to have 2 blocking microfrontends
  const [prompt, setPrompt] = useState<string | null>(null)

  useEffect(() => {
    return history.block(() => prompt ?? undefined)
  }, [history, prompt])

  return setPrompt
}
