import { DateTime } from 'luxon'

export function formattedGermanDate(dateString: string): string {
  if (!dateString) return 'Unbekanntes Datum'
  return DateTime.fromISO(dateString, { zone: 'utc' }) // e.g. 05.11.2019
    .toLocal()
    .setLocale('de-DE')
    .toLocaleString({
      timeZone: 'Europe/Berlin',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
}

export function formattedGermanDateTime(dateString: string): string {
  if (!dateString) return 'Unbekanntes Datum'
  return formatDateTimeForGermany(DateTime.fromISO(dateString, { zone: 'utc' })) // e.g. 05.11.2019, 11:33
}

export function formattedGermanFullDate(dateString: string): string {
  if (!dateString) return 'Unbekanntes Datum'
  return DateTime.fromISO(dateString, { zone: 'utc' })
    .toLocal()
    .setLocale('de-DE')
    .toLocaleString(DateTime.DATE_FULL) // e.g. 5. November 2019
}

export function formattedGermanDateFromTimezone(
  dateString: string,
  timezone: string
): string {
  return DateTime.fromISO(dateString)
    .setZone(timezone)
    .setLocale('de-DE')
    .toLocaleString(DateTime.DATETIME_MED) // e.g. 5. Nov. 2019, 11:32
}

export function formatDateTimeForGermany(dateTime: DateTime) {
  return dateTime.toLocal().setLocale('de-DE').toLocaleString({
    timeZone: 'Europe/Berlin', // e.g. 05.11.2019 11:32
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}
