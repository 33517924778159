import React, { useMemo } from 'react'
import { useIsAuthorized, AccessRights } from '../auth/AuthorizationFilter'
import { BackofficeMicrofrontend } from '../utils/BackofficeMicrofrontend'

export default function CustomerPortalsFaqs({
  match: {
    params: { productId }
  }
}) {
  const userHasWriteRights = useIsAuthorized(AccessRights.CustomerPortalAdmin)

  const customProps = useMemo(
    () => ({
      userHasWriteRights,
      productId
    }),
    [userHasWriteRights, productId]
  )

  return (
    <BackofficeMicrofrontend
      name="customer-portal-faqs"
      prodUrl="https://bo-customer-portal-faqs.web.app"
      localUrl="http://localhost:3032"
      additionalCustomProps={customProps}
    />
  )
}
