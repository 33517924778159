import * as React from 'react'
import store from '../../../CustomerStore'
import Button from '@material-ui/core/Button'
import { UpdatePaymentDetailsDialog } from './UpdatePaymentDetailsDialog'

interface Props {
  customer: any
  policy: any
  isReadOnly: boolean
}

export const UpdatePaymentDetailsButton: React.FC<Props> = ({
  customer,
  policy,
  isReadOnly
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClose = success => {
    setOpen(false)

    if (success) {
      store.loadCustomer(customer.id)
    }
  }

  return (
    <React.Fragment>
      <Button
        color="primary"
        variant="outlined"
        disabled={
          policy.state === 'CANCELED' ||
          policy.state === 'REVOKED' ||
          isReadOnly
        }
        style={{ marginBottom: 16, marginRight: 16 }}
        onClick={() => setOpen(true)}>
        Zahlungsdaten ändern
      </Button>

      {open && (
        <UpdatePaymentDetailsDialog
          key={policy.id}
          policy={policy}
          isTest={customer.testData}
          onClose={handleClose}
        />
      )}
    </React.Fragment>
  )
}
