import React from 'react'

type CustomerContext = {
  isReadOnly: boolean
  isInVodafoneGroupInsurance: boolean
}

export default React.createContext<CustomerContext>({
  isReadOnly: false,
  isInVodafoneGroupInsurance: false
})
