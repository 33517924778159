import React from 'react'
import { Timeline } from 'react-event-timeline'
import PropTypes from 'prop-types'
import CustomerTimelineEvent from './CustomerTimelineEvent'
import {
  combineEventsWithDocuments,
  combineEventsWithEventDescription
} from '../../../utils/timeline/TimelineUtils'

export default function CustomerTimeline({
  events,
  documents,
  eventTypes,
  ...otherProps
}) {
  const eventsWithDocuments = combineEventsWithDocuments(events, documents)
  const eventsWithDocumentsAndEventDescription =
    combineEventsWithEventDescription(eventsWithDocuments, eventTypes)

  return (
    <Timeline>
      {eventsWithDocumentsAndEventDescription.map(event => (
        <CustomerTimelineEvent
          key={event.eventId}
          event={event}
          attachments={event.attachments}
          {...otherProps}
        />
      ))}
    </Timeline>
  )
}

CustomerTimeline.propTypes = {
  events: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  eventTypes: PropTypes.array.isRequired
}
