export enum ScheduleStatuses {
  READY = 'READY',
  INPROGRESS = 'INPROGRESS',
  DUNNING = 'DUNNING',
  PROCESSED = 'PROCESSED',
  ONHOLD = 'ONHOLD'
}

export type SchedulePaymentType = {
  policyId: string
  idempotentId: string
  paymentMethod: 'CREDIT_CARD'
  status: ScheduleStatuses
  amount: number
  testData: boolean
  dueDate: string
  metadata: {
    originalDueDate: string
    accountingEndDate: string
    accountingStartDate: string
    coverStartDate: string
    previousDueDate: string
    firstPremium: boolean
    transactionId: string
    partnerId: string
    transactionDate: string
    lastTransactionStatus: string
    cancellationDate: string
    adjustedPaymentNo: string
    isMta: boolean
    mtaEffectiveDate: string
    mtaType: string
  }
}
