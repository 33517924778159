import React from 'react'
import { Field } from 'react-final-form'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import LabelField from './LabelField'

export default function TextField({
  name,
  fullWidth = true,
  floatingLabelText,
  validate,
  shrinkLabel,
  allowNull = false,
  required = false,
  style,
  parse,
  format,
  formatOnBlur = false,
  fieldProps = {},
  type,
  step,
  inputProps,
  fieldNote,
  ...rest
}) {
  return (
    <Field
      name={name}
      validate={validate}
      allowNull={allowNull}
      parse={parse}
      format={format}
      formatOnBlur={formatOnBlur}
      type={type}
      step={step}
      {...fieldProps}
      render={({ input, meta }) => {
        const errorText =
          (meta.touched && meta.error) || (!meta.touched && meta.submitError)
            ? meta.error || meta.submitError
            : ''
        return (
          <FormControl fullWidth={fullWidth} style={{ ...style }}>
            <LabelField
              name={name}
              shrinkLabel={shrinkLabel}
              required={required}
              floatingLabelText={floatingLabelText}
              fieldNote={fieldNote}
            />
            <Input
              {...rest}
              {...input}
              inputProps={inputProps}
              value={input.value || ''}
              autoComplete="nope"
              id={name}
            />
            <FormHelperText style={{ color: 'red' }}>
              {errorText}
            </FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}
