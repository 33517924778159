export default {
  element: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_ABO3OdIAY',
    userPoolWebClientId: '4cjeo6al2jj3a0i3o4lpql4ub5',
    mandatorySignIn: true
  },
  backofficeE2eTest: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_7qYztVkou',
    userPoolWebClientId: '7q95rhr42ntusdamth17mgf50p',
    mandatorySignIn: true
  },
  externals: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_3gctBrN3t',
    userPoolWebClientId: '2gip6sdfkiou0gukfh01l7o65j',
    mandatorySignIn: true
  },
  'claims-handlers': {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_VYCJnLI2F',
    userPoolWebClientId: '35v5fikchpp8bgbqiv3345phrh',
    mandatorySignIn: true
  }
}
