import React, { useRef } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TimelineConfirmedTransactionWithExtraFundsContext } from './TimelineConfirmedTransactionWithExtraFundsContext'
import { formatCurrency, formatDateTime } from '../../../../utils/Formatters'
import { Link, Box, Popover, Typography } from '@material-ui/core'
import { getFirstEventByType, getTinyId } from './PaymentDetailsUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
      'font-size': '90%'
    },
    underlineHover: {
      'text-decoration': 'underline'
    },
    noMarginBottom: {
      'margin-bottom': '0'
    }
  })
)

export const PaymentEventDetailsTimelineConfirmedTransactionWithExtraFunds: React.FC<
  Props
> = ({ context }) => {
  const classes = useStyles()

  const transfer = context.transfer
  const refundIban = context.refundIban

  const refundAmount = transfer.refundAmount
  const refundDetailsAreProvided = refundIban !== undefined

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const buttonRef = useRef(null)
  const handleClick = () => {
    setAnchorEl(buttonRef.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Typography variant="body2" color="initial">
        <span>
          Überbezahlter Betrag:&nbsp;
          {formatCurrency(Math.abs(refundAmount))}
        </span>
        &nbsp;
        <div>
          <Link
            className={classes.underlineHover}
            onClick={handleClick}
            component="button"
            ref={buttonRef}
            color="initial"
            variant="inherit">
            Rückzahlung Angaben
          </Link>
        </div>
      </Typography>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {refundDetailsAreProvided &&
          getRefundedTransferContent(
            transfer,
            refundIban,
            refundAmount,
            classes
          )}
        {!refundDetailsAreProvided &&
          getRefundDataRequiredContent(transfer, refundAmount, classes)}
      </Popover>
    </div>
  )
}

function getRefundedTransferContent(
  transfer,
  refundIban,
  refundAmount,
  classes
) {
  const refundByFallbackEvent = getFirstEventByType(
    transfer.events,
    'PAYMENT_TRANSFER_REFUND_BY_FALLBACK'
  )

  const formattedRefundAmount = formatCurrency(Math.abs(refundAmount))

  return (
    <Typography className={classes.typography}>
      <Box>Rückzahlung von {formattedRefundAmount} via TIA:</Box>
      <ul>
        <li key={`${transfer.id}-${refundByFallbackEvent.createdAt}`}>
          {formatDateTime(refundByFallbackEvent.createdAt)}
        </li>
        <li key={`${transfer.id}-${refundIban}`}>
          Lastschrift, IBAN: {refundIban}
        </li>
      </ul>
      {getSharedDataContent(transfer, refundAmount, classes)}
    </Typography>
  )
}

function getRefundDataRequiredContent(transfer, refundAmount, classes) {
  const refundDataRequiredEvent = getFirstEventByType(
    transfer.events,
    'PAYMENT_TRANSFER_REFUND_DATA_REQUIRED'
  )

  const formattedRefundAmount = formatCurrency(Math.abs(refundAmount))

  return (
    <Typography className={classes.typography}>
      <Box>IBAN für Rückzahlung von {formattedRefundAmount} angefordert:</Box>
      <ul>
        <li key={`${transfer.id}-${refundDataRequiredEvent.createdAt}`}>
          {formatDateTime(refundDataRequiredEvent.createdAt)}
        </li>
      </ul>
      {getSharedDataContent(transfer, refundAmount, classes)}
    </Typography>
  )
}

function getSharedDataContent(transfer, refundAmount, classes) {
  const amountCharged = transfer.amountCharged
  const amountAfterRefund = amountCharged + refundAmount

  return (
    <>
      <Box>
        <span>
          <strong>Verbundene Überweisung:</strong>
        </span>
        &nbsp;
        <span>{getTinyId(transfer.id)}</span>
      </Box>
      <ul className={classes.noMarginBottom}>
        <li key={`${transfer.id}-${amountCharged}`}>
          Überwiesener Betrag: {formatCurrency(amountCharged)}
        </li>
        <li>
          Gezahlter Betrag nach Rückzahlung:&nbsp;
          {formatCurrency(amountAfterRefund)}
        </li>
      </ul>
    </>
  )
}

interface Props {
  context: TimelineConfirmedTransactionWithExtraFundsContext
}
