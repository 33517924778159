import { InsuredEntityDto } from '../dtos/InternalPolicyResponseDtos'
import { insuredPetOptionNames } from './InsuredPetPricingAttributes'
import _ from 'lodash'

export class InsuredEntity {
  constructor(
    readonly id: string,
    readonly type: string,
    readonly attributes?: Attributes
  ) {}

  public static of(insuredEntityDto: InsuredEntityDto): InsuredEntity {
    const attributes = _.pick(insuredEntityDto, [...insuredPetOptionNames])

    return new InsuredEntity(
      insuredEntityDto.id,
      insuredEntityDto.type,
      attributes
    )
  }
}

type Attributes = {
  [s: string]: any
}
