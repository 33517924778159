import React from 'react'
import { Timeline } from 'react-event-timeline'
import PaymentHistoryTimelineEvent from './PaymentHistoryTimelineEvent'
import PropTypes from 'prop-types'

export default function PaymentHistoryTimeline({
  customerId,
  policyId,
  transactions,
  paymentMethods,
  paymentTransfers,
  documents,
  ...noneEventProps
}) {
  return (
    <div className="payment-history-timeline-wrapper">
      <Timeline>
        {transactions.map(transaction => (
          <PaymentHistoryTimelineEvent
            customerId={customerId}
            policyId={policyId}
            key={transaction.id}
            transaction={transaction}
            paymentMethods={paymentMethods}
            paymentTransfers={paymentTransfers}
            documents={documents}
            {...noneEventProps}
          />
        ))}
      </Timeline>
    </div>
  )
}

PaymentHistoryTimeline.propTypes = {
  transactions: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array.isRequired
}
