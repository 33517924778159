import { blue, red, green, grey, common } from '@material-ui/core/colors'
import { formattedGermanDateTime } from '../../../../utils/Dates.ts'
import { formatCurrency } from '../../../../utils/Formatters'
import { PaymentMethods } from './PaymentMethodConst'
import PayPalSvg from './PayPalSvg'
import formatStringByPattern from 'format-string-by-pattern'

export const mask = value => {
  return `${value === undefined ? 'Unbekannt' : '**** ' + value}`
}

const SUCCESS_EVENTS = [
  'PAYMENT_TRANSACTION_CONFIRMED',
  'PAYMENT_TRANSACTION_CONFIRMED_WITH_EXTRA_FUNDS',
  'PAYMENT_TRANSACTION_DISPUTE_WON'
]

export const getPaymentTransactionLabel = transaction => {
  const lastTransactionEvent = getLastEvent(transaction)
  const amount = transaction.amount

  const lastPaidBalance =
    getLatestTransactionInsufficientEvent(transaction)?.collectedAmount || 0

  const labelForPayoutEvent = event => {
    if (event.returnedAmount) {
      return `Rückzahlung ${formatCurrency(
        Math.abs(event.returnedAmount)
      )} von ${formatCurrency(amount)}`
    }
    return `${formatCurrency(Math.abs(amount))}`
  }

  switch (lastTransactionEvent.eventType) {
    case 'PAYMENT_TRANSACTION_REFUND_DATA_REQUIRED':
    case 'PAYMENT_TRANSACTION_REFUND_INITIATED':
    case 'PAYMENT_TRANSACTION_REFUND_BY_FALLBACK_INITIATED':
    case 'PAYMENT_TRANSACTION_REFUND_PENDING':
    case 'PAYMENT_TRANSACTION_REFUND_CONFIRMED':
      return `Rückzahlung ${formatCurrency(Math.abs(transaction.refundAmount))}`
    case 'PAYMENT_TRANSACTION_TRANSFER_CHARGING':
    case 'PAYMENT_TRANSACTION_TRANSFER_FUNDS_INSUFFICIENT':
    case 'PAYMENT_TRANSACTION_OUTSTANDING':
    case 'PAYMENT_TRANSACTION_OVERDUE':
      return lastPaidBalance !== 0
        ? `Bezahlt ${formatCurrency(lastPaidBalance)} von ${formatCurrency(
            amount
          )}`
        : `${formatCurrency(Math.abs(amount))}`
    case 'PAYMENT_TRANSACTION_CONFIRMED':
    case 'PAYMENT_TRANSACTION_CONFIRMED_WITH_EXTRA_FUNDS':
    case 'PAYMENT_TRANSACTION_DISPUTE_FUNDS_REINSTATED':
    case 'PAYMENT_TRANSACTION_DISPUTE_WARNING_CLOSED':
    case 'PAYMENT_TRANSACTION_DISPUTE_WON':
      return `Bezahlt ${formatCurrency(amount)}`
    case 'PAYMENT_TRANSACTION_DISPUTE_OPENED':
    case 'PAYMENT_TRANSACTION_DISPUTE_FUNDS_WITHDRAWN':
    case 'PAYMENT_TRANSACTION_DISPUTE_UPDATED':
    case 'PAYMENT_TRANSACTION_DISPUTE_LOST':
      return `Rückbuchung von ${formatCurrency(amount)}`
    case 'PAYMENT_TRANSACTION_PAYOUT_PENDING':
    case 'PAYMENT_TRANSACTION_PAYOUT_CONFIRMED':
      return labelForPayoutEvent(lastTransactionEvent)
    case 'PAYMENT_TRANSACTION_CANCELLED':
      return `Zahlung stoniert ${formatCurrency(Math.abs(amount))}`
    default:
      return `${formatCurrency(Math.abs(amount))}`
  }
}

export const getPaymentTransferLabel = transfer => {
  const type = transfer.latestEventType

  switch (type) {
    case 'PAYMENT_TRANSFER_REFUND_DATA_REQUIRED':
      return `IBAN für Rückzahlung von ${formatCurrency(
        Math.abs(transfer.refundAmount)
      )} angefordert`
    case 'PAYMENT_TRANSFER_REFUND_BY_FALLBACK':
      return `Rückzahlung ${formatCurrency(
        Math.abs(transfer.refundAmount)
      )} von ${formatCurrency(transfer.amount)} über TIA`
    case 'PAYMENT_TRANSFER_REFUNDING':
    case 'PAYMENT_TRANSFER_REFUNDED':
      return `Rückzahlung ${formatCurrency(
        Math.abs(transfer.refundAmount)
      )} von ${formatCurrency(transfer.amount)}`
    default:
      return `${formatCurrency(Math.abs(transfer.amount))}`
  }
}

const getLatestTransactionInsufficientEvent = transaction => {
  const insufficientEvents = getEventsByType(
    transaction.events,
    'PAYMENT_TRANSACTION_TRANSFER_FUNDS_INSUFFICIENT'
  )
  return (
    insufficientEvents &&
    insufficientEvents.length > 0 &&
    insufficientEvents.reverse()[0]
  )
}

export const mapPaymentMethodIcon = type => {
  switch (type) {
    case PaymentMethods.CREDIT_CARD:
      return 'credit_card'
    case PaymentMethods.DIRECT_DEBIT:
      return 'account_balance'
    case PaymentMethods.INVOICE:
      return 'receipt_long'
    case PaymentMethods.MANUAL_TRANSFER:
      return 'compare_arrows'
    case PaymentMethods.EXTERNAL_COLLECTION:
      return 'assessment'
    case PaymentMethods.PAYPAL:
      return <PayPalSvg />
    default:
      return 'assignment_late'
  }
}

export const mapPaymentType = type => {
  switch (type) {
    case PaymentMethods.CREDIT_CARD:
      return 'Kreditkarte'
    case PaymentMethods.DIRECT_DEBIT:
      return 'SEPA-Lastschrift'
    case PaymentMethods.INVOICE:
      return 'Rechnung'
    case PaymentMethods.MANUAL_TRANSFER:
      return 'Zahlungsüberweisung'
    case PaymentMethods.EXTERNAL_COLLECTION:
      return 'Externe Zahlung'
    case PaymentMethods.PAYPAL:
      return 'PayPal'
    default:
      return ''
  }
}

export const filterPaymentMethod = (paymentMethodId, paymentMethods) => {
  return paymentMethods.find(p => p.id === paymentMethodId)
}

export const filterPaymentTransfer = (paymentTransferId, paymentTransfers) => {
  return paymentTransfers.find(p => p.id === paymentTransferId)
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const getTransactionDate = paymentTransaction => {
  let firstEvent = paymentTransaction.events[0]
  return formattedGermanDateTime(firstEvent.transactionDate)
}

export const getLastEvent = paymentTransaction => {
  return paymentTransaction.events[paymentTransaction.events.length - 1]
}

export const getTransactionIcon = paymentTransaction => {
  let lastEventType = getLastEvent(paymentTransaction).eventType
  const DEFAULT_ICON_COLOR = common['white'],
    DEFAULT_BACKGROUND_COLOR = common['white']

  const overdue = paymentTransaction.eventTypes.includes(
    'PAYMENT_TRANSACTION_OVERDUE'
  )
  // In case there was overdue and the payment is not paid yet

  switch (lastEventType) {
    // Successful transactions
    case 'PAYMENT_TRANSACTION_CONFIRMED':
    case 'PAYMENT_TRANSACTION_DISPUTE_WON':
    case 'PAYMENT_TRANSACTION_CONFIRMED_WITH_EXTRA_FUNDS':
    case 'PAYMENT_TRANSACTION_DISPUTE_RESOLVED_FOR_SELLER':
    case 'PAYMENT_TRANSACTION_CONFIRMED_WITH_MANUAL_TRANSFER':
      return {
        icon: 'done',
        color: DEFAULT_ICON_COLOR,
        backgroundColor: green[700]
      }
    case 'PAYMENT_TRANSACTION_REFUND_CONFIRMED':
      return {
        icon: 'replay',
        color: DEFAULT_ICON_COLOR,
        backgroundColor: green[700]
      }
    // Failed transactions
    case 'PAYMENT_TRANSACTION_PAYMENT_METHOD_CHANGED':
      return {
        icon: 'notification_important',
        color: DEFAULT_ICON_COLOR,
        backgroundColor: red[700]
      }
    case 'PAYMENT_TRANSACTION_FAILED':
    case 'PAYMENT_TRANSACTION_BOUNCED':
    case 'PAYMENT_TRANSACTION_DISPUTE_LOST':
    case 'PAYMENT_TRANSACTION_DISPUTE_RESOLVED_FOR_BUYER':
    case 'PAYMENT_TRANSACTION_DISPUTE_REFUNDED':
      return {
        icon: 'clear',
        color: DEFAULT_ICON_COLOR,
        backgroundColor: red[700]
      }
    case 'PAYMENT_TRANSACTION_REFUND_NOT_APPLICABLE':
    case 'PAYMENT_TRANSACTION_REFUND_FAILED':
      return {
        icon: 'replay',
        color: DEFAULT_ICON_COLOR,
        backgroundColor: red[700]
      }
    // Pending transactions
    case 'PAYMENT_TRANSACTION_CREATED':
    case 'PAYMENT_TRANSACTION_IDEMPOTENTID_ADDED':
    case 'PAYMENT_TRANSACTION_PENDING':
      return {
        icon: 'timer',
        color: DEFAULT_ICON_COLOR,
        backgroundColor: blue[700]
      }

    case 'PAYMENT_TRANSACTION_MANUAL_TRANSFER_FUNDS_INSUFFICIENT':
      if (overdue) break

      return {
        icon: 'notification_important',
        color: DEFAULT_ICON_COLOR,
        backgroundColor: red[700]
      }

    case 'PAYMENT_TRANSACTION_TRANSFER_FUNDS_INSUFFICIENT':
    case 'PAYMENT_TRANSACTION_TRANSFER_CHARGING':
    case 'PAYMENT_TRANSACTION_OUTSTANDING':
      if (overdue) break
      return {
        icon: 'notification_important',
        color: DEFAULT_ICON_COLOR,
        backgroundColor: blue[700]
      }
    // Other transactions
    case 'PAYMENT_TRANSACTION_REFUND_INITIATED':
    case 'PAYMENT_TRANSACTION_REFUND_BY_FALLBACK_INITIATED':
    case 'PAYMENT_TRANSACTION_REFUND_PENDING':
      return {
        icon: 'replay',
        color: grey[700],
        backgroundColor: DEFAULT_BACKGROUND_COLOR
      }
    case 'PAYMENT_TRANSACTION_PAYOUT_PENDING':
    case 'PAYMENT_TRANSACTION_PAYOUT_CONFIRMED':
      return {
        icon: 'payments',
        color: green[700],
        backgroundColor: DEFAULT_BACKGROUND_COLOR
      }
    case 'PAYMENT_TRANSACTION_CANCELLED':
      return {
        icon: 'money_off_csred',
        color: grey[700],
        backgroundColor: DEFAULT_BACKGROUND_COLOR
      }
    case 'PAYMENT_TRANSACTION_REFUND_DATA_REQUIRED':
      return {
        icon: 'forward_to_inbox',
        color: red[700],
        backgroundColor: DEFAULT_BACKGROUND_COLOR
      }
  }

  // In case there was overdue and the payment is not paid yet
  if (overdue) {
    return {
      icon: 'timer_off',
      color: DEFAULT_ICON_COLOR,
      backgroundColor: red[700]
    }
  }

  return {
    icon: 'assignment_late',
    color: grey[700],
    backgroundColor: DEFAULT_BACKGROUND_COLOR
  }
}

export const getTinyId = id => {
  const parts = id.split('-')
  return parts[0]
}

const getEventsByType = (events, type) => {
  return events.filter(event => event.eventType === type)
}

export const getFirstEventByType = (events, type) => {
  return getEventsByType(events, type)[0]
}

export const getInvoiceNumber = transaction => {
  return (
    transaction &&
    transaction.events &&
    transaction.events[0] &&
    transaction.events[0].idempotentId
  )
}

export const mapTransactionType = transactionType => {
  switch (transactionType) {
    case 'PAYMENT_TRANSACTION_PENDING':
    case 'PAYMENT_TRANSACTION_IDEMPOTENTID_ADDED':
    case 'PAYMENT_TRANSACTION_REFUND_PENDING':
    case 'PAYMENT_TRANSACTION_PAYOUT_PENDING':
      return 'Ausstehend'
    case 'PAYMENT_TRANSACTION_CONFIRMED':
    case 'PAYMENT_TRANSACTION_REFUND_CONFIRMED':
    case 'PAYMENT_TRANSACTION_PAYOUT_CONFIRMED':
      return 'Bestätigt'
    case 'PAYMENT_TRANSACTION_FAILED':
    case 'PAYMENT_TRANSACTION_REFUND_FAILED':
    case 'PAYMENT_TRANSACTION_BOUNCED':
      return 'Gescheitert'
    case 'PAYMENT_TRANSACTION_TRANSFER_FUNDS_INSUFFICIENT':
    case 'PAYMENT_TRANSACTION_OUTSTANDING':
      return 'Offener'
    case 'PAYMENT_TRANSACTION_REFUND_INITIATED':
    case 'PAYMENT_TRANSACTION_REFUND_BY_FALLBACK_INITIATED':
      return 'Initiiert'
    case 'PAYMENT_TRANSACTION_REFUND_DATA_REQUIRED':
      return 'Rückzahlungsdetails angefragt'
    case 'PAYMENT_TRANSACTION_DISPUTE_FUNDS_WITHDRAWN':
      return 'Abgehobenes Geld'
    case 'PAYMENT_TRANSACTION_DISPUTE_UPDATED':
      return 'Aktualisiert'
    case 'PAYMENT_TRANSACTION_DISPUTE_FUNDS_REINSTATED':
      return 'Wiedereinsetzung der Mittel'
    case 'PAYMENT_TRANSACTION_DISPUTE_WON':
      return 'Gewonnen'
    case 'PAYMENT_TRANSACTION_DISPUTE_LOST':
      return 'Verloren'
    case 'PAYMENT_TRANSACTION_DISPUTE_WARNING_CLOSED':
      return 'Geschlossen'
    default:
      return 'Erstellt'
  }
}

export const ibanFieldMask = anyString => {
  const firstTwoChar = anyString.replace(/\^[A-Z]{2}/g, '')
  return formatStringByPattern(
    'AA XXXX XXXX XXXX XXXX XXXX XXXX XX',
    firstTwoChar
  )
}
