import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, FormSpy } from 'react-final-form'
import LinearProgress from '@material-ui/core/LinearProgress'
import { FormattedMessage } from 'react-intl'

import { UserService } from '../services/UserService'
import { GlobalSnackbarService } from '../utils/GlobalSnackbarService'
import ToggleField from '../components/ToggleField'
import { Typography } from '@material-ui/core'
import { Col, Divider, Row } from 'antd'

export class EditUserDialog extends React.Component {
  state = {
    initialValues: {},
    loading: true
  }

  componentDidMount() {
    UserService.loadUser(this.props.pool, this.props.uuid)
      .then(user => {
        return UserService.loadGroups(this.props.pool)
          .then(groups => {
            const groupData = groups.reduce(
              (acc, group) => {
                acc.initialValuesGroups[group.groupName] =
                  user.groups.indexOf(group.groupName) > -1
                acc.groupsDescriptions[group.groupName] = group.description
                return acc
              },
              { initialValuesGroups: {}, groupsDescriptions: {} }
            )

            this.setState({
              initialValues: {
                groups: groupData.initialValuesGroups,
                groupsDescriptions: groupData.groupsDescriptions,
                enabled: user.enabled,
                mfaEnabled: !!user.preferredMfaSetting,
                user
              },
              loading: false
            })
          })
          .catch(error => {
            GlobalSnackbarService.emit(
              <FormattedMessage
                id="USER_MANAGEMENT.LOAD_GROUPS_ERROR"
                values={{ message: error.message }}
              />,
              'error'
            )
            this.props.onCancel()
          })
      })
      .catch(error => {
        GlobalSnackbarService.emit(
          <FormattedMessage
            id="USER_MANAGEMENT.LOAD_USER_ERROR"
            values={{ message: error.message }}
          />,
          'error'
        )
        this.props.onCancel()
      })
  }

  render() {
    const { onCancel, onOk } = this.props
    const { initialValues } = this.state

    return (
      <Form
        initialValues={initialValues}
        onSubmit={onOk}
        subscription={{}}
        render={({ handleSubmit }) => (
          <Dialog open={true} fullWidth={true} maxWidth="xl">
            <form onSubmit={handleSubmit}>
              <DialogTitle>
                <Typography variant="h5">
                  <FormattedMessage id="USER_MANAGEMENT.EDIT_USER" />
                  {!this.state.loading && initialValues.user.attributes && (
                    <Typography variant="h6">
                      {initialValues.user.attributes.givenName}{' '}
                      {initialValues.user.attributes.familyName}
                    </Typography>
                  )}
                </Typography>
              </DialogTitle>

              <DialogContent style={{ paddingTop: 8 }}>
                {this.state.loading && <LinearProgress />}

                {!this.state.loading && (
                  <React.Fragment>
                    <Typography variant="h6" style={{ marginBottom: '10px' }}>
                      <FormattedMessage id="USER_MANAGEMENT.GROUPS" />
                    </Typography>
                    <Row gutter={[16, 16]}>
                      {Object.keys(initialValues.groups).map(key => (
                        <Col key={key} span={8}>
                          <React.Fragment key={key}>
                            <ToggleField
                              key={key}
                              name={`groups.${key}`}
                              label={key}
                            />
                            <Typography
                              variant="subtitle2"
                              style={{ fontStyle: 'italic' }}>
                              {initialValues.groupsDescriptions[key]}
                            </Typography>
                            <Divider />
                          </React.Fragment>
                        </Col>
                      ))}
                    </Row>
                    {!Object.keys(initialValues.groups).length && (
                      <FormattedMessage
                        id="NO_NOUN_AVAILABLE"
                        tagName="p"
                        values={{
                          noun: <FormattedMessage id="USER_MANAGEMENT.GROUPS" />
                        }}
                      />
                    )}
                    <Typography variant="h6">
                      <FormattedMessage id="SETTINGS" />
                    </Typography>
                    <ToggleField
                      name="enabled"
                      label={<FormattedMessage id="ENABLED" />}
                    />
                    <ToggleField
                      name="mfaEnabled"
                      label={<FormattedMessage id="MFA_ENABLED" />}
                    />
                  </React.Fragment>
                )}
              </DialogContent>

              <DialogActions>
                <Button variant="contained" onClick={onCancel}>
                  <FormattedMessage id="CANCEL" />
                </Button>
                <FormSpy
                  subscription={{
                    hasValidationErrors: true,
                    dirtySinceLastSubmit: true,
                    pristine: true,
                    submitting: true,
                    submitFailed: true
                  }}>
                  {({
                    submitting,
                    hasValidationErrors,
                    pristine,
                    dirtySinceLastSubmit,
                    submitFailed
                  }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        submitting ||
                        pristine ||
                        hasValidationErrors ||
                        (submitFailed && !dirtySinceLastSubmit)
                      }>
                      <FormattedMessage id="SAVE" />
                    </Button>
                  )}
                </FormSpy>
              </DialogActions>
            </form>
          </Dialog>
        )}
      />
    )
  }
}

EditUserDialog.propTypes = {
  pool: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired
}
