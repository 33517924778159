import React from 'react'
import { LabeledContent } from './LabeledContent'
import { formattedGermanFullDate } from '../../utils/Dates'

export default function CustomerTypeSpecificFields({ customer }) {
  const Component =
    customer.type === 'LEGAL_ENTITY' ? LegalEntityFields : NatualPersonFields
  return <Component customer={customer} />
}

function NatualPersonFields({ customer }) {
  return (
    <div>
      <h4>Person</h4>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridColumnGap: 10
        }}>
        <LabeledContent label="Titel" value={customer.title} />
        <LabeledContent label="Vorname" value={customer.firstName} />
        <LabeledContent label="Nachname" value={customer.lastName} />
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridColumnGap: 10
        }}>
        <LabeledContent
          label="Geschlecht"
          value={displayGender(customer.gender)}
        />
        <LabeledContent
          label="Geburtsdatum"
          value={formattedGermanFullDate(customer.birthDate)}
        />
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridColumnGap: 10
        }}>
        <LabeledContent label="Nationalität" value={customer.nationality} />
        <LabeledContent
          label="Art des Berufs"
          value={(customer.occupation || {}).type}
        />
        <LabeledContent
          label="Arbeitgeber"
          value={(customer.occupation || {}).employer}
        />
      </div>
    </div>
  )
}

function displayGender(gender) {
  switch (gender) {
    case 'MALE':
      return 'männlich'
    case 'FEMALE':
      return 'weiblich'
    case 'DIVERSE':
      return 'divers'
    case 'PREFER_NOT_TO_MENTION':
      return 'keine angabe'
    case 'UNKNOWN':
      return 'unbekannt'
    case 'NOT_APPLICABLE':
      return 'nicht zutreffend'
    default:
      return '-'
  }
}

function LegalEntityFields({ customer }) {
  return (
    <div>
      <h4>Firma</h4>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridColumnGap: 10
        }}>
        <LabeledContent label="Name" value={customer.name} />
        <LabeledContent label="Steuernummer" value={customer.taxId} />
      </div>
    </div>
  )
}
