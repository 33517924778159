import * as React from 'react'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import * as _ from 'lodash'

export function SortableTableBody({
  columns,
  data,
  handleClick,
  idField,
  isLoading,
  isSelectable,
  isSelected,
  page,
  rowsPerPage,
  withPagination
}) {
  const sliceArgs = withPagination
    ? [page * rowsPerPage, page * rowsPerPage + rowsPerPage]
    : []

  if (!data) {
    data = []
  }

  return (
    <TableBody>
      {data.slice(...sliceArgs).map(n => {
        const id = _.get(n, idField)
        const selected = isSelected(id)
        return (
          <TableRow
            hover
            onClick={event => isSelectable && handleClick(event, id)}
            role="checkbox"
            aria-checked={selected}
            tabIndex={-1}
            key={id}
            selected={selected}>
            {isSelectable && (
              <TableCell padding="checkbox">
                <Checkbox checked={selected} />
              </TableCell>
            )}
            {columns.map(col => (
              <TableCell
                key={`${col.id}-${id}`}
                align={col.numeric ? 'right' : 'left'}
                style={{
                  overflow: 'auto',
                  wordWrap: 'break-word',
                  maxWidth: '200px'
                }}>
                {col.render
                  ? col.render(_.get(n, col.id), n)
                  : _.get(n, col.id)}
              </TableCell>
            ))}
          </TableRow>
        )
      })}

      {!data.length && (
        <TableRow>
          <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
            {isLoading
              ? 'Daten werden geladen...'
              : 'Es sind keine Daten vorhanden.'}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

SortableTableBody.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  withPagination: PropTypes.bool
}
