import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import * as Validators from '../utils/Validators'

export function NewUserDialog({ onCancel, onOk }) {
  return (
    <Form
      onSubmit={onOk}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        submitFailed,
        dirtySinceLastSubmit,
        submitError
      }) => (
        <Dialog open={true} fullWidth={true} maxWidth="xs">
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <FormattedMessage id="USER_MANAGEMENT.CREATE_USER" />
            </DialogTitle>

            <DialogContent style={{ paddingTop: 8 }}>
              <FormattedMessage id="EMAIL">
                {txt => (
                  <Field
                    name="email"
                    placeholder={txt}
                    validate={Validators.compose(
                      Validators.required,
                      Validators.email
                    )}>
                    {({ input }) => (
                      <FormControl
                        error={submitFailed && !dirtySinceLastSubmit}
                        fullWidth={true}>
                        <InputLabel htmlFor={input.name} required={true}>
                          {txt}
                        </InputLabel>
                        <Input autoFocus={true} {...input} />
                        {submitFailed && !dirtySinceLastSubmit && (
                          <FormHelperText>{submitError}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </Field>
                )}
              </FormattedMessage>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={onCancel}>
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  submitting ||
                  pristine ||
                  hasValidationErrors ||
                  (submitFailed && !dirtySinceLastSubmit)
                }>
                <FormattedMessage id="CREATE" />
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    />
  )
}

NewUserDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired
}
