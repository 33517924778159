import React from 'react'
import ReactDOM from 'react-dom'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import teal from '@material-ui/core/colors/teal'
import blueGrey from '@material-ui/core/colors/blueGrey'
import commonColors from '@material-ui/core/colors/common'

import App from './App'
import 'antd/dist/antd.css'
import './index.css'

import { Settings } from 'luxon'

Settings.defaultLocale = 'de'

const elementMuiTheme = createTheme({
  palette: {
    primary: { main: teal[400] }
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: blueGrey[800],
        color: commonColors['white']
      }
    }
  }
})

ReactDOM.render(
  <MuiThemeProvider theme={elementMuiTheme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
)
