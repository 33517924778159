import React from 'react'
import SelectField from '../../components/SelectField'
import { countries } from './CustomerCountries'

export default function CustomerCountrySelection({
  name,
  allowEUCountries,
  floatingLabelText
}) {
  const germanSpeakingCountries = ['D', 'AT']

  const allowedCountries = countries.filter(country =>
    allowEUCountries
      ? country
      : germanSpeakingCountries.includes(country.countryCode)
  )

  const options = allowedCountries.map((country, index) => {
    return (
      <option key={index} value={country.name} data-testid="customer-country">
        {country.germanName}
      </option>
    )
  })

  return (
    <SelectField
      name={name}
      floatingLabelText={floatingLabelText}
      data-testid="customer-country-select">
      {options}
    </SelectField>
  )
}
