import * as React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import Table from '@material-ui/core/Table'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'

import { SortableTableHead } from './SortableTableHead'
import { SortableTableToolbar } from './SortableTableToolbar'
import { SortableTableBody } from './SortableTableBody'
import { TableFilterDialog } from './TableFilterDialog'
import { sortByField } from '../Sort'
import { valueIsString } from '../../stringUtils'

export class SortableTable extends React.Component {
  static propTypes = {
    actions: PropTypes.node,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    defaultOrderBy: PropTypes.string,
    filterBy: PropTypes.string,
    filterSelectedByDefault: PropTypes.arrayOf(PropTypes.string),
    idField: PropTypes.string,
    initialSelection: PropTypes.array,
    isLoading: PropTypes.bool,
    isSelectable: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    selectedActions: PropTypes.func,
    title: PropTypes.node.isRequired,
    withPagination: PropTypes.bool
  }

  static defaultProps = {
    idField: 'id',
    withPagination: true
  }

  constructor(props) {
    super(props)

    const filterSelected = getFilterSelected(props)
    const order = 'asc'
    const orderBy = props.defaultOrderBy || null

    this.state = {
      filterOpen: false,
      filterSelected,
      order: order,
      orderBy: orderBy,
      page: 0,
      rowsPerPage: 10,
      selected: props.initialSelection ? [...props.initialSelection] : [],
      processedData: filterAndSort(
        props.data,
        props.filterBy,
        filterSelected,
        order,
        orderBy,
        null
      ),
      searchTerm: null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data) {
      const filterSelected = getFilterSelected(this.props)
      const orderBy = this.props.defaultOrderBy || this.state.orderBy

      this.setState({
        filterSelected,
        orderBy,
        processedData: filterAndSort(
          this.props.data,
          this.props.filterBy,
          filterSelected,
          this.state.order,
          orderBy,
          this.state.searchTerm
        )
      })
    }
  }

  handleFilterOpen = () => {
    this.setState({
      filterOpen: true
    })
  }

  handleFilterClose = values => {
    const filterSelected = Object.keys(values).reduce((acc, key) => {
      if (values[key]) {
        acc.push(key)
      }

      return acc
    }, [])

    const { data, filterBy } = this.props
    const { order, orderBy, searchTerm } = this.state

    this.setState({
      filterOpen: false,
      filterSelected,
      processedData: filterAndSort(
        data,
        filterBy,
        filterSelected,
        order,
        orderBy,
        searchTerm
      )
    })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({
      order,
      orderBy,
      processedData: filterAndSort(
        this.props.data,
        this.props.filterBy,
        this.state.filterSelected,
        order,
        orderBy,
        this.state.searchTerm
      )
    })
  }

  handleSelectAllClick = (event, checked) => {
    const selected = checked
      ? this.state.processedData.map(n => _.get(n, this.props.idField))
      : []

    this.setState({ selected })

    if (typeof this.props.onSelectionChange === 'function') {
      this.props.onSelectionChange(selected)
    }
  }

  handleClick = (event, id) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    this.setState({ selected: newSelected })

    if (typeof this.props.onSelectionChange === 'function') {
      this.props.onSelectionChange(newSelected)
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleSearch = term => {
    const searchTerm = term.trim().toLowerCase() || null

    this.setState({
      processedData: filterAndSort(
        this.props.data,
        this.props.filterBy,
        this.state.filterSelected,
        this.state.order,
        this.state.orderBy,
        searchTerm
      ),
      searchTerm
    })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  render() {
    const {
      actions,
      columns,
      data,
      filterBy,
      idField,
      isLoading,
      isSelectable,
      selectedActions,
      style,
      title,
      withPagination
    } = this.props
    const {
      processedData,
      filterSelected,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page
    } = this.state

    return (
      <Paper elevation={2} style={style}>
        <SortableTableToolbar
          actions={actions}
          isFilterable={!!filterBy}
          numSelected={selected.length}
          onFilterOpen={this.handleFilterOpen}
          onSearch={this.handleSearch}
          selected={selected}
          selectedActions={selectedActions}
          title={title}
        />
        <Table aria-labelledby="tableTitle">
          <SortableTableHead
            columns={columns}
            isLoading={isLoading}
            isSelectable={isSelectable}
            numSelected={selected.length}
            onRequestSort={this.handleRequestSort}
            onSelectAllClick={this.handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={processedData.length}
          />
          <SortableTableBody
            columns={columns}
            data={processedData}
            handleClick={this.handleClick}
            idField={idField}
            isLoading={isLoading}
            isSelectable={isSelectable}
            isSelected={this.isSelected}
            page={page}
            rowsPerPage={rowsPerPage}
            withPagination={withPagination}
          />
        </Table>
        {withPagination && (
          <TablePagination
            component="div"
            count={processedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
        <TableFilterDialog
          data={data}
          filterBy={filterBy}
          selected={filterSelected}
          open={this.state.filterOpen}
          onClose={this.handleFilterClose}
        />
      </Paper>
    )
  }
}

function filterAndSort(data, filterBy, filterSelected, order, orderBy, search) {
  let filtered = filterByField(data, filterBy, filterSelected)

  if (search) {
    filtered = filtered.filter(entry =>
      Object.values(entry).some(
        text => valueIsString(text) && text.toLowerCase().includes(search)
      )
    )
  }

  return sortByField(filtered, e => _.get(e, orderBy), {
    order,
    ignoreCase: true
  })
}

function getFilterSelected({ data, filterBy, filterSelectedByDefault }) {
  if (!filterBy) {
    return []
  }

  if (Array.isArray(filterSelectedByDefault)) {
    return [...filterSelectedByDefault]
  }

  const set = new Set()
  data.forEach(el => set.add(el[filterBy]))
  return [...set]
}

function filterByField(data, filterBy, selected) {
  if (filterBy) {
    return data.filter(entry => selected.indexOf(entry[filterBy]) > -1)
  }

  return data
}
