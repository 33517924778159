import { PaymentsService } from '../../../../services/PaymentsService'
import { PaymentTransferReferenceContext } from './PaymentTransferReferenceContext'

export default class PaymentSharedTransferService {
  static async createSharedTransferDetailsContext(
    transfer: any
  ): Promise<Array<PaymentTransferReferenceContext> | undefined> {
    const distributedPolicies = this.getDistributedPolicies(transfer)
    const paymentTransferOrigin = this.getPaymentTransferOrigin(transfer)

    if (distributedPolicies && distributedPolicies.length > 0) {
      return this.getSharedPaymentTransfers(distributedPolicies)
    }

    if (paymentTransferOrigin) {
      return this.getSharedPaymentTransfersForTransferOrigin(
        transfer.id,
        paymentTransferOrigin
      )
    }

    return undefined
  }

  private static getPaymentTransferOrigin(
    transfer: any
  ): PaymentPolicyTransferReference | undefined {
    if (!transfer.paymentTransferOrigin) {
      return undefined
    }

    return {
      policyId: transfer.paymentTransferOrigin.policyId,
      paymentTransferId: transfer.paymentTransferOrigin.paymentTransferId
    }
  }

  private static getDistributedPolicies(
    transfer: any
  ): Array<PaymentPolicyTransferReference> | undefined {
    if (!transfer.paymentTransferDistribution) {
      return undefined
    }

    return transfer.paymentTransferDistribution.map(i => {
      return {
        policyId: i.policyId,
        paymentTransferId: i.paymentTransferId
      }
    })
  }

  private static async getSharedPaymentTransfers(
    transferReferences: Array<PaymentPolicyTransferReference>
  ): Promise<Array<PaymentTransferReferenceContext>> {
    return Promise.all(
      transferReferences.map(async transferReference => {
        const result = await PaymentsService.loadPaymentTransfer(
          transferReference.paymentTransferId
        )
        return {
          policyId: transferReference.policyId,
          amountCharged: result.amountCharged,
          paymentTransferId: transferReference.paymentTransferId
        } as PaymentTransferReferenceContext
      })
    )
  }

  private static async getSharedPaymentTransfersForTransferOrigin(
    distributedTransferId: string,
    paymentTransferOrigin: PaymentPolicyTransferReference
  ): Promise<Array<PaymentTransferReferenceContext>> {
    const result = new Array<PaymentTransferReferenceContext>()

    const originTransfer = await PaymentsService.loadPaymentTransfer(
      paymentTransferOrigin.paymentTransferId
    )

    result.push({
      policyId: paymentTransferOrigin.policyId,
      amountCharged: originTransfer.amountCharged,
      paymentTransferId: paymentTransferOrigin.paymentTransferId
    })

    const distributedTransfers = this.getDistributedPolicies(originTransfer)
    if (!distributedTransfers) {
      throw new Error(`The original transfer ${paymentTransferOrigin.paymentTransferId} 
            does not have transfer distribution collection.`)
    }

    const sharedPaymentTransfers =
      await this.getOtherSharedPaymentTransfersForTransferOrigin(
        distributedTransferId,
        distributedTransfers
      )
    if (sharedPaymentTransfers) {
      result.push(...sharedPaymentTransfers)
    }

    return result
  }

  private static async getOtherSharedPaymentTransfersForTransferOrigin(
    distributedTransferId: string,
    distributedTransfers: Array<PaymentPolicyTransferReference>
  ): Promise<Array<PaymentTransferReferenceContext> | undefined> {
    const otherDistributedTransfers = distributedTransfers.filter(
      i => i.paymentTransferId !== distributedTransferId
    )

    if (!otherDistributedTransfers?.length) {
      return undefined
    }

    const otherDistributedTransferReferences = otherDistributedTransfers.map(
      i => {
        return {
          policyId: i.policyId,
          paymentTransferId: i.paymentTransferId
        } as PaymentPolicyTransferReference
      }
    )

    return this.getSharedPaymentTransfers(otherDistributedTransferReferences)
  }
}

interface PaymentPolicyTransferReference {
  policyId: string
  paymentTransferId: string
}
