export function formatToTranslationKey(string) {
  return string.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase()
}

export function stringToBoolean(string) {
  return isStringBoolean(string) ? JSON.parse(string) : undefined
}

export function valueIsString(value) {
  return typeof value === 'string' || value instanceof String
}

export const removeBlankSpace = string => {
  return string.replace(/\s/g, '')
}
