import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { useUser } from './useUser'

export function UserInfoTooltip({ identifier }: { identifier: string }) {
  const { data: user } = useUser(identifier)

  if (!user) {
    return identifier
  }

  if (!user.email) {
    return <span>{user.name}</span>
  }

  return (
    <Tooltip
      title={
        <>
          <div>{user.name}</div>
          <div>{user.email}</div>
        </>
      }>
      <span>{user.name}</span>
    </Tooltip>
  )
}
