import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form } from 'react-final-form'

import SelectField from '../../components/SelectField'
import * as Validators from '../../utils/Validators'

export const AddGenericEventDialog = props => {
  const { isOpen, onCancel, onSubmit, eventTypes } = props

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <Form onSubmit={onSubmit} subscription={{ invalid: true }}>
        {({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">
              Geschäftsvorfall hinzufügen
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Bitte den Typ auswählen.</DialogContentText>
              <SelectField
                name="eventType"
                native={true}
                validate={Validators.required}>
                {eventTypes
                  .filter(eventType => eventType.generic)
                  .map(eventType => (
                    <option key={eventType.type} value={eventType.type}>
                      {eventType.description}
                    </option>
                  ))}
              </SelectField>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                Abbrechen
              </Button>
              <Button type="submit" color="secondary" disabled={invalid}>
                Hinzufügen
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}
