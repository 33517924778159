import React from 'react'
import grey from '@material-ui/core/colors/grey'
import Divider from '@material-ui/core/Divider'
import { CollapsibleTimelineEvent } from '../../../../utils/timeline/CollapsibleTimelineEvent'
import { formattedGermanDateTime } from '../../../../utils/Dates'
import { Author } from '../../../../utils/timeline/Author'
import { PaymentMethods } from '../payment-details/PaymentMethodConst'
import { mapPaymentMethodIcon } from '../payment-details/PaymentDetailsUtils'
import { TransfersDetailsButton } from '../payment-details/transfers/TransfersDetailsButton'
import { combineEventsWithDocuments } from '../../../../utils/timeline/TimelineUtils'

export default function PaymentMethodTimelineEvent({
  customer,
  policy,
  paymentMethod,
  documents,
  testData,
  ...noneEventProps
}) {
  const style = { fontSize: 16 }

  const paymentMethodEvents = paymentMethod.events
  const activatedEvent = paymentMethodEvents.find(el => el.status === 'ACTIVE')
  const deactivatedEvent = paymentMethodEvents.find(
    el => el.status === 'INACTIVE'
  )
  const isInactive = paymentMethod.status === 'INACTIVE'

  const { type, metadata } = paymentMethod

  const attachments = combineEventsWithDocuments(
    paymentMethod.events,
    documents
  )
    .flatMap(event => event.attachments)
    .filter(attachment => attachment !== undefined)

  return (
    <CollapsibleTimelineEvent
      icon={mapPaymentMethodIcon(type)}
      label={mapPaymentMethodLabel(type, metadata)}
      event={{
        createdAt: activatedEvent.createdAt,
        createdBy: activatedEvent.createdBy,
        paymentMethod: paymentMethod
      }}
      isExpanded={!isInactive}
      customer={customer}
      policy={policy}
      paymentMethod={paymentMethod}
      attachments={attachments}
      hideAttachmentUpload={true}
      displayEffectiveDate={false}
      isFutureEvent={isInactive}
      titleStyle={style}
      subtitleStyle={style}
      iconStyle={{ fontSize: 12, height: 10 }}
      iconColor={grey['700']}
      contentStyle={{ boxShadow: 'none', padding: 0, margin: 0 }}
      testData={testData}
      {...noneEventProps}>
      <ul style={{ margin: 10, padding: '0 10px' }}>
        <PaymentMethodInfo paymentMethod={paymentMethod} />
        {isInactive && (
          <li>
            <b>Deaktiviert:</b>{' '}
            {formattedGermanDateTime(deactivatedEvent.createdAt)} von{' '}
            <Author
              identifier={deactivatedEvent.createdBy}
              isCustomer={
                deactivatedEvent.createdBy === deactivatedEvent.customerId
              }
            />
          </li>
        )}
      </ul>
      {paymentMethod.type !== PaymentMethods.MANUAL_TRANSFER && (
        <RefundSection paymentMethod={paymentMethod} />
      )}
      {[PaymentMethods.INVOICE, PaymentMethods.MANUAL_TRANSFER].includes(
        paymentMethod.type
      ) && (
        <>
          <Divider />
          <TransfersDetailsButton
            policy={policy}
            paymentMethod={paymentMethod}
            customer={customer}
            events={paymentMethod.events}
          />
        </>
      )}
      {attachments && attachments.length > 0 && <Divider />}
    </CollapsibleTimelineEvent>
  )
}

const mapPaymentMethodLabel = (type, metadata) => {
  switch (type) {
    case PaymentMethods.CREDIT_CARD:
      return `Kreditkarte (****${metadata.cardLastFourDigits})`
    case PaymentMethods.DIRECT_DEBIT:
      return `SEPA-Lastschrift (****${metadata.ibanLastFourDigits})`
    case PaymentMethods.INVOICE:
      return 'Rechnung (SEPA-Banküberweisung)'
    case PaymentMethods.MANUAL_TRANSFER:
      return 'Zahlungsüberweisung'
    case PaymentMethods.EXTERNAL_COLLECTION:
      return 'Externe Zahlung'
    case PaymentMethods.PAYPAL:
      return 'PayPal'
    default:
      return 'Unbekannte Zahlungsmethode'
  }
}

export function PaymentMethodInfo({ paymentMethod }) {
  const { type, metadata, provider } = paymentMethod

  switch (type) {
    case PaymentMethods.CREDIT_CARD:
      return <CreditCardDetails metadata={metadata} />
    case PaymentMethods.DIRECT_DEBIT:
      return <DirectDebitDetails metadata={metadata} provider={provider} />
    case PaymentMethods.INVOICE:
      return <InvoiceDetails metadata={metadata} />
    case PaymentMethods.PAYPAL:
      return <PayPalDetails metadata={metadata} />
    default:
      return <></>
  }
}

function CreditCardDetails({ metadata }) {
  const { cardLastFourDigits, expirationMonth, expirationYear } = metadata

  return (
    <>
      {cardLastFourDigits && (
        <li>
          <b>Kartennummer:</b> ****{cardLastFourDigits}
        </li>
      )}
      {expirationMonth && expirationYear && (
        <li>
          <b>Gültig bis:</b> {expirationMonth}/{expirationYear}
        </li>
      )}
    </>
  )
}

function DirectDebitDetails({ metadata, provider }) {
  const { iban, accountHolder, bic, mandate } = metadata

  return (
    <>
      {provider && (
        <li>
          <b>Provider:</b> {provider}
        </li>
      )}
      {iban && (
        <li>
          <b>IBAN:</b> {iban}
        </li>
      )}
      {accountHolder && (
        <li>
          <b>Kontoinhaber:</b> {accountHolder}
        </li>
      )}
      {bic && (
        <li>
          <b>BIC:</b> {bic}
        </li>
      )}
      {mandate && (
        <li>
          <b>SEPA-Mandats-Id:</b> {mandate}
        </li>
      )}
    </>
  )
}

function PayPalDetails({ metadata }) {
  const { payerName, payerEmail } = metadata

  return (
    <>
      {payerName && (
        <li>
          <b>Name des Zahlers:</b> {payerName}
        </li>
      )}
      {payerEmail && (
        <li>
          <b>Zahler E-Mail:</b> {payerEmail}
        </li>
      )}
    </>
  )
}

function InvoiceDetails({ metadata }) {
  const { iban, bic, bank } = metadata

  return (
    <>
      {iban && (
        <li>
          <b>IBAN:</b> {iban}
        </li>
      )}
      {bic && (
        <li>
          <b>BIC:</b> {bic}
        </li>
      )}
      {bank && (
        <li>
          <b>Bank:</b> {bank}
        </li>
      )}
      <InvoiceDetailsPolicyListItems metadata={metadata} />
    </>
  )
}

function InvoiceDetailsPolicyListItems({ metadata }) {
  const { policies, policyId } = metadata

  if (policies) {
    const policyList = policies.replace(/ /g, '').split(',')

    if (policyList.length === 1) {
      return <InvoiceDetailsPolicyListItem policyId={policyList[0]} />
    }

    const listItems = policyList.map(policyId => (
      <li key={policyId}>{policyId}</li>
    ))

    return (
      <li>
        <b>Policies:</b>
        <ul>{listItems}</ul>
      </li>
    )
  }

  if (policyId) {
    return <InvoiceDetailsPolicyListItem policyId={policyId} />
  }

  return <></>
}

function InvoiceDetailsPolicyListItem({ policyId }) {
  return (
    <li>
      <b>Policy:</b> {policyId}
    </li>
  )
}

function RefundSection({ paymentMethod }) {
  const { metadata, type, status } = paymentMethod
  const { refundAccountHolder, refundIban } = metadata

  return (
    type !== PaymentMethods.EXTERNAL_COLLECTION && (
      <>
        {refundAccountHolder && refundIban && (
          <>
            <b>Rückzahlungdetails:</b>
            <ul style={{ margin: 10, padding: '0 10px' }}>
              <li>
                <b>Kontoinhaber:</b> {refundAccountHolder}
              </li>
              <li>
                <b>IBAN:</b> {refundIban}
              </li>
            </ul>
          </>
        )}
      </>
    )
  )
}
