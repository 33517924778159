import React from 'react'
import { LinearProgress } from '@material-ui/core'

export function ProductLine({
  policyLines,
  policyLine,
  product,
  onInsuredPersonRemoved,
  onInsuredPersonAdded,
  onPolicyLineRemoved,
  onInsuredDogRemoved
}) {
  const PolicyDisplayView = React.lazy(() =>
    import(`./display/${policyLine.productName}/PolicyDisplay`).catch(
      () => import('./PolicyDisplayError')
    )
  )

  return (
    <React.Suspense fallback={<LinearProgress />}>
      <PolicyDisplayView
        policyLines={policyLines}
        policyLine={policyLine}
        product={product}
        onInsuredPersonAdded={onInsuredPersonAdded}
        onInsuredPersonRemoved={onInsuredPersonRemoved}
        onPolicyLineRemoved={onPolicyLineRemoved}
        onInsuredDogRemoved={onInsuredDogRemoved}
      />
    </React.Suspense>
  )
}
