import React from 'react'
import { FormattedPremium } from '../../utils/FormattedPremium'
import { Icon } from '@material-ui/core'
import { getTranslationForKey } from '../../i18n/getTranslationForKey'
import isString from 'lodash/isString'

export const CustomCardHeader = ({ leftColumnRender, rightColumnRender }) => {
  return (
    <div
      style={{
        background: 'ghostWhite',
        padding: '8px 16px',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center'
      }}>
      {leftColumnRender}

      <div style={{ float: 'right' }}>{rightColumnRender}</div>
    </div>
  )
}

export function LabeledContent({
  label,
  value,
  style = undefined,
  content = undefined
}) {
  return (
    <div style={style}>
      <label style={{ fontSize: 12, color: '#555' }}>{label}</label>
      <div style={{ fontSize: 16, paddingTop: 10, paddingBottom: 10 }}>
        {content || value || '-'}
      </div>
    </div>
  )
}

export function ComparisonOperator({ value }) {
  switch (value.toLowerCase()) {
    case 'greaterThan'.toLowerCase():
      return <span>&gt;</span>
    case 'lessThan'.toLowerCase():
      return <span>&lt;</span>
    case 'greaterThanOrEqual'.toLowerCase():
      return <span>&ge;</span>
    case 'lessThanOrEqual'.toLowerCase():
      return <span>&le;</span>
    default:
      return <span>{value}</span>
  }
}

export function GoogleMapsLink({ latitude, longitude }) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
      href={`https://maps.google.com/?q=${latitude},${longitude}`}>
      <Icon aria-label="Link zu Google Maps">map</Icon>
    </a>
  )
}

export function LabeledBooleanValueWithChildren({ label, value, children }) {
  const isTrue = value === 'true' || value === true
  return (
    <div>
      <LabeledContent label={label} value={isTrue ? 'ja' : 'nicht gewünscht'} />
      {isTrue && children}
    </div>
  )
}

export function LabeledBooleanValue({ label, value }) {
  const isTrue = isString(value)
    ? value.toLowerCase() === 'true'
    : value === true
  return value != null ? (
    <LabeledContent
      label={label}
      value={isTrue ? getTranslationForKey('YES') : getTranslationForKey('NO')}
    />
  ) : null
}

export function LabeledNumberContent({
  label,
  value,
  noDataMessage = 'unbekannt'
}) {
  return value != null ? (
    <LabeledContent label={label} value={<FormattedPremium value={value} />} />
  ) : (
    <LabeledContent label={label} value={noDataMessage} />
  )
}
