import * as React from 'react'
import MuiButton from '@material-ui/core/Button'

export function Button({ children, ...props }) {
  return (
    <MuiButton variant="contained" color="primary" fullWidth={true} {...props}>
      {children}
    </MuiButton>
  )
}
