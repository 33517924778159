import * as React from 'react'

const style = {
  border: '1px solid transparent',
  padding: '1rem',
  margin: '1rem 0',
  backgroundColor: '#f6cdd1',
  borderColor: '#dc3545'
}

export const ErrorMessage = ({ message }) => {
  return message ? <div style={style}>{message}</div> : null
}
