import * as React from 'react'
import Loadable from 'react-loadable'
import LinearProgress from '@material-ui/core/LinearProgress'

const Loading = () => <LinearProgress />

export default function LazyLoader(opts) {
  return Loadable(
    Object.assign(
      {
        loading: Loading,
        delay: 200,
        timeout: 10000
      },
      opts
    )
  )
}
