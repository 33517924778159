import { useEffect, useState, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PaymentsService } from '../../../../../services/PaymentsService'

import ScheduledList from './ScheduledList'
import { SchedulePaymentType } from './types'
import * as React from 'react'

type ScheduledPaymentsProps = {
  policyId: string
}

export default function ScheduledPayments({
  policyId
}: ScheduledPaymentsProps) {
  const { data, error, refetch, loading } = useLoadSchedulePayments(policyId)

  if (error) {
    return (
      <Box
        sx={{
          marginTop: '20px',
          width: '250px'
        }}>
        <Box
          sx={{
            marginBottom: '10px'
          }}>
          <Typography variant="body2" color="secondary">
            Something went wrong, press on Retry button
          </Typography>
        </Box>
        <Button
          fullWidth
          color="secondary"
          variant="outlined"
          onClick={refetch}>
          Retry
        </Button>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        width: '250px'
      }}>
      {loading ? <LinearProgress /> : <ScheduledList payments={data} />}
    </Box>
  )
}

function useLoadSchedulePayments(policyId: string) {
  const [data, setData] = useState<SchedulePaymentType[]>([])
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState<boolean>(false)

  const loadSchedulePayments = useCallback(async () => {
    setLoading(true)

    try {
      const response: SchedulePaymentType[] =
        await PaymentsService.loadScheduledPayments(policyId)
      setData(response)
      setLoading(false)
    } catch (error) {
      const err = error as Error

      setError(err)
      setLoading(false)
    }
  }, [policyId])

  useEffect(() => {
    if (!loading) {
      loadSchedulePayments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSchedulePayments])

  return { data, error, refetch: loadSchedulePayments, loading }
}
