import React, { useEffect, useState } from 'react'
import { ModalFormButton } from '../../../components/ModalFormButton'
import SendDunningForm from './payment-details/SendDunningForm'
import { policyService } from '../../../services/PolicyService'
import { GlobalSnackbarService } from '../../../utils/GlobalSnackbarService'

export function SendDunningButton({ policyId, ...rest }) {
  const [dunningCalculation, setDunningCalculation] = useState(null)

  useEffect(() => {
    policyService
      .getDunningCalculation(policyId)
      .then(result => {
        setDunningCalculation(result)
      })
      .catch(error => {
        setDunningCalculation(null)
        GlobalSnackbarService.emit(
          'Zahlungserinnerungsberechnung nicht möglich.',
          'error'
        )
        console.error('error', error)
      })
  }, [policyId])

  function onSubmit(formData) {
    return policyService.createDunningReminder(policyId, {
      dunningType: formData.dunningType,
      reminderType: formData.reminderType
    })
  }

  function validate(formData) {
    return undefined
  }

  return (
    <ModalFormButton
      onSubmit={onSubmit}
      titleLabel={`Zahlungserinnerung senden (Police: ${policyId})`}
      buttonLabel={'Zahlungserinnerung senden'}
      successLabel={'Zahlungserinnerung gesendet'}
      {...rest}
      formProps={{ validate: validate }}
      disabled={dunningCalculation == null}
      formContent={
        <SendDunningForm
          dueDate={dunningCalculation ? dunningCalculation.dueDate : null}
          balance={dunningCalculation ? dunningCalculation.balance : null}
          firstReminderInstant={
            dunningCalculation ? dunningCalculation.firstReminderInstant : null
          }
        />
      }
    />
  )
}
