import React from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import authTexts from '@elementinsurance/cognito-auth/i18n/de.json'
import * as Sentry from '@sentry/browser'
import { SentryErrorBoundary } from './components/SentryErrorBoundary'
import { Auth } from './auth/Auth'
import { AuthorizationContext } from './auth/AuthorizationFilter'
import texts from './i18n/de.json'

import './App.css'
import ElementNameMappingProvider from './global-state/ElementNameMappingProvider'
import { AuthComponentProps } from '@elementinsurance/cognito-auth/dist/types/AuthComponentProps'
import { ConfirmationDialogContextProvider } from './components/ConfirmationDialogContextProvider'
import { DevTools } from './components/DevTools'
import { GlobalSnackbar } from './components/GlobalSnackbar'

import AppLayout from './components/AppLayout/AppLayout'
import { useEffect } from 'react'
import { CognitoUser } from 'amazon-cognito-identity-js'

Sentry.init({
  dsn: 'https://4315652fd9cf4a738c5e38e36827af30@sentry.io/1312037',
  beforeSend: event => {
    event.tags = {
      ...event.tags,
      stacktraceOrigin: getStacktraceOrigin(event)
    }

    return event
  }
})

const messages = Object.assign({}, authTexts, texts)

export default function App() {
  return (
    <IntlProvider locale="de" messages={messages}>
      <SentryErrorBoundary>
        <Auth>
          {(auth: AuthComponentProps) => {
            return (
              <AuthorizationContext.Provider value={auth}>
                <SentryUser auth={auth} />
                <ConfirmationDialogContextProvider>
                  <ElementNameMappingProvider>
                    <Router>
                      <DevTools />
                      <AppLayout auth={auth} />
                      <GlobalSnackbar />
                    </Router>
                  </ElementNameMappingProvider>
                </ConfirmationDialogContextProvider>
              </AuthorizationContext.Provider>
            )
          }}
        </Auth>
      </SentryErrorBoundary>
    </IntlProvider>
  )
}

function SentryUser({ auth }: { auth: AuthComponentProps }) {
  const username = auth.authData?.getUsername()
  useEffect(() => {
    // @ts-ignore
    getUserEmail(auth.authData).then(email => Sentry.setUser({ email }))
  }, [username, auth.authData])
  return null
}

function getStacktraceOrigin(event: Sentry.Event) {
  try {
    const exception = event.exception?.values?.[0]
    const firstStackFrameFilename = exception?.stacktrace?.frames?.[0]?.filename
    return firstStackFrameFilename
      ? new URL(firstStackFrameFilename).origin
      : null
  } catch {
    return null // ignore invalid stacktrace urls
  }
}

function getUserEmail(cognitoUser: CognitoUser) {
  return new Promise<any>((resolve, reject) => {
    cognitoUser.getUserAttributes((error, attributes) => {
      if (attributes) {
        resolve((attributes as any).find(attr => attr.Name === 'email')?.Value)
      } else {
        reject(error)
      }
    })
  })
}
