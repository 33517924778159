import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { FormattedMessage } from 'react-intl'

export class SentryErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.captureException(error)
  }

  render() {
    return this.state.error ? (
      <FormattedMessage id="SENTRY.OOPS" />
    ) : (
      this.props.children
    )
  }
}
