import React from 'react'

export default class QueueMessagePage extends React.Component {
  state = {
    wrappedMessageJson: '',
    messageJson: ''
  }

  onLeftInputChange = e => {
    const wrappedMessageJson = e.target.value

    const wrappedMessage = parseJson(wrappedMessageJson)
    const message = parseJson(wrappedMessage.Message)

    this.setState({
      wrappedMessageJson,
      messageJson: stringifyJson(message)
    })
  }

  onRightInputChange = e => {
    const messageJson = e.target.value

    this.setState(previousState => {
      const wrappedMessage = parseJson(previousState.wrappedMessageJson)
      const message = parseJson(messageJson)

      wrappedMessage.Message = JSON.stringify(message)

      return {
        wrappedMessageJson: stringifyJson(wrappedMessage),
        messageJson
      }
    })
  }

  render() {
    const textareaStyle = {
      width: '100%',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      height: '80vh'
    }

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Enter message from queue here:
          <textarea
            onChange={this.onLeftInputChange}
            style={textareaStyle}
            value={this.state.wrappedMessageJson}></textarea>
        </div>

        <div style={{ width: 50 }}></div>

        <div style={{ flex: 1 }}>
          Real message payload:
          <textarea
            onChange={this.onRightInputChange}
            style={textareaStyle}
            value={this.state.messageJson}></textarea>
        </div>
      </div>
    )
  }
}

function parseJson(json) {
  try {
    return JSON.parse(json)
  } catch (e) {
    return {}
  }
}

function stringifyJson(value) {
  return JSON.stringify(value, null, 2)
}
