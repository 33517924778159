import React from 'react'
import { ConfirmationDialog, ConfirmationOptions } from './ConfirmationDialog'
import ConfirmationDialogContext from './ConfirmationDialogContext'

export function useConfirmation() {
  return React.useContext(ConfirmationDialogContext)
}

export function ConfirmationDialogContextProvider({ children }) {
  const [confirmationState, setConfirmationState] =
    React.useState<ConfirmationOptions | null>(null)

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setConfirmationState(null)
  }

  return (
    <>
      <ConfirmationDialogContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationDialogContext.Provider>
      {confirmationState && (
        <ConfirmationDialog
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationState}
        />
      )}
    </>
  )
}
