import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import Card from '@material-ui/core/Card'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

import CustomerTimeline from './timeline/CustomerTimeline'
import { customerService } from '../../services/CustomerService'
import store from '../CustomerStore'
import { CustomCardHeader, LabeledContent } from './LabeledContent'
import CustomerTypeSpecificFields from './CustomerTypeSpecificFields'
import { AddGenericEventDialog } from './AddGenericEventDialog'
import DocumentsService from '../../services/DocumentsService'
import UpdateCustomerButton from './UpdateCustomerButton'
import Paper from '@material-ui/core/Paper'
import Copyable from '../../utils/Copyable'
import { getCountryGermanNameByName } from './CustomerCountries'
import { FixedColumnCountGridLayout } from '../../components/GridLayouts'
import { MARKETING_CONSENT_OPTIONS } from './MarketingConsentFields'

export function displaySalutation(gender) {
  switch (gender) {
    case 'MALE':
      return 'Herr'
    case 'FEMALE':
      return 'Frau'
    default:
      return '-'
  }
}

export default class CustomerData extends React.Component {
  static propTypes = {
    customer: PropTypes.object,
    documents: PropTypes.array,
    customerEventTypes: PropTypes.array,
    isReadOnly: PropTypes.bool
  }

  state = {
    addEventDialogOpen: false
  }

  toggleAddEventDialogOpen = () => {
    this.setState({ addEventDialogOpen: !this.state.addEventDialogOpen })
  }

  handleAddEventSubmit = formData => {
    const type = formData.eventType
    customerService
      .addGenericCustomerEvent(this.props.customer.id, type)
      .then(response => {
        store.loadCustomer(this.props.customer.id)
      })
  }

  uploadAttachmentsToEvent = (event, attachments, visibleForCustomer) => {
    return DocumentsService.addCustomerEventAttachments(
      this.props.customer.id,
      event.eventId,
      attachments,
      visibleForCustomer
    ).then(() => store.loadCustomer(this.props.customer.id))
  }

  render = () => {
    const { customer, documents, customerEventTypes } = this.props

    return (
      <Card style={{ width: '100%' }}>
        <CustomCardHeader
          leftColumnRender={
            <Typography variant="subtitle1" component="h3">
              Kundendaten
            </Typography>
          }
        />

        <div style={{ display: 'grid', gridTemplateColumns: '350px auto' }}>
          <div style={{ padding: 20 }}>
            {customerEventTypes && (
              <React.Fragment>
                <Fab
                  name="addOpsEventDialogButton"
                  data-testid="addOpsEventDialogButton"
                  onClick={this.toggleAddEventDialogOpen}
                  style={{ width: 47, height: 47 }}
                  color="secondary"
                  disabled={this.props.isReadOnly}>
                  <Icon>add</Icon>
                </Fab>

                <AddGenericEventDialog
                  onSubmit={this.handleAddEventSubmit}
                  onCancel={this.toggleAddEventDialogOpen}
                  isOpen={this.state.addEventDialogOpen}
                  eventTypes={customerEventTypes}
                />
              </React.Fragment>
            )}

            {customer.events && (
              <CustomerTimeline
                events={customer.events.slice().reverse()}
                documents={documents}
                eventTypes={customerEventTypes}
                onAttachmentsUpload={this.uploadAttachmentsToEvent}
              />
            )}
          </div>

          <Paper elevation={1} style={{ padding: '16px 16px 0' }} square={true}>
            <UpdateCustomerButton
              variant="outlined"
              customer={customer}
              disabled={this.props.isReadOnly}
            />

            <FixedColumnCountGridLayout columnCount={1}>
              <CustomerTypeSpecificFields customer={customer} />

              <FixedColumnCountGridLayout>
                <h4>Kontakt</h4>

                <FixedColumnCountGridLayout columnCount={3}>
                  <LabeledContent
                    label="Email"
                    value={<Copyable>{customer.email}</Copyable>}
                  />
                  <LabeledContent
                    label="Telefon"
                    value={<Copyable>{customer.phone}</Copyable>}
                  />
                </FixedColumnCountGridLayout>
                <FixedColumnCountGridLayout>
                  <h4>Werbeeinwilligung</h4>
                  <FixedColumnCountGridLayout
                    columnCount={3}
                    data-testid="marketingConsents">
                    {customer.marketingConsents &&
                      MARKETING_CONSENT_OPTIONS.map(({ label, value }) => {
                        return (
                          <LabeledContent
                            key={value}
                            label={label}
                            value={
                              customer.marketingConsents.includes(value)
                                ? 'Ja'
                                : 'Nein'
                            }
                          />
                        )
                      })}
                  </FixedColumnCountGridLayout>
                </FixedColumnCountGridLayout>

                {customer.type === 'LEGAL_ENTITY' && (
                  <FixedColumnCountGridLayout columnCount={3}>
                    <LabeledContent
                      label="Vorname"
                      value={
                        customer.contactPerson &&
                        customer.contactPerson.firstName
                          ? customer.contactPerson.firstName
                          : '-'
                      }
                      data-test-id="vorname"
                    />
                    <LabeledContent
                      label="Nachname"
                      value={
                        customer.contactPerson &&
                        customer.contactPerson.lastName
                          ? customer.contactPerson.lastName
                          : '-'
                      }
                      data-test-id="nachname"
                    />
                    <LabeledContent
                      label="Anrede"
                      value={
                        customer.contactPerson && customer.contactPerson.gender
                          ? displaySalutation(customer.contactPerson.gender)
                          : '-'
                      }
                      data-test-id="anrede"
                    />
                  </FixedColumnCountGridLayout>
                )}

                <h5>Adresse</h5>
                <LabeledContent label="Firma" value={customer.companyName} />
                {customer.addresses.map(address => (
                  <FixedColumnCountGridLayout columnCount={3} key={address}>
                    <LabeledContent
                      label="Adresszusatz"
                      value={address.addressPrefix}
                    />
                    <LabeledContent label="Straße" value={address.street} />
                    <LabeledContent
                      label="Hausnummer"
                      value={address.houseNumber}
                    />
                    <LabeledContent label="PLZ" value={address.postCode} />
                    <LabeledContent label="Ort" value={address.city} />
                    <LabeledContent
                      label="Land"
                      value={getCountryGermanNameByName(address.country)}
                    />
                  </FixedColumnCountGridLayout>
                ))}

                <h5>Sonstiges</h5>

                <FixedColumnCountGridLayout columnCount={3}>
                  <LabeledContent
                    label="Externe ID"
                    value={<Copyable>{customer.externalId}</Copyable>}
                  />
                  <LabeledContent
                    label="Subpartner ID"
                    value={customer.subpartnerId}
                  />
                  <LabeledContent
                    label="Subpartner Email"
                    value={customer.subpartnerEmail}
                  />
                </FixedColumnCountGridLayout>
              </FixedColumnCountGridLayout>
            </FixedColumnCountGridLayout>
          </Paper>
        </div>
      </Card>
    )
  }
}
