import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { friendlyFormatIBAN } from 'ibantools'
import { valueIsString } from '../stringUtils'
import { Duration } from 'luxon'

export function formatDate(date) {
  if (date == null) {
    return '-'
  }
  return new Date(date).toLocaleDateString('de-DE', {
    timeZone: 'Europe/Berlin',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
}

export function formatDateTime(date) {
  return new Date(date).toLocaleDateString('de-DE', {
    timeZone: 'Europe/Berlin',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

export function phoneInternational(phoneNumber) {
  if (!phoneNumber) {
    return undefined
  }
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'DE')
  return parsedPhoneNumber ? parsedPhoneNumber.number : phoneNumber
}

export function formatCurrency(value, decimalPlaces = 2, currencyType = 'EUR') {
  if (!value && value !== 0) {
    return value
  }
  if (valueIsString(value)) {
    value = parseFloat(value)
  }
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: decimalPlaces,
    style: 'currency',
    currency: currencyType
  })
}

export function iban(value) {
  return friendlyFormatIBAN(value)
}

export function toPascalCase(value) {
  let str = value
  const upperChars = str.match(/([A-Z])/g)
  if (upperChars) {
    str = value.toString()
    upperChars.forEach(
      upperChar => (str = str.replace(upperChar, '_' + upperChar.toLowerCase()))
    )

    if (str.startsWith('_')) {
      str = str.slice(1)
    }
  }
  return str.toUpperCase()
}

export function formatPercentage(value, fractionDigits = 1) {
  return value ? `${(value * 100).toFixed(fractionDigits)} %` : '0 %'
}

export function formatDuration(value, format) {
  const duration = Duration.fromISO(value)
  switch (format) {
    case 'days':
      return `${duration.toFormat('d')} Tage`
    case 'months':
      return `${duration.toFormat('M')} Monate`
    case 'years':
      return `${duration.toFormat('y')} Jahre`
    default:
      return `${duration.toFormat('M')} Monate`
  }
}

export function formatToISODate(value) {
  return value ? new Date(value).toISOString() : undefined
}
