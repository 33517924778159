import React from 'react'
import elementLogo from './img/new-element-logo.svg'
import styles from './Logo.module.scss'

export default function ElementLogo() {
  return (
    <a href="/">
      <img src={elementLogo} alt="ELEMENT Partner Portal" />
    </a>
  )
}
