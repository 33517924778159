import { InputAdornment, TextField, IconButton } from '@material-ui/core'
import React, { PropsWithChildren, useState } from 'react'
import { Edit, Done, Close } from '@material-ui/icons'

const EditableField = ({
  children,
  fieldValue,
  fieldLabel,
  isRequired = false,
  errorText,
  onDone,
  isReadOnly = false
}: PropsWithChildren<{
  fieldValue: string
  fieldLabel: string
  isRequired?: boolean
  errorText?: string
  onDone: (inputText: string) => void
  isReadOnly?: boolean
}>) => {
  const [isEditMode, updateEditMode] = useState(false)
  const [textInField, updateText] = useState(fieldValue)

  const onTextChange = evt => {
    const {
      target: { value }
    } = evt
    updateText(value)
  }

  const toggleEditMode = () => updateEditMode(!isEditMode)

  const onDoneButtonClick = () => {
    onDone(textInField)
    toggleEditMode()
  }

  const onCancelClick = () => {
    updateText(fieldValue)
    toggleEditMode()
  }

  const isEmpty = textInField === ''
  return isEditMode ? (
    <TextField
      required={isRequired}
      defaultValue={textInField}
      error={isEmpty}
      onChange={onTextChange}
      label={fieldLabel}
      helperText={isEmpty ? errorText : ''}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={onDoneButtonClick}
              disabled={textInField === '' || fieldValue === textInField}
              name="doneButton"
              data-testid="doneButton">
              <Done />
            </IconButton>
            <IconButton
              onClick={onCancelClick}
              name="cancelButton"
              data-testid="cancelButton">
              <Close />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  ) : (
    <>
      {children}
      <IconButton
        onClick={toggleEditMode}
        data-testid="editButton"
        name="editButton"
        disabled={isReadOnly}>
        <Edit style={{ fontSize: 14 }} />
      </IconButton>
    </>
  )
}

export default EditableField
