import { API, SandboxAPI } from '@elementinsurance/cognito-auth'
import { errorHandler } from '../utils/RestErrorHandler'

export class QuoteService {
  static create(quote, testData) {
    return testData
      ? SandboxAPI.post('/quotes', quote).catch(errorHandler())
      : API.post('/quotes', quote).catch(errorHandler())
  }

  static createQuotePreview(quote) {
    return API.post('/backoffice/underwriting/quote/preview', quote)
  }
}
