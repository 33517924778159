import React from 'react'
import { Field } from 'react-final-form'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'

export default function ToggleField({
  name,
  label,
  validate,
  defaultValue,
  initialValue,
  ...rest
}) {
  return (
    <Field
      name={name}
      validate={validate}
      defaultValue={defaultValue}
      initialValue={initialValue}
      type="checkbox"
      render={({ input, meta }) => {
        const { checked, value, ...inputWithoutValue } = input // NOSONAR : we need to exclude value as it does not conform to MUI api
        const errorText = meta.touched && meta.error ? meta.error : ''
        return (
          <FormGroup>
            <div>
              <FormControlLabel
                label={label}
                control={
                  <Switch
                    {...inputWithoutValue}
                    {...rest}
                    checked={!!checked}
                  />
                }
              />
            </div>

            {errorText && (
              <FormHelperText style={{ color: 'red' }}>
                {errorText}
              </FormHelperText>
            )}
          </FormGroup>
        )
      }}
    />
  )
}
