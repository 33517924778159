import { mapPaymentType } from './PaymentDetailsUtils'
import { PaymentMethods } from './PaymentMethodConst'

/**
 * Represents members to display PayPal payment details in the event timeline.
 */
export class TimelinePayPalPaymentMethodDetailsContext {
  paymentMethod = mapPaymentType(PaymentMethods.PAYPAL)

  payerName?: string

  payerEmail?: string

  receiverName?: string

  receiverEmail?: string
}
