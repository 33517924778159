import styled from 'styled-components'

export const AutoGridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`

interface FixedColumnCountGridLayoutProps {
  columnCount?: number
  columnGap?: number
  rowGap?: number
}
export const FixedColumnCountGridLayout = styled.div<FixedColumnCountGridLayoutProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.columnCount || 1}, 1fr);
  grid-template-rows: min-content;
  grid-column-gap: ${props => props.columnGap || 40}px;
  grid-row-gap: ${props => props.rowGap || 10}px;
`
