import React from 'react'
import { LabeledContent } from '../../LabeledContent'
import PaymentsContainer from './PaymentsContainer'
import { SendDunningButton } from '../SendDunningButton'
import {
  AccessRights,
  AuthorizationFilter
} from '../../../../auth/AuthorizationFilter'
import { UpdatePaymentDetailsButton } from './UpdatePaymentDetailsButton'
import CustomerContext from '../../CustomerContext'
import { PaymentsService } from '../../../../services/PaymentsService'
import PaymentsHistory from './PaymentsHistory'
import ScheduledPayments from './ScheduledPayments/ScheduledPayments'

export default function PaymentDetails({ policy, customer, documents }) {
  const [paymentAllowsModification, setPaymentAllowsModification] =
    React.useState(false)

  const { isReadOnly } = React.useContext(CustomerContext)

  React.useEffect(() => {
    isExternalManagement(policy.productId).then(external =>
      setPaymentAllowsModification(!external)
    )
  }, [policy])

  return (
    <div>
      {paymentAllowsModification && (
        <UpdatePaymentDetailsButton
          customer={customer}
          policy={policy}
          isReadOnly={isReadOnly}
        />
      )}
      <AuthorizationFilter rights={AccessRights.Element}>
        <SendDunningButton
          policyId={policy.id}
          disabled={policy.state !== 'ACTIVE' || isReadOnly}
          variant="outlined"
          style={{ marginBottom: 16 }}
        />
      </AuthorizationFilter>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1.5fr 0.6fr 1.5fr',
          gridColumnGap: 40
        }}>
        <div>
          <h4>Zahlungen</h4>
          <PaymentsHistory
            customerId={customer.id}
            policyId={policy.id}
            paymentHistory={policy.paymentHistory}
            documents={documents}
          />
        </div>
        <div>
          <LabeledContent
            label={
              customer.testData
                ? 'Buchhaltung (nur Pseudodaten!)'
                : 'Buchhaltung (TIA)'
            }
            value={
              <PaymentsContainer
                policyId={policy.id}
                testData={customer.testData}
              />
            }
          />
        </div>
        <div>
          <h4>Geplante Zahlungen</h4>
          <ScheduledPayments policyId={policy.id} />
        </div>
      </div>
    </div>
  )
}

function isExternalManagement(productId) {
  return PaymentsService.loadProductPaymentManagementType(productId)
    .then(paymentManagementType => paymentManagementType === 'EXTERNAL')
    .catch(() => false)
}
