import * as React from 'react'
import Button from '@material-ui/core/Button'

export function ClickableText({ children, ...props }) {
  return (
    <Button size="small" color="primary" {...props}>
      {children}
    </Button>
  )
}
