import React from 'react'
import Grid from '@material-ui/core/Grid'
import { ModalFormButton } from '../../../../components/ModalFormButton'
import PaymentDetailsForm from './PaymentDetailsForm'
import { PaymentsService } from '../../../../services/PaymentsService'
import store from '../../../CustomerStore'
import { removeBlankSpace } from '../../../../stringUtils'

export function SetRefundDetailsButton({
  customerId,
  policyId,
  testData,
  ...rest
}) {
  function onClose() {
    store.loadCustomer(customerId)
  }

  function onSubmit(formData) {
    const { accountHolder, iban } = formData
    const refundDetails = {
      accountHolder,
      iban: removeBlankSpace(iban)
    }
    return PaymentsService.setPolicyRefundDetails(
      policyId,
      refundDetails,
      testData
    )
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <ModalFormButton
          onClose={onClose}
          onSubmit={onSubmit}
          titleLabel={`Rückzahlungdetails Eintragen (Police: ${policyId})`}
          buttonLabel={'Rückzahlungdetails Eintragen'}
          successLabel={'Rückzahlungdetails erfolgreich eingegeben'}
          {...rest}
          formContent={<PaymentDetailsForm />}
        />
      </Grid>
    </Grid>
  )
}
