export function errorHandler(statusCodes = {}) {
  return function (error) {
    console.error(error)

    const message = statusCodes[error.status]
    if (message) {
      throw new Error(message)
    } else {
      let message = error.message
      const data = error.data || {}

      if (data.message) {
        message = data.message
      }

      if (data.errors) {
        message +=
          '\n' +
          data.errors.map(e => `${e.field}: ${e.defaultMessage}`).join('\n')
      }

      throw new Error(`Es ist ein Fehler aufgetreten (${message}).`)
    }
  }
}

export function notFoundErrorHandler(statusCodes = {}, defaultValue = null) {
  return function (error) {
    if (error && error.status === 404) {
      return defaultValue
    }
    return errorHandler(statusCodes)()
  }
}
