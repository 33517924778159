import React, { useEffect, useState } from 'react'
import { productService } from '../services/ProductService'
import ElementNameMappingContext from './ElementNameMappingContext'
import ProgressVisualizer from '../utils/ProgressVisualizer'

export default function ElementNameMappingProvider({ children }) {
  const [nameMap, setNameMap] = useState(null)

  useEffect(() => {
    productService
      .loadLineIdToElementNameMap()
      .then(productLineIdToElementNameMap =>
        setNameMap(productLineIdToElementNameMap)
      )
      .catch(error =>
        console.error(
          'failed to load productLineIdToElementNameMapping from product service',
          error
        )
      )
  }, [])

  return nameMap ? (
    <ElementNameMappingContext.Provider value={{ nameMap }}>
      {children}
    </ElementNameMappingContext.Provider>
  ) : (
    <ProgressVisualizer />
  )
}
