import React from 'react'
import * as _ from 'lodash'
import { valueIsString } from '../stringUtils'

export function FormattedPremium({ value }) {
  if (valueIsString(value)) {
    value = parseFloat(value)
  }

  return <Money value={value} decimalPlaces={2} currencyType={'EUR'} />
}

export const Money = ({ value, decimalPlaces = 2, currencyType = 'EUR' }) => {
  if (_.isNumber(value)) {
    return (
      <span>
        {value.toLocaleString('de-DE', {
          minimumFractionDigits: decimalPlaces,
          style: 'currency',
          currency: currencyType
        })}
      </span>
    )
  } else {
    return null
  }
}
