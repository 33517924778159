import React from 'react'
import PropTypes from 'prop-types'
import { policyService } from '../../../services/PolicyService'
import store from '../../CustomerStore'
import DocumentsService from '../../../services/DocumentsService'
import { productService } from '../../../services/ProductService'
import { PolicyDetails } from './PolicyDetails'
import { PolicyUpdateContext } from '../../../policy-update/PolicyUpdateContext'
import * as Sentry from '@sentry/browser'
import { Box, Card } from '@material-ui/core'
import Copyable from '../../../utils/Copyable'
import { StyledHeader, StyledTypography } from './PolicyStatusHeader'
import { ACCIDENT_PRODUCT_LINES } from '../../../product-lines/components/Accident/AccidentProductLines'
import { GlobalSnackbarService } from '../../../utils/GlobalSnackbarService'
import { getTranslationForKey } from '../../../i18n/getTranslationForKey'

export default class PolicyDetailsContainer extends React.Component {
  static contextType = PolicyUpdateContext

  static propTypes = {
    policy: PropTypes.object,
    customer: PropTypes.object,
    documents: PropTypes.array,
    shadowFlowDocuments: PropTypes.array,
    policyEventTypes: PropTypes.array,
    isReadOnly: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      product: null,
      addEventDialogOpen: false
    }
  }

  toggleAddEventDialogOpen = () => {
    this.setState({ addEventDialogOpen: !this.state.addEventDialogOpen })
  }

  handleAddEventSubmit = formData => {
    const eventType = formData.eventType
    policyService
      .addGenericPolicyEvent(
        this.props.customer.id,
        this.props.policy.id,
        eventType
      )
      .then(() => {
        store.loadCustomer(this.props.customer.id)
      })
      .catch(error => {
        GlobalSnackbarService.emit(error.message, 'error')
      })
  }

  uploadAttachmentsToEvent = (event, attachments, visibleForCustomer) => {
    const params = {
      customerId: this.props.customer.id,
      policyId: this.props.policy.id,
      claimId: event.claimId,
      eventId: event.eventId,
      files: attachments,
      visibleForCustomer: visibleForCustomer
    }

    return (
      event.claimId
        ? DocumentsService.addClaimEventAttachments({
            ...params,
            claimId: event.claimId
          })
        : DocumentsService.addPolicyEventAttachments(params)
    ).then(() => store.loadCustomer(this.props.customer.id))
  }

  async componentDidMount() {
    const product = await productService.load(this.props.policy.productId)
    if (product) {
      this.setState({ product })
    }
  }

  addInsuredPersonToPolicy = ({
    customerId,
    policyId,
    productLineId,
    addedInsuredPerson,
    effectiveDate
  }) =>
    this.context.useCases['AddInsuredPerson'].execute({
      customerId: customerId,
      policyId: policyId,
      productLineId: productLineId,
      personToInsure: addedInsuredPerson,
      effectiveDate: effectiveDate
    })

  removeInsuredPersonFromPolicy = ({
    customerId,
    policyId,
    productLineId,
    removedInsuredPersonId,
    effectiveDate
  }) =>
    this.context.useCases['RemoveInsuredPerson'].execute({
      customerId: customerId,
      policyId: policyId,
      productLineId: productLineId,
      removedInsuredPersonId: removedInsuredPersonId,
      effectiveDate: effectiveDate
    })

  removeInsuredDogFromPolicy = ({
    customerId,
    policyId,
    productLineId,
    removedInsuredDogId,
    effectiveDate
  }) =>
    this.context.useCases['RemoveInsuredDog'].execute({
      customerId: customerId,
      policyId: policyId,
      productLineId: productLineId,
      removedInsuredDogId: removedInsuredDogId,
      effectiveDate: effectiveDate
    })

  removePolicyLineFromPolicy = ({
    customerId,
    policyId,
    productLineId,
    effectiveDate
  }) =>
    this.context.useCases['RemovePolicyLine'].execute({
      customerId: customerId,
      policyId: policyId,
      productLineId: productLineId,
      effectiveDate: effectiveDate
    })

  updateExternalIdOfPolicy = externalPolicyId => {
    const {
      customer: { id: customerId },
      policy: { id: policyId }
    } = this.props
    policyService
      .updateExternalPolicyId(customerId, policyId, externalPolicyId)
      .then(() => {
        GlobalSnackbarService.emit(
          getTranslationForKey('POLICY_ID.EXTERNAL_ID.UPDATE_SUCCESS'),
          'success'
        )
        store.reloadCustomer()
      })
      .catch(() => {
        GlobalSnackbarService.emit(
          getTranslationForKey('POLICY_ID.EXTERNAL_ID.UPDATE_FAILED'),
          'error'
        )
      })
  }

  render() {
    const {
      policy,
      documents,
      shadowFlowDocuments,
      customer,
      policyEventTypes
    } = this.props
    const { product, addEventDialogOpen } = this.state
    const policyDocuments = documents.filter(d => d.policyId === policy.id)
    const shadowFlowPolicyDocuments = shadowFlowDocuments.filter(
      d => d.policyId === policy.id
    )
    const productAllowsModification =
      product &&
      (product.productLines || []).every(pl => pl.modifiable === true)
    const policyContainsAccidentLine = (policy.policyLines || []).some(pl =>
      ACCIDENT_PRODUCT_LINES.includes(pl.productLineId)
    )

    return (
      <PolicyViewErrorBoundary
        policyId={policy.id}
        policyExternalId={policy.externalId}>
        <PolicyDetails
          policy={policy}
          product={product}
          documents={documents}
          customer={customer}
          policyEventTypes={policyEventTypes}
          policyDocuments={policyDocuments}
          shadowFlowPolicyDocuments={shadowFlowPolicyDocuments}
          policyContainsAccidentLine={policyContainsAccidentLine}
          productAllowsModification={productAllowsModification}
          hasPendingRenewal={policy.hasPendingRenewal}
          isAddEventDialogOpen={addEventDialogOpen}
          onToggleAddEventDialogOpen={this.toggleAddEventDialogOpen}
          onHandleAddEventSubmit={this.handleAddEventSubmit}
          onUploadAttachmentsToEvent={this.uploadAttachmentsToEvent}
          onAddInsuredPersonToPolicy={this.addInsuredPersonToPolicy}
          onRemoveInsuredPersonFromPolicy={this.removeInsuredPersonFromPolicy}
          onRemovePolicyLineFromPolicy={this.removePolicyLineFromPolicy}
          onRemoveInsuredDogFromPolicy={this.removeInsuredDogFromPolicy}
          onUpdateExternalId={this.updateExternalIdOfPolicy}
        />
      </PolicyViewErrorBoundary>
    )
  }
}

class PolicyViewErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.captureException(error)
  }

  render() {
    const { policyId, policyExternalId } = this.props
    return this.state.error ? (
      <Card style={{ width: '100%', marginBottom: 20, marginTop: 20 }}>
        <StyledHeader>
          <StyledTypography variant="subtitle1" component="h3">
            <div>
              Id: <Copyable>{policyId}</Copyable>
            </div>
            {policyExternalId && (
              <div>
                Externe Id: <Copyable>{policyExternalId}</Copyable>
              </div>
            )}
          </StyledTypography>
        </StyledHeader>
        <Box m={2} style={{ textAlign: 'center', color: 'red' }}>
          <h3>Das Laden der Police ist fehlgeschlagen</h3>
          <p>Fehler:</p>
          <pre>{JSON.stringify(this.state.error.toString(), null, 2)}</pre>
        </Box>
      </Card>
    ) : (
      this.props.children
    )
  }
}
