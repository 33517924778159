import { API, BackofficeAPI } from '@elementinsurance/cognito-auth'
import { errorHandler } from '../utils/RestErrorHandler'

export default class DocumentsService {
  static addCustomerEventAttachments = (
    customerId,
    eventId,
    files,
    visibleForCustomer
  ) => {
    const formData = createFormData(files, visibleForCustomer)

    // see https://github.com/spring-cloud/spring-cloud-netflix/blob/master/docs/src/main/asciidoc/spring-cloud-netflix.adoc#uploading-files-through-zuul
    // why we prefix the URL with /zuul
    return API.post(
      `/zuul/customers/${customerId}/events/${eventId}/documents`,
      formData
    ).catch(errorHandler())
  }

  static addPolicyEventAttachments = ({
    customerId,
    policyId,
    eventId,
    files,
    visibleForCustomer
  }) => {
    const formData = createFormData(files, visibleForCustomer)

    return API.post(
      `/zuul/customers/${customerId}/policies/${policyId}/events/${eventId}/documents`,
      formData
    ).catch(errorHandler())
  }

  static addClaimEventAttachments = ({
    customerId,
    policyId,
    claimId,
    eventId,
    files,
    visibleForCustomer
  }) => {
    const formData = createFormData(files, visibleForCustomer)

    return API.post(
      `/zuul/customers/${customerId}/policies/${policyId}/claims/${claimId}/events/${eventId}/documents`,
      formData
    ).catch(errorHandler())
  }

  static getDocumentsOfCustomer(customerId) {
    return BackofficeAPI.get(`/customers/${customerId}/documents`).catch(
      errorHandler()
    )
  }

  static getShadowFlowDocumentsOfCustomer(customerId) {
    return API.get(`/customers/${customerId}/shadowFlowDocuments`).catch(
      errorHandler()
    )
  }

  static getTemplatesOverridesStatus(productId) {
    return API.get(`/products/${productId}/templates/overrides/status`).catch(
      errorHandler()
    )
  }

  static updateVisibilityForCustomer = (
    customerId,
    documentId,
    visibleForCustomer
  ) => {
    return API.put(
      `/customers/${customerId}/documents/${documentId}/visibleForCustomer`,
      { visibleForCustomer }
    ).catch(errorHandler())
  }
}

const createFormData = (files, visibleForCustomer) => {
  const formData = new FormData()
  files.forEach(file => formData.append('files', file))
  formData.append('visibleForCustomer', visibleForCustomer)
  return formData
}
