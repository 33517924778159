import { valueIsString } from '../stringUtils'

export function sortByField(arr, fieldAccessor, userOptions) {
  const options = Object.assign(
    {
      ignoreCase: false,
      order: 'ascending'
    },
    userOptions
  )

  return [...arr].sort((a, b) => {
    let fieldA = fieldAccessor(a)
    let fieldB = fieldAccessor(b)
    const ascending = options.order === 'ascending' || options.order === 'asc'

    if (options.ignoreCase) {
      fieldA = ignoreCase(fieldA)
      fieldB = ignoreCase(fieldB)
    }

    if (fieldA < fieldB) {
      return ascending ? -1 : 1
    } else if (fieldA > fieldB) {
      return ascending ? 1 : -1
    } else {
      return 0
    }
  })
}

function ignoreCase(input) {
  if (valueIsString(input)) {
    return input.toUpperCase()
  }

  return input
}
