import useSWRImmutable from 'swr/immutable'
import { partnerService } from '../services/PartnerService'
import { UserService } from '../services/UserService'

export function useUser(identifier: string | null) {
  return useSWRImmutable<{ name: string; email?: string }>(
    [identifier, '/user-management/pools/'],
    getUser
  )
}

async function getUser(
  identifier: string
): Promise<{ name: string; email?: string }> {
  // purchase flow
  if (/^WLPF:/i.test(identifier)) {
    const id = identifier.split(':')[1]

    return {
      name: `${id} (WLPF)`,
      email: 'support@element.in'
    }
  }

  try {
    // partner
    if (/^\d+$/.test(identifier)) {
      const partner = await partnerService.load(identifier)
      return {
        name: `${partner.name} (API Key)`,
        email: partner.email
      }
    }

    // backoffice user
    const [pool, id] = identifier.split(':')
    if (pool && id) {
      const user = await UserService.info(pool, id).catch()
      return {
        name: `${user.givenName} ${user.familyName}`,
        email: user.email
      }
    }
  } catch (error) {
    console.error(`Failed ot find user by identifier '${identifier}'`, error)
    return { name: 'Unbekannt' }
  }

  return {
    name: `${identifier} (Legacy)`,
    email: 'support@element.in'
  }
}
