import React from 'react'
import Badge from '@material-ui/core/Badge'
import Icon from '@material-ui/core/Icon'

export const EventAttachmentCountBadge = ({ attachments }) =>
  attachments ? (
    <Badge
      badgeContent={attachments.length}
      color="primary"
      style={{ fontWeight: 'bold', color: '#777', marginTop: 10 }}>
      <Icon>attachment</Icon>
    </Badge>
  ) : null
