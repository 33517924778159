import * as React from 'react'
import { BackofficeMicrofrontend } from '../../../utils/BackofficeMicrofrontend'
import { useMemo } from 'react'

export default function CustomerOrders({ customerId }: { customerId: string }) {
  return (
    <BackofficeMicrofrontend
      name="customer-orders-config"
      prodUrl="https://bo-customer-orders-config.web.app"
      localUrl="http://localhost:4022"
      additionalCustomProps={{ customerId }}
    />
  )
}
