import { DateTime } from 'luxon'

export const renderCoverStart = policy => {
  return dateToLocaleString(policy.coverStart)
}

export const renderCoverEnd = policy => {
  return dateToLocaleString(policy.coverage.coverEndForDisplay)
}

export function coverStartIsInFuture(policy) {
  let coverStart = DateTime.fromISO(policy.coverStart)
  return coverStart.diffNow().milliseconds >= 0
}

export function coverEndIsInPast(policy) {
  let coverEnd = DateTime.fromISO(policy.coverEnd)
  return coverEnd.diffNow().milliseconds <= 0
}

export function renderCancellationDate(policyEvents) {
  for (let i = 0; i < policyEvents.length; i++) {
    let element = policyEvents[i]
    if (element.type === 'PolicyCanceledEventV1') {
      return dateToLocaleString(element.cancelDate)
    }
  }
  return null
}

export function isCancelledInTheFuture(policyEvents) {
  let isCancelledInTheFuture = false

  for (let i = policyEvents.length - 1; i >= 0; i--) {
    if (policyEvents[i].type === 'PolicyCanceledEventV1') {
      isCancelledInTheFuture = true
    }
    if (
      policyEvents[i].eventType === 'MTA_REINSTATE' &&
      isCancelledInTheFuture
    ) {
      isCancelledInTheFuture = false
    }
  }

  return isCancelledInTheFuture
}

function dateToLocaleString(date) {
  return `${DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)} Uhr`
}
