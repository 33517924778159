import React from 'react'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import Button from '@material-ui/core/Button'
import { Form } from 'react-final-form'

import store from '../../CustomerStore'
import ErrorMessage from '../../../utils/ErrorMessage'
import SelectField from '../../../components/SelectField'
import TextField from '../../../components/TextField'
import * as Validators from '../../../utils/Validators'

const uiStore = observable(
  {
    loading: false,
    error: null,

    addMessage: function (message, resetForm) {
      this.loading = true
      this.error = null

      message = {
        type: 'NOTE',
        ...message
      }

      store
        .addMessage(message)
        .then(() => {
          this.loading = false
          resetForm()
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
    }
  },
  {
    addMessage: action.bound
  }
)

export default observer(MessageForm)
function MessageForm({ style, policies }) {
  if (!policies || policies.length === 0) {
    return null
  }

  return (
    <div style={{ ...style, marginBottom: 20 }}>
      <Form onSubmit={uiStore.addMessage} subscription={{ invalid: true }}>
        {({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline'
              }}>
              <div style={{ flex: '0 1 auto' }}>
                <SelectField
                  name="policyId"
                  floatingLabelText="Policy"
                  native={true}
                  fullWidth={true}
                  value={policies[0].id}
                  validate={Validators.required}>
                  {policies.map(policy => (
                    <option key={policy.id} value={policy.id}>
                      {policy.id}
                    </option>
                  ))}
                </SelectField>
              </div>
              <div style={{ flex: '1 1 auto', paddingLeft: '1em' }}>
                <TextField
                  name="text"
                  fullWidth={true}
                  floatingLabelText="Text"
                  validate={Validators.required}
                />
              </div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <ErrorMessage error={uiStore.error} />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={uiStore.loading || invalid}>
                Hinzufügen
              </Button>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}
