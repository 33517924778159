import React from 'react'
import styled from 'styled-components'
import { getTranslationForKey } from '../../../../i18n/getTranslationForKey'
import PaymentHistoryTimeline from './PaymentHistoryTimeline'
import { sortByField } from '../../../../utils/Sort'

const StyledInfo = styled.div`
  font-size: 0.85em;
  padding: 0.65em 0 0.65em;
`

const NoPaymentsPlaceholder = () => (
  <StyledInfo hint>
    {getTranslationForKey('PAYMENTS.STATUS.NO_PAYMENTS')}
  </StyledInfo>
)

export default function PaymentsHistory({
  customerId,
  policyId,
  paymentHistory,
  documents
}) {
  const {
    paymentTransactions = [],
    paymentMethods = [],
    paymentTransfers = []
  } = paymentHistory || {}

  const transactions = sortByField(
    paymentTransactions,
    t => new Date(t.events[0].transactionDate),
    { order: 'descending' }
  )

  return (
    <div>
      {paymentTransactions.length > 0 ? (
        <PaymentHistoryTimeline
          customerId={customerId}
          policyId={policyId}
          transactions={transactions}
          paymentMethods={paymentMethods}
          paymentTransfers={paymentTransfers}
          documents={documents}
        />
      ) : (
        <NoPaymentsPlaceholder />
      )}
    </div>
  )
}
