import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import React from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FullscreenDialog from '../utils/FullscreenDialog'
import ProgressVisualizer from '../utils/ProgressVisualizer'
import { StyledSuccessContainer } from '../utils/FormHelpers'
import { GlobalSnackbarService } from '../utils/GlobalSnackbarService'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import ProductSelectedContext from '../product-lines/ProductSelectedContext'
import { MtaApiService } from '../services/MtaApiService'

import {
  mapQuoteRequestFrom,
  mapQuoteRequestFromAccidentPolicy
} from '../policy-creation/requests/QuoteRequestMapper'
import Grid from '@material-ui/core/Grid'
import { QuoteService } from '../services/QuoteService'

export class ModalFormButton extends React.Component {
  static contextType = ProductSelectedContext

  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onPreview: PropTypes.func,
    titleLabel: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    successLabel: PropTypes.string.isRequired,
    successAddendum: PropTypes.string,
    formProps: PropTypes.object,
    formContent: PropTypes.element.isRequired,
    formFooter: PropTypes.element,
    formHint: PropTypes.element,
    formPreview: PropTypes.element,
    elementNameMapping: PropTypes.object,
    children: PropTypes.element
  }

  state = {
    open: false
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = resetFn => {
    this.setState({ open: false })
    resetFn()
    if (this.props.hasOwnProperty('onClose')) {
      this.props.onClose()
    }
  }

  handlePreview = formData => {
    const selectedProductWithElements = Object.assign({}, this.context, {
      elements: this.context.productLines.map(line => ({
        name: this.props.elementNameMapping[line.name]
      }))
    })
    let request

    if (selectedProductWithElements.elements[0].name === 'accident') {
      request = mapQuoteRequestFromAccidentPolicy(
        formData,
        selectedProductWithElements
      )
    } else {
      request = mapQuoteRequestFrom(formData, selectedProductWithElements)
    }
    // we only call mta-service quote creation call test customer and for partner 'NAMMERT' and products 'boat-liability' and 'boat-kasko' for now.
    if (
      this.isTestData() &&
      selectedProductWithElements.partnerId === '890579711933' &&
      [
        '30215cbb-0ceb-40fc-aba3-b3876e98f5c0',
        '812ef3c1-e2a1-481f-9d48-67f87b2abe68'
      ].includes(selectedProductWithElements.id)
    ) {
      MtaApiService.create(request)
        .then(res => {
          this.props.onPreview(res.elements[0])
        })
        .catch(response => {
          GlobalSnackbarService.emit(response.message, 'error')
        })
    } else {
      QuoteService.createQuotePreview(request)
        .then(res => {
          this.props.onPreview(res)
        })
        .catch(response => {
          GlobalSnackbarService.emit(response.data.message, 'error')
        })
    }
  }

  isTestData = () => {
    const formProps = this.props.formProps
    let initialValues = formProps.initialValues
    return formProps && initialValues && initialValues.testData
      ? initialValues.testData
      : false
  }

  submit = formData => {
    return this.props
      .onSubmit(formData)
      .then(data => {
        if (data && data.errors) {
          throw new Error()
        }
        return {} // all fine
      })
      .catch(response => {
        let errorMessage = 'Ein Fehler ist aufgetreten.'

        // in the case we have thrown the error above, response is undefined
        if (response && response.data) {
          if (response.data && response.data.status === 403) {
            GlobalSnackbarService.emit(
              'Ihnen fehlt die Berechtigung für diese Änderung.',
              'error'
            )
          }

          errorMessage = errorMessage + ' ' + response.data.message

          if (response.data.errors) {
            errorMessage =
              errorMessage + ' ' + response.data.errors[0].defaultMessage
          }
        } else if (response && response.message) {
          errorMessage = response.message
        }

        return { [FORM_ERROR]: errorMessage }
      })
  }

  render = () => {
    const {
      titleLabel,
      buttonLabel,
      buttonColor,
      formContent,
      successLabel,
      successAddendum,
      formProps,
      formFooter,
      formHint,
      formPreview,
      onPreview,
      submitDisabled,
      ...rest
    } = this.props

    return (
      <Form
        onSubmit={this.submit}
        keepDirtyOnReinitialize={true}
        {...formProps}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          invalid,
          submitting,
          hasSubmitErrors,
          submitError,
          submitSucceeded,
          touched,
          values,
          form: { reset }
        }) => (
          <>
            <Button
              color={buttonColor || 'primary'}
              name="openButton"
              data-testid="openButton"
              onClick={this.handleOpen}
              {...rest}>
              {buttonLabel || 'Ändern'}
            </Button>
            <FullscreenDialog
              open={this.state.open}
              footer={formFooter}
              title={
                <>
                  {titleLabel}
                  <IconButton
                    name="closeButton"
                    data-testid="closeButton"
                    style={{
                      position: 'absolute',
                      right: 10,
                      top: 10
                    }}
                    onClick={() => this.handleClose(reset)}
                    aria-label="Close">
                    <CloseIcon />
                  </IconButton>
                </>
              }
              content={
                submitting ? (
                  <ProgressVisualizer />
                ) : (
                  <>
                    <Grid container>
                      <Grid item xs={9} md={12}>
                        {hasSubmitErrors && (
                          <FormSubmitErrorMessage message={submitError} />
                        )}

                        <form>{this.state.open && formContent}</form>

                        {submitSucceeded && !hasSubmitErrors && (
                          <FormSubmitSuccessMessage
                            successLabel={successLabel}
                            successAddendum={successAddendum}
                          />
                        )}
                        {formHint}
                      </Grid>
                      {formPreview && (
                        <Grid item xs={3}>
                          {formPreview}
                        </Grid>
                      )}
                    </Grid>
                  </>
                )
              }
              controls={
                <>
                  <Button
                    name="cancelButton"
                    data-testid="cancelButton"
                    label="Cancel"
                    onClick={() => this.handleClose(reset)}
                    style={{ marginRight: 8 }}>
                    {submitSucceeded ? 'Schließen' : 'Abbrechen'}
                  </Button>

                  {formPreview && (
                    <Button
                      name="previewButton"
                      label="Preview"
                      disabled={false}
                      onClick={() => this.handlePreview(values)}
                      style={{ marginRight: 8 }}>
                      Vorschau
                    </Button>
                  )}

                  {!submitSucceeded && !submitDisabled && (
                    <Button
                      name="okButton"
                      data-testid="okButton"
                      color="secondary"
                      onClick={handleSubmit}
                      disabled={(invalid && !touched) || submitting}
                      style={{ marginRight: 8 }}>
                      Bestätigen
                    </Button>
                  )}
                </>
              }>
              {this.props.children}
            </FullscreenDialog>
          </>
        )}
      />
    )
  }
}

function FormSubmitErrorMessage({
  message = 'Es ist ein Fehler aufgetreten.'
}) {
  return (
    <div data-debugid="error" style={{ color: 'red' }}>
      {message}
    </div>
  )
}

export function FormSubmitSuccessMessage({ successLabel, successAddendum }) {
  return (
    <StyledSuccessContainer data-cy="success-message">
      <b>{successLabel}</b>
      <div>{successAddendum}</div>
    </StyledSuccessContainer>
  )
}
