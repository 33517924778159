import * as React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TimelinePayPalPaymentMethodDetailsContext } from './TimelinePayPalPaymentMethodDetailsContext'

const useStyles = makeStyles({
  bold: {
    'font-weight': 600
  },
  paymentListItems: {
    'padding-left': 0,
    'list-style-type': 'disc'
  }
})

export const PaymentEventDetailsTimelinePayPalPaymentDetails: React.FC<
  Props
> = ({ context }) => {
  const classes = useStyles()

  const payPalPaymentDetails = (
    <ul className={classes.paymentListItems}>
      <li>
        <Typography variant="body2" color="textPrimary">
          <span>Zahlungsmethode:</span>&nbsp;
          <span>{context.paymentMethod}</span>
        </Typography>
      </li>
      {getPayerOrReceiverDetails(context)}
    </ul>
  )

  return (
    <>
      <Typography variant="body2" color="textPrimary" className={classes.bold}>
        Zalungsdetails:
      </Typography>
      <ul>{payPalPaymentDetails}</ul>
    </>
  )
}

function getPayerOrReceiverDetails(
  context: TimelinePayPalPaymentMethodDetailsContext
) {
  if (context.payerName && context.payerEmail) {
    return (
      <>
        <li>
          <Typography variant="body2" color="textPrimary">
            <span>Name des Zahlers:</span>&nbsp;
            <span>{context.payerName}</span>
          </Typography>
        </li>
        <li>
          <Typography variant="body2" color="textPrimary">
            <span>Zahler E-Mail:</span>&nbsp;
            <span>{context.payerEmail}</span>
          </Typography>
        </li>
      </>
    )
  } else if (context.receiverName && context.receiverEmail) {
    return (
      <>
        <li>
          <Typography variant="body2" color="textPrimary">
            <span>Name des Empfänger:</span>&nbsp;
            <span>{context.receiverName}</span>
          </Typography>
        </li>
        <li>
          <Typography variant="body2" color="textPrimary">
            <span>PayPal-Konto:</span>&nbsp;
            <span>{context.receiverEmail}</span>
          </Typography>
        </li>
      </>
    )
  }

  return <></>
}

interface Props {
  context: TimelinePayPalPaymentMethodDetailsContext
}
